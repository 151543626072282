import React from "react";

import CachedIcon from "@mui/icons-material/Cached";
import { IconButton } from "@mui/material";
interface PropTypes {
    refreshFunction: () => void;
}

export const Refresh = ({ refreshFunction }: PropTypes) => {
    const handleRefresh = () => {
        refreshFunction();
    };

    return (
        <IconButton onClick={handleRefresh}>
            <CachedIcon />
        </IconButton>
    );
};
