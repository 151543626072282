import React, { ReactElement, useContext } from "react";
import {
    AccessAlarmOutlined,
    AccountBalanceOutlined,
    AppsOutlined,
    Assignment,
    AssignmentIndOutlined,
    InfoOutlined,
} from "@mui/icons-material";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MoveToInboxOutlinedIcon from "@mui/icons-material/MoveToInboxOutlined";
import { List, ListItem, ListItemText, Divider } from "@mui/material";
import { RouteComponentProps, useLocation, withRouter } from "react-router";

import useApp from "../../hooks/useApp";
import { UserContext } from "../../context/user/UserContext";

interface MenuData {
    label: string;
    path: string;
    icon: ReactElement;
    segment?: string;
}

type PropTypes = RouteComponentProps;

const Menu = (props: PropTypes) => {
    const { type } = useApp();
    const { user, organization } = useContext(UserContext);
    const requerimientosMenu: MenuData[] = [
        {
            icon: <HomeOutlinedIcon />,
            label: "Home",
            path: "",
            segment: "home",
        },
        {
            icon: <MoveToInboxOutlinedIcon />,
            label: "Recepciones",
            path: "submissions",
            segment: "submissions",
        },
        {
            icon: <ContactPageOutlinedIcon />,
            label: "Contactos",
            path: "contacts",
        },
        {
            icon: <DateRangeOutlinedIcon />,
            label: "Requerimientos",
            path: "requirements",
        },
        {
            icon: <AssignmentOutlinedIcon />,
            label: "Templates",
            path: "templates",
        },
        {
            icon: <EmailOutlinedIcon />,
            label: "Emails",
            path: "emails",
        },
        {
            icon: <AppsOutlined />,
            label: "Tableros",
            path: "reports",
        },
        {
            icon: <BuildCircleOutlinedIcon />,
            label: "Form Builder",
            path: "form-builder",
        },
    ];

    const cumplimientoMenu: MenuData[] = [
        {
            icon: <HomeOutlinedIcon />,
            label: "Home",
            path: "",
        },
        {
            icon: <InfoOutlined />,
            label: "Novedades",
            path: "novedades",
        },
        {
            icon: <AccessAlarmOutlined />,
            label: "Vencimientos",
            path: "deadlines",
        },

        {
            icon: <Assignment />,
            label: "Obligaciones",
            path: "regulations",
        },
        {
            icon: <AccountBalanceOutlined />,
            label: "Mis Entidades",
            path: "entities",
        },
    ];

    const selected = useLocation().pathname.split("/")[type === "CUMPLIMIENTO" ? 2 : 1];

    const handleOpenMenu = (path: string, segment: string | undefined) => (event: any) => {
        window.analytics.track("view_" + (segment || path), {
            id_user: user?.id,
            id_organization: organization?.id_organization,
        });
        props.history.push(`/${type === "CUMPLIMIENTO" ? "cumplimiento/" : ""}${path}`);
    };

    return (
        <List className="ui-menu">
            {type !== "CUMPLIMIENTO" &&
                requerimientosMenu.map((menu, index) => (
                    <div
                        key={index}
                        className={`${selected === menu.path ? "selected" : ""}`}
                        onClick={handleOpenMenu(menu.path, menu.segment)}
                    >
                        <ListItem key={menu.path} button className="list-item">
                            <span className="menu-icon">{menu.icon}</span>
                            <ListItemText className="menu-text" primary={menu.label} />
                        </ListItem>
                    </div>
                ))}
            {type === "CUMPLIMIENTO" &&
                cumplimientoMenu.map((menu, index) => (
                    <div
                        key={index}
                        className={`${selected === menu.path ? "selected" : ""}`}
                        onClick={handleOpenMenu(menu.path, menu.segment)}
                    >
                        <ListItem key={menu.path} button className="list-item">
                            <span className="menu-icon">{menu.icon}</span>
                            <ListItemText className="menu-text" primary={menu.label} />
                        </ListItem>
                    </div>
                ))}
        </List>
    );
};

export default withRouter(Menu);
