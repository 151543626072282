import { RegulationsResponse } from "../api/regulations";

export type Regulation = Pick<
    RegulationsResponse,
    "id_regulation" | "template" | "contact" | "reference" | "data" | "created_at" | "updated_at"
>;

export interface RegulationImportExcel {
    id_regulation: string;
    email: string;
    template: string;
    reference: string;
    [key: string]: any;
}

export enum RegulationsFilterType {
    completed = "completed",
    pending = "pending",
    all = "all",
}
