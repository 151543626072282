import { Contact } from "../../contacts";
import { Entity } from "../../entities";
import { Regulation } from "../../regulations";
import { Template } from "../../templates";
import { RegulationsResponse } from "../regulations";
import { Attachment } from "../submissions";

export interface DeadlinesResponse {
    id_deadline: string;
    date: string;
    regulation: RegulationsResponse;
    conditions: Condition[];
    regulator: Regulator;
    entities: Entity[];
    status?: string;
    filings: Filing;
}

export interface Filing {
    id_filing: string;
    id_deadline: string;
    versions: Version[];
}
export interface Version {
    id_version: string;
    status: string;
    body: string;
    attachment: File[] | Attachment[];
    created_by: string;
    created_at: string;
    updated_at: string;
}
export interface Comment {
    id_comment: string;
    comment: string;
    created_at: Date;
    updated_at: Date;
    created_by: CreatedBy;
    attachments: any[];
}

export interface Condition {
    created_at: Date;
    updated_at: Date;
    id_condition: string;
    name: string;
    country: string;
    description: string;
}

export interface Regulator {
    created_at: Date;
    updated_at: Date;
    id_regulator: string;
    name: string;
    country: string;
    code: string;
}

export interface CreatedBy {
    id: string;
    email: string;
}

/* export interface Contact {
  id_contact:  string;
  first_name:  string;
  middle_name: null | string;
  last_name:   string;
  email:       string;
} */

export interface Data {}

export enum DeadlineStatus {
    Created = "CREATED",
    Draft = "DRAFT",
    Submitted = "SUBMITTED",
    Deleted = "DELETED",
    Review = "REVIEW",
    Cancelled = "CANCELLED",
    Expired = "EXPIRED",
}

export interface Update {
    created_at: Date;
    updated_at: Date;
    id_deadline_update: number;
    id_deadline: string;
    status: DeadlineStatus;
    created_by: null | string;
}
