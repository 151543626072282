import React from "react";

import { Button, ButtonGroup } from "@mui/material";

import { RegulationsFilterType } from "../../../interfaces/regulations";

import "./index.scss";

interface PropTypes {
    onClick: (f?: RegulationsFilterType) => void;
    value?: RegulationsFilterType;
}

export const RegulationsFilters = (props: PropTypes) => {
    const { onClick, value } = props;

    const handleClickFilter = (v: RegulationsFilterType) => (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (value === v) {
            onClick();
        } else {
            onClick(v);
        }
    };

    return (
        <div className="regulations-filters-container">
            <ButtonGroup>
                <Button
                    color={value === RegulationsFilterType.completed ? "secondary" : "primary"}
                    size="small"
                    variant={value === RegulationsFilterType.completed ? "contained" : "outlined"}
                    onClick={handleClickFilter(RegulationsFilterType.completed)}
                >
                    Completados
                </Button>
                <Button
                    color={value === RegulationsFilterType.pending ? "secondary" : "primary"}
                    size="small"
                    variant={value === RegulationsFilterType.pending ? "contained" : "outlined"}
                    onClick={handleClickFilter(RegulationsFilterType.pending)}
                >
                    Pendientes
                </Button>
                <Button
                    color={value === RegulationsFilterType.all ? "secondary" : "primary"}
                    size="small"
                    variant={value === RegulationsFilterType.all ? "contained" : "outlined"}
                    onClick={handleClickFilter(RegulationsFilterType.all)}
                >
                    Todos
                </Button>
            </ButtonGroup>
        </div>
    );
};
