import React, { useContext, useEffect } from "react";

import { RouteComponentProps, withRouter } from "react-router";

import EmailsTable from "../../components/emails/table";
import { EmailsContext } from "../../context/emails/EmailsContext";
import CommonLayout from "../../layout/common";

import "./index.scss";

type PropTypes = RouteComponentProps<any>;

const Emails = (props: PropTypes) => {
    const { emails, getEmails, loading } = useContext(EmailsContext);

    useEffect(() => {
        getEmails();
    }, []);

    return (
        <div className="pages-emails">
            <CommonLayout loading={loading} refreshFunction={getEmails} title="Emails">
                {emails && <EmailsTable emails={emails} />}
            </CommonLayout>
        </div>
    );
};

export default withRouter(Emails);
