import React, { useEffect } from "react";

import { Cookies } from "react-cookie";
import { Redirect } from "react-router-dom";

import { supabase } from "../../utils/supabase";

const Logout = () => {
    const cookies = new Cookies();

    useEffect(() => {
        const logout = async () => {
            await supabase.auth.signOut();
        };

        logout();
        cookies.remove("access_token_api", { path: "/" });
    }, []);

    return <Redirect to="/login" />;
};

export default Logout;
