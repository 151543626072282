import React, { useContext, useEffect, useState } from "react";

import { ContentPaste, PeopleAltOutlined, SystemUpdateAlt } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { GridColDef } from "@mui/x-data-grid";
import { RouteComponentProps, withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";

import { DeadlinesFilters } from "../../../components/deadlines/filters";
import DeadlinesTable from "../../../components/deadlines/table";
import ImportPreviewDialog from "../../../components/import-preview";
import { DeadlinesContext } from "../../../context/deadlines/DeadlinesContext";
import { DeadlineImportExcel, DeadlinesFilterType } from "../../../interfaces/deadlines";
import CommonLayout from "../../../layout/common";

import "./index.scss";

type PropTypes = RouteComponentProps<any>;

interface Action {
    type?: string;
    id?: string;
}

const Deadlines = (props: PropTypes) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filters = queryParams.get("filters");

    const getFilterInitialState = (): DeadlinesFilterType => {
        if (
            filters === DeadlinesFilterType.day ||
            filters === DeadlinesFilterType.month ||
            filters === DeadlinesFilterType.month
        ) {
            return filters;
        }

        return DeadlinesFilterType.week;
    };

    const {
        createDeadline,
        getDeadlines,
        getDeadlinesByFilter,
        loading,
        errorMessage,
        deadlines,
        dayDeadlines,
        weekDeadlines,
        monthDeadlines,
        yearDeadlines,
    } = useContext(DeadlinesContext);
    const [importData, setImportData] = useState<DeadlineImportExcel[] | undefined>();
    const [importBy, setImportBy] = useState<string>("");
    const [customColumns, setCustomColumns] = useState<GridColDef[] | undefined>();
    const [deadlinesFilters, setDeadlinesFilters] = useState<DeadlinesFilterType | undefined>(getFilterInitialState());

    const handleAddDeadline = () => {
        props.history.push("/deadlines/new");
    };

    useEffect(() => {
        if (deadlinesFilters === undefined) {
            getDeadlines();
        } else {
            getDeadlinesByFilter(deadlinesFilters);
        }
    }, []);

    const handleChangeFilters = (value?: DeadlinesFilterType) => {
        if (value === undefined) {
            getDeadlines();
            props.history.push("/cumplimiento/deadlines");
        } else {
            getDeadlinesByFilter(value);
            props.history.push(`/cumplimiento/deadlines?filters=${value}`);
        }
        setDeadlinesFilters(value);
    };

    const handleImportDeadline = (by: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files,
            f = files && files[0];
        const reader = new FileReader();

        if (f) {
            reader.onload = function (e: ProgressEvent<FileReader>) {
                const data = e?.target?.result;
                const readedData = XLSX.read(data, { type: "binary" });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                /* Convert array to json*/
                const xlsxData = XLSX.utils.sheet_to_json(ws, { header: "A", raw: false, rawNumbers: false });

                if (xlsxData && xlsxData instanceof Array) {
                    const dataParse = xlsxData.slice(1).map(
                        (o: any): DeadlineImportExcel => {
                            const temp: any = {};

                            Object.keys(o).map((k) => (temp[xlsxData[0][k]] = o[k]));

                            return {
                                id_deadline: uuidv4(),
                                ...temp,
                            };
                        },
                    );
                    const customColumns: GridColDef[] = [];

                    Object.keys(xlsxData[0]).map((key) => {
                        if (xlsxData[0][key] === "email") {
                            customColumns.push({
                                field: "email",
                                headerName: "Contacto",
                                width: 200,
                                renderCell: (params: any) => <b>{params.value}</b>,
                            });
                        }
                        if (xlsxData[0][key] === "account") {
                            customColumns.push({
                                field: "account",
                                headerName: "Cuenta",
                                width: 200,
                                renderCell: (params: any) => <b>{params.value}</b>,
                            });
                        } else if (xlsxData[0][key] === "template") {
                            customColumns.push({
                                field: "template",
                                headerName: "Template",
                                width: 300,
                            });
                        } else if (xlsxData[0][key] === "reference") {
                            customColumns.push({
                                field: "reference",
                                headerName: "Detalle",
                                width: 300,
                            });
                        } else {
                            customColumns.push({
                                field: xlsxData[0][key],
                                width: 150,
                            });
                        }
                    });
                    setCustomColumns(customColumns);
                    setImportData(dataParse);
                    setImportBy(by);
                }
            };
            reader.readAsBinaryString(f);
        }
        e.target.value = "";
        handleClose();
    };

    const deadlinesByFilter = {
        day: dayDeadlines,
        week: weekDeadlines,
        month: monthDeadlines,
        year: yearDeadlines,
    };

    const isLoading = () => {
        if (loading || (!deadlines && deadlinesFilters && !deadlinesByFilter[deadlinesFilters])) {
            return true;
        }

        return false;
    };

    const getRefreshFunction = () => {
        if (deadlinesFilters === undefined) {
            return getDeadlines();
        }

        return getDeadlinesByFilter(deadlinesFilters);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="pages-deadlines">
            <CommonLayout loading={loading} refreshFunction={getRefreshFunction} title="Vencimientos">
                <div className="options-deadlines">
                    <DeadlinesFilters value={deadlinesFilters} onClick={handleChangeFilters} />
                </div>
                <DeadlinesTable
                    deadlines={deadlinesFilters ? deadlinesByFilter[deadlinesFilters] || [] : deadlines || []}
                    loading={isLoading()}
                />
            </CommonLayout>
        </div>
    );
};

export default withRouter(Deadlines);
