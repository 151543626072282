import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CircularProgress, Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import api from "../../api";
import { HomeContext } from "../../context/home/HomeContext";
import CommonLayout from "../../layout/common";

import "./index.scss";
import { UserContext } from "../../context/user/UserContext";

interface MetricsData {
    label: string;
    accessor:
        | "contacts"
        | "submissions"
        | "active_requirements"
        | "emails"
        | "pending_submissions"
        | "last_week_expired_requirements";
    color: string;
    backgroundColor: string;
    path: string;
    segment_event_name: string;
}

interface AnalyticsData {
    contacts: number;
    submissions: number;
    pending_submissions: number;
    active_requirements: number;
    emails: number;
}

type PropTypes = RouteComponentProps;

const useStyles = makeStyles(() => ({
    root: {
        "&.MuiCard-root": {
            boxShadow: "none",
            textAlign: "center",
            padding: "35px",
            borderRadius: "8px",
            height: "200px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "10px",
            marginRight: "18px",
            textDecoration: "none",
        },
    },
}));

const Home = (props: PropTypes) => {
    const { analytics, getAnalytics, loading } = React.useContext(HomeContext);
    const { user, organization } = React.useContext(UserContext);

    React.useEffect(() => {
        getAnalytics();
        logSegment("view_home");
    }, []);

    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const metrics: MetricsData[] = [
        {
            label: "Recepciones pendientes",
            accessor: "pending_submissions",
            color: "rgb(0,128,0)",
            backgroundColor: "rgba(0, 128, 0, 0.2)",
            path: "/submissions?filters=pending",
            segment_event_name: "click_pending_button_home",
        },
        {
            label: "Requerimientos Expirados ultimos 7 días",
            accessor: "last_week_expired_requirements",
            color: "rgb(0,0,0)",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            path: "/requirements?filters=expired",
            segment_event_name: "click_expired_button_home ",
        },
        {
            label: "Requerimientos Activos",
            accessor: "active_requirements",
            color: "rgb(255,102,0)",
            backgroundColor: "rgba(255, 102, 0, 0.2)",
            path: "/requirements?filters=active",
            segment_event_name: "click_active_button_home",
        },
        {
            label: "Contactos",
            accessor: "contacts",
            color: "rgb(249, 215, 28)",
            backgroundColor: "rgba(249, 215, 28, 0.2)",
            path: "/contacts",
            segment_event_name: "click_contacts_button_home",
        },
        {
            label: "Emails",
            accessor: "emails",
            color: "rgb(97, 97, 97)",
            backgroundColor: "rgba(97, 97, 97, 0.2)",
            path: "/emails",
            segment_event_name: "click_emails_button_home",
        },
    ];
    /*   const [data, setData] = React.useState<AnalyticsData | undefined>()
  const [loading, setLoading] = React.useState(false)

  React.useEffect( () => {
    setLoading(true)
    api.get('/home/analytics').then(response => {
      if(response) {
        setData(response.data)
      }
    }).catch(e => {
      enqueueSnackbar(e?.response?.data?.message || "Ocurrió un error al obtener información")
    }).finally(() => setLoading(false))
  }, []) */
    const logSegment = (eventName: string) => (ev: any) => {
        window.analytics.track(eventName, { id_user: user?.id, id_organization: organization?.id_organization });
    };

    return (
        <div className="pages-home">
            <CommonLayout loading={loading} title="Home">
                <div className="grid-container">
                    {metrics.map((metric) => (
                        <Grid key={metric.accessor} style={{ width: "195px" }}>
                            <Card
                                classes={{
                                    root: classes.root,
                                }}
                                component={Link}
                                sx={{ color: metric.color, backgroundColor: metric.backgroundColor }}
                                to={metric.path}
                                onClick={logSegment(metric.segment_event_name)}
                            >
                                <Typography sx={{ fontWeight: "700" }} variant="h2">
                                    {(analytics && analytics[metric.accessor]) || "-"}
                                </Typography>
                                <Typography sx={{ opacity: 0.72, paddingTop: "25px" }} variant="subtitle2">
                                    {metric.label}
                                </Typography>
                            </Card>
                        </Grid>
                    ))}
                </div>
            </CommonLayout>
        </div>
    );
};

export default withRouter(Home);
