import React from "react";

import { Button, ButtonGroup } from "@mui/material";

import { NovedadesFilterType } from "../../../interfaces/novedades";

import "./index.scss";

interface PropTypes {
    onClick: (f?: NovedadesFilterType) => void;
    value?: NovedadesFilterType;
}

export const NovedadesFilters = (props: PropTypes) => {
    const { onClick, value } = props;

    const handleClickFilter = (v: NovedadesFilterType) => (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (value === v) {
            onClick();
        } else {
            onClick(v);
        }
    };

    return (
        <div className="novedades-filters-container">
            <ButtonGroup>
                <Button
                    color={value === NovedadesFilterType.day ? "secondary" : "primary"}
                    size="small"
                    variant={value === NovedadesFilterType.day ? "contained" : "outlined"}
                    onClick={handleClickFilter(NovedadesFilterType.day)}
                >
                    Día
                </Button>
                <Button
                    color={value === NovedadesFilterType.week ? "secondary" : "primary"}
                    size="small"
                    variant={value === NovedadesFilterType.week ? "contained" : "outlined"}
                    onClick={handleClickFilter(NovedadesFilterType.week)}
                >
                    Semana
                </Button>
                <Button
                    color={value === NovedadesFilterType.month ? "secondary" : "primary"}
                    size="small"
                    variant={value === NovedadesFilterType.month ? "contained" : "outlined"}
                    onClick={handleClickFilter(NovedadesFilterType.month)}
                >
                    Mes
                </Button>
            </ButtonGroup>
            <ButtonGroup sx={{ marginLeft: "8px" }}>
                <Button
                    color={value === NovedadesFilterType.all ? "secondary" : "primary"}
                    size="small"
                    variant={value === NovedadesFilterType.all ? "contained" : "outlined"}
                    onClick={handleClickFilter(NovedadesFilterType.all)}
                >
                    Todos
                </Button>
            </ButtonGroup>
        </div>
    );
};
