import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@mui/material/Drawer";

import { GoToCumplimiento } from "../button/go-to-cumplimiento";
import { LogoutButton } from "../button/logout";
import { Logo } from "../logo";
import Menu from "../menu";

import "./index.scss";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiButtonBase-root.MuiListItem-root:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            borderRadius: "8px",
        },
    },
}));

export const Sidebar = () => {
    const classes = useStyles();

    return (
        <Drawer
            anchor="left"
            className="ui-sidebar"
            classes={{
                root: classes.root,
            }}
            sx={{
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                },
            }}
            variant="permanent"
        >
            <Logo />
            <Menu />
            <GoToCumplimiento />
            <LogoutButton />
        </Drawer>
    );
};
