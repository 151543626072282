import { NovedadesResponse } from "../api/novedades";

export type Novedad = Pick<NovedadesResponse, "id_news" | "date" | "title" | "description" | "condition" | "entities">;

export interface NovedadImportExcel {
    id_requirement: string;
    email: string;
    template: string;
    reference: string;
    [key: string]: any;
}

export interface Entity {
    created_at: string;
    id_condition: string;
    id_entity: string;
    period: string;
    updated_at: string;
    fiscal_month_end: number;
    id_organization: string;
    legal_name: string;
    tax_id: string;
    tax_id_type: string;
}

export enum NovedadesFilterType {
    day = "day",
    week = "week",
    month = "month",
    all = "all",
}
