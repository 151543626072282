import React, { createContext, useReducer } from "react";

import { isArray } from "lodash";
import { useSnackbar } from "notistack";

import api from "../../api";
import { RegulationsResponse } from "../../interfaces/api/regulations";
import { Regulation, RegulationImportExcel, RegulationsFilterType } from "../../interfaces/regulations";
import { RegulationsReducer, RegulationsState } from "./RegulationsReducer";

type RegulationsContextProps = {
    regulations?: RegulationsResponse[];
    completedRegulations?: RegulationsResponse[];
    pendingRegulations?: RegulationsResponse[];
    allRegulations?: RegulationsResponse[];
    errorMessage?: string;
    loading: boolean;
    getRegulations: () => Promise<void>;
    getRegulationsByFilter: (v: RegulationsFilterType) => Promise<void>;
    getRegulationById: (id: string) => Promise<Regulation | undefined>;
    getRegulationsByContact: (contactId: string) => Promise<RegulationsResponse[] | undefined>;
    getRegulationsByTemplate: (contactId: string) => Promise<RegulationsResponse[] | undefined>;
    removeError: () => void;
    cleanRegulations: () => void;
    createRegulation: (regulation: Partial<Regulation> | RegulationImportExcel[], by: string) => Promise<boolean>;
    updateRegulation: (regulation: Partial<Regulation>, id: string) => Promise<boolean>;
    cancelRegulation: (id: string) => Promise<void>;
    pauseRegulation: (id: string) => Promise<void>;
    resumeRegulation: (id: string) => Promise<void>;
    restartRegulation: (id: string) => Promise<void>;
    completeRegulation: (id: string) => Promise<void>;
};

const regulationsInitialState: RegulationsState = {
    loading: false,
    errorMessage: undefined,
    regulations: undefined,
};

export const RegulationsContext = createContext({} as RegulationsContextProps);

export const RegulationsProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(RegulationsReducer, regulationsInitialState);
    const { enqueueSnackbar } = useSnackbar();

    const onSuccess = (action: boolean) => {
        dispatch({
            type: "removeError",
        });
        if (action) {
            dispatch({
                type: "setLoadingAction",
                payload: { value: false, type: undefined, reqId: undefined },
            });
        } else {
            dispatch({
                type: "setLoading",
                payload: false,
            });
        }
    };

    const onSuccessWithMessage = (action: boolean, successMessage: string) => {
        onSuccess(action);
        enqueueSnackbar(successMessage, {
            variant: "success",
        });
    };

    const onError = (action: boolean, errorMsg?: string) => {
        dispatch({
            type: "addError",
            payload: errorMsg || "Hubo un error",
        });
        if (action) {
            dispatch({
                type: "setLoadingAction",
                payload: { value: false, type: undefined, reqId: undefined },
            });
        } else {
            dispatch({
                type: "setLoading",
                payload: false,
            });
        }
        enqueueSnackbar(errorMsg || "Hubo un error", {
            variant: "error",
        });
    };

    const getRegulations = async () => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<RegulationsResponse[]>("/regulations?type=neq.RECURRING");
            const regulations = resp.data;

            dispatch({
                type: "setRegulations",
                payload: regulations || undefined,
            });
            onSuccess(false);
        } catch (error: any) {
            onError(false, error.response?.data?.message);
            cleanRegulations();
        }
    };

    const getRegulationById = async (id: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<RegulationsResponse>(`/regulations/${id}`);
            const regulation = resp.data;
            const mappedRegulation: Regulation = {
                id_regulation: regulation.id_regulation,
                template: regulation.template,
                contact: regulation.contact,
                data: regulation.data,
                reference: regulation.reference,
                created_at: regulation.created_at,
                updated_at: regulation.updated_at,
            };

            onSuccess(false);

            return mappedRegulation;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const getRegulationsByContact = async (contactId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<RegulationsResponse[]>(`/regulations?contact->>id_contact=eq.${contactId}`);

            onSuccess(false);

            return data;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const getRegulationsByFilter = async (status: RegulationsFilterType) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });

            if (status === RegulationsFilterType.completed) {
                const { data } = await api.get<RegulationsResponse[]>(
                    "/regulations?type=neq.RECURRING&is_active=eq.false",
                );

                dispatch({
                    type: "setCompletedRegulations",
                    payload: data || undefined,
                });
            } else if (status === RegulationsFilterType.pending) {
                const { data } = await api.get<RegulationsResponse[]>("/regulations?type=neq.RECURRING");

                dispatch({
                    type: "setPendingRegulations",
                    payload: data || undefined,
                });
            } else {
                const { data } = await api.get<RegulationsResponse[]>("/regulations?type=neq.RECURRING");

                dispatch({
                    type: "setAllRegulations",
                    payload: data || undefined,
                });
            }
            onSuccess(false);
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const getRegulationsByTemplate = async (templateId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<RegulationsResponse[]>(
                `/regulations?template->>id_template=eq.${templateId}`,
            );

            onSuccess(false);

            return data;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const createRegulation = async (regulation: Partial<Regulation> | RegulationImportExcel[], by: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const mapRegulation = () => {
                if (isArray(regulation)) {
                    return regulation.map((r) => ({ ...r, id_regulation: undefined }));
                } else {
                    return {
                        ...regulation,
                        id_regulation: undefined,
                        contact: undefined,
                        template: undefined,
                        id_contact: regulation.contact?.id_contact,
                        id_template: regulation.template?.id_template,
                    };
                }
            };
            const mappedRegulation = mapRegulation();

            if (by === "accounts") {
                await api.post<RegulationsResponse[]>("/regulations_from_accounts", mappedRegulation);
            } else {
                await api.post<RegulationsResponse[]>("/regulations", mappedRegulation);
            }
            onSuccessWithMessage(false, "El requerimiento ha sido creado correctamente");
            getRegulations();
            const success = true;

            return success;
        } catch (error: any) {
            onError(false, error.response?.data?.message);
            const success = false;

            return success;
        }
    };

    const updateRegulation = async (regulation: Partial<Regulation>, id: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            await api.patch<RegulationsResponse[]>(`/regulations/${id}`, regulation);
            onSuccessWithMessage(false, "El requerimiento ha sido actualizado correctamente");
            getRegulations();
            const success = true;

            return success;
        } catch (error: any) {
            onError(false, error.response?.data?.message);
            const success = false;

            return success;
        }
    };

    const cancelRegulation = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "cancel", reqId: id },
            });
            await api.post(`/regulations/${id}/cancel`);
            onSuccessWithMessage(true, "El requerimiento ha sido cancelado correctamente");
            getRegulations();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };

    const pauseRegulation = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "pause", reqId: id },
            });
            await api.post(`/regulations/${id}/pause`);
            onSuccessWithMessage(true, "El requerimiento ha sido pausado correctamente");
            getRegulations();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };

    const completeRegulation = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "complete", reqId: id },
            });
            await api.post(`/regulations/${id}/complete`);
            onSuccessWithMessage(true, "El requerimiento ha sido completado correctamente");
            getRegulations();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };

    const resumeRegulation = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "resume", reqId: id },
            });
            await api.post(`/regulations/${id}/resume`);
            onSuccessWithMessage(true, "El requerimiento ha sido reanudado correctamente");
            getRegulations();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };

    const restartRegulation = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "restart", reqId: id },
            });
            await api.post(`/regulations/${id}/restart`);
            onSuccessWithMessage(true, "El requerimiento ha sido reiniciado correctamente");
            getRegulations();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };
    const removeError = () => {
        dispatch({ type: "removeError" });
    };

    const cleanRegulations = () => {
        dispatch({ type: "cleanRegulations" });
    };

    return (
        <RegulationsContext.Provider
            value={{
                ...state,
                getRegulations,
                getRegulationsByFilter,
                cleanRegulations,
                removeError,
                createRegulation,
                updateRegulation,
                getRegulationById,
                getRegulationsByContact,
                getRegulationsByTemplate,
                cancelRegulation,
                completeRegulation,
                pauseRegulation,
                resumeRegulation,
                restartRegulation,
            }}
        >
            {children}
        </RegulationsContext.Provider>
    );
};
