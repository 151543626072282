import { RegulationsResponse } from "../../interfaces/api/regulations";

export interface RegulationsState {
    errorMessage?: string;
    loading: boolean;
    regulations?: RegulationsResponse[];
    completedRegulations?: RegulationsResponse[];
    pendingRegulations?: RegulationsResponse[];
    allRegulations?: RegulationsResponse[];
}

type RegulationsActions =
    | { type: "setRegulations"; payload: RegulationsResponse[] | undefined }
    | { type: "setCompletedRegulations"; payload: RegulationsResponse[] | undefined }
    | { type: "setPendingRegulations"; payload: RegulationsResponse[] | undefined }
    | { type: "setAllRegulations"; payload: RegulationsResponse[] | undefined }
    | { type: "addError"; payload: string }
    | { type: "setLoading"; payload: boolean }
    | { type: "setLoadingAction"; payload: { value: boolean; type?: string; reqId?: string } }
    | { type: "cleanRegulations" }
    | { type: "removeError" };

export const RegulationsReducer = (state: RegulationsState, action: RegulationsActions): RegulationsState => {
    switch (action.type) {
        case "setLoading":
            return {
                ...state,
                loading: action.payload,
            };
        case "setLoadingAction":
            return {
                ...state,
            };
        case "addError":
            return {
                ...state,
                errorMessage: action.payload,
            };
        case "removeError":
            return {
                ...state,
                errorMessage: undefined,
            };
        case "setRegulations":
            return {
                ...state,
                regulations: action.payload,
            };
        case "setCompletedRegulations":
            return {
                ...state,
                completedRegulations: action.payload,
            };
        case "setPendingRegulations":
            return {
                ...state,
                pendingRegulations: action.payload,
            };
        case "setAllRegulations":
            return {
                ...state,
                allRegulations: action.payload,
            };
        case "cleanRegulations":
            return {
                ...state,
                regulations: undefined,
            };
        default:
            return state;
    }
};
