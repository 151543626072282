import React from "react";

import logo from "../../assets/celeri-logo-white.png";

import "./index.scss";

export const Logo = () => {
    return (
        <div className="ui-logo">
            <img alt="logo" src={logo} />
        </div>
    );
};
