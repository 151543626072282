import { DeadlinesResponse } from "../api/deadlines";

export type Deadline = DeadlinesResponse;

export interface DeadlineImportExcel {
    id_requirement: string;
    email: string;
    template: string;
    reference: string;
    [key: string]: any;
}

export enum DeadlinesFilterType {
    day = "day",
    week = "week",
    month = "month",
    yaer = "year",
}
