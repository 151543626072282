import React, { useState } from "react";

import { Button, Card, CircularProgress, TextField } from "@mui/material";
import { Cookies } from "react-cookie";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import validator from "validator";

import { supabase } from "../../utils/supabase";

import "./index.scss";

type Props = RouteComponentProps;

const Login = (props: Props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const handleLogin = async (email: string, password: string) => {
        const cookies = new Cookies();

        setLoading(true);
        const { error, user, session } = await supabase.auth.signIn(
            { email, password },
            {
                scopes: "offline email",
            },
        );

        if (!error && !user) window.alert("Check your email for the login link!");
        if (error) window.alert(error.message);
        cookies.set("access_token_api", session?.access_token, { path: "/" });
        window.analytics.track("User logged in", {
            email,
        });
        props.history.push("/");
        setLoading(false);
    };

    return (
        <div className="login-root">
            <Card className="login-box">
                <div className="logo">
                    <img alt="logo" src="https://celeri-public.nyc3.cdn.digitaloceanspaces.com/celeri-logo-black.png" />
                </div>
                <h3>Ingresar a tu cuenta</h3>
                <div className="text-field">
                    <TextField
                        fullWidth
                        autoCapitalize={"none"}
                        error={!!email && !validator.isEmail(email)}
                        helperText={!!email && !validator.isEmail(email) ? "Ingresar un email válido" : ""}
                        label="Email"
                        placeholder="email@address.com"
                        sx={{
                            marginX: "16px",
                            width: "100%",
                        }}
                        type="email"
                        value={email}
                        onChange={(ev: any) => setEmail(ev.target.value)}
                        onKeyPress={(ev: any) =>
                            ev.key === "Enter" && password && email && handleLogin(email, password)
                        }
                    />
                </div>
                <div className="text-field">
                    <TextField
                        fullWidth
                        autoCapitalize={"none"}
                        label="Contraseña"
                        placeholder="Contraseña"
                        sx={{
                            marginX: "16px",
                            width: "100%",
                        }}
                        type="password"
                        value={password}
                        onChange={(ev: any) => setPassword(ev.target.value)}
                        onKeyPress={(ev: any) =>
                            ev.key === "Enter" && password && email && handleLogin(email, password)
                        }
                    />
                </div>
                <Link
                    style={{
                        width: "100%",
                        paddingTop: "10px",
                        paddingLeft: "32px",
                        textAlign: "left",
                        textDecoration: "none",
                        fontSize: "14px",
                    }}
                    to="/forgot-password"
                >
                    Has olvidado la contraseña?
                </Link>
                <div className="login-button">
                    <Button
                        disabled={loading || !email || !password || !validator.isEmail(email)}
                        sx={{
                            marginRight: "8px",
                        }}
                        variant="contained"
                        onClick={() => handleLogin(email, password)}
                    >
                        Ingresar
                    </Button>
                    {loading && <CircularProgress size={16} />}
                </div>
            </Card>
        </div>
    );
};

export default withRouter(Login);
