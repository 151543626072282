import React, { useContext, useEffect, useState } from "react";

import { Box, Tab, Tabs } from "@mui/material";
import { useParams } from "react-router-dom";

import EmailsTable from "../../../components/emails/table";
import RequirementDetail from "../../../components/requirements/detail";
import SubmissionsTable from "../../../components/submissions/table";
import { EmailsContext } from "../../../context/emails/EmailsContext";
import { RequirementsContext } from "../../../context/requirements/RequirementsContext";
import { SubmissionsContext } from "../../../context/submissions/SubmissionsContext";
import { EmailsResponse } from "../../../interfaces/api/emails";
import { SubmissionsResponse } from "../../../interfaces/api/submissions";
import { Requirement } from "../../../interfaces/requirements";
import CommonLayout from "../../../layout/common";
import { TabPanel } from "../../../ui/tab-panel";

import "./index.scss";

type RequirementParams = {
    id: string;
};

export const ViewRequirement = () => {
    const { id } = useParams<RequirementParams>();
    const { getRequirementById, loading } = useContext(RequirementsContext);
    const [requirement, setRequirement] = useState<Requirement | undefined>(undefined);
    const [submissions, setSubmissions] = useState<SubmissionsResponse[] | undefined>(undefined);
    const [emails, setEmails] = useState<EmailsResponse[] | undefined>(undefined);
    const { getSubmissionsByRequirement, loading: submissionsLoading } = useContext(SubmissionsContext);
    const { getEmailsByRequirement, loading: emailsLoading } = useContext(EmailsContext);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        const getRequirement = async () => {
            const resp = await getRequirementById(id);

            setRequirement(resp);
        };
        const getRequirementSubmissions = async (id: string) => {
            const subs = await getSubmissionsByRequirement(id);

            setSubmissions(subs);
        };
        const getRequirementEmails = async (id: string) => {
            const emails = await getEmailsByRequirement(id);

            setEmails(emails);
        };

        if (id) {
            getRequirement();
            getRequirementSubmissions(id);
            getRequirementEmails(id);
        }
    }, [id]);

    return (
        <div className="pages-view-requirement">
            <CommonLayout loading={loading} title="Detalle de requerimiento">
                {!loading && (
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs value={tab} onChange={(ev, newValue) => setTab(newValue)}>
                                <Tab label="Detalle" />
                                <Tab label="Recepciones" />
                                <Tab label="Emails" />
                            </Tabs>
                        </Box>
                        <TabPanel index={0} value={tab}>
                            {requirement && <RequirementDetail requirement={requirement} />}
                        </TabPanel>
                        <TabPanel index={1} value={tab}>
                            <div>
                                {submissions && submissions.length > 0 ? (
                                    <SubmissionsTable submissions={submissions} />
                                ) : (
                                    "No hay recepciones"
                                )}
                            </div>
                        </TabPanel>
                        <TabPanel index={2} value={tab}>
                            <div>
                                {emails && emails.length > 0 ? (
                                    <EmailsTable isDetailSection emails={emails} />
                                ) : (
                                    "No hay emails"
                                )}
                            </div>
                        </TabPanel>
                    </Box>
                )}
            </CommonLayout>
        </div>
    );
};
