import React, { useContext, useEffect, useState } from "react";

//import { ErrorBoundary } from "react-error-boundary";
import { User } from "@supabase/supabase-js";
import { Cookies } from "react-cookie";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";

//import { ErrorFallback } from "../../components/common/errors/error-boundary";
import api from "../../api";
import { supabase } from "../../utils/supabase";
import useApp from "../../hooks/useApp";
import { CircularProgress } from "@mui/material";
import { UserContext } from "../../context/user/UserContext";
import CeleriLoading from "../../components/celeri/loading";

interface PropTypes {
    component?: React.FC | React.ComponentType | undefined;
    render?: ((props: RouteComponentProps) => React.ReactNode) | undefined;
    path?: string;
    exact?: boolean;
}

const PrivateRoute = ({ component, ...args }: PropTypes): JSX.Element => {
    const { type } = useApp();
    const { user, organization, organizations, getOrganizations } = useContext(UserContext);
    const [isWating, setIsWating] = useState(true);

    const finishLoading = async () => {
        if (user !== undefined) {
            if (user && !organizations) {
                await getOrganizations();
            }
            setIsWating(false);
        }
    };

    useEffect(() => {
        finishLoading();
    }, [user]);

    if (isWating) {
        return <CeleriLoading />;
    }

    if (user === null) {
        return <Redirect to="/login" />;
    }

    if (type === "CUMPLIMIENTO" && !(organization && organization.regulations_subscription)) {
        return <Redirect to="/" />;
    }

    return <Route component={component} {...args} />;
};

export default PrivateRoute;
