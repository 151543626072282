import React, { createContext, useContext, useReducer } from "react";

import { useSnackbar } from "notistack";

import api from "../../api";
import { UserReducer, UserState } from "./UserReducer";
import { User } from "@supabase/supabase-js";
import { Organization } from "../../interfaces/user";
import { ApiOutlined } from "@mui/icons-material";

type UserContextProps = {
    user?: User | null;
    organizations?: Organization[];
    organization?: Organization;
    errorMessage?: string;
    loading: boolean;
    setUser: (user: User | null) => Promise<void>;
    getOrganizations: () => Promise<void>;
    removeError: () => void;
};

const userInitialState: UserState = {
    user: undefined,
    errorMessage: undefined,
    loading: false,
    organizations: undefined,
    organization: undefined,
};

export const UserContext = createContext({} as UserContextProps);

export const UserProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(UserReducer, userInitialState);
    const { enqueueSnackbar } = useSnackbar();

    const onSuccess = () => {
        dispatch({
            type: "removeError",
        });
        dispatch({
            type: "setLoading",
            payload: false,
        });
    };

    const onError = (errorMsg?: string) => {
        dispatch({
            type: "addError",
            payload: errorMsg || "Hubo un error",
        });
        dispatch({
            type: "setLoading",
            payload: false,
        });
        enqueueSnackbar(errorMsg || "Hubo un error", {
            variant: "error",
        });
    };

    const setUser = async (user: User | null) => {
        if (user && (!state.user || state.user.id !== user.id)) {
            window.analytics.identify(
                user.id,
                {
                    email: user.email,
                },
                {},
                () => console.log(),
            );
        }
        dispatch({
            type: "setUser",
            payload: user,
        });
    };

    const getOrganizations = async () => {
        try {
            const r = await api.get("organizations");

            dispatch({
                type: "setOrganizations",
                payload: r.data,
            });
            dispatch({
                type: "setOrganization",
                payload: r.data[0],
            });
        } catch (e) {
            dispatch({
                type: "setOrganizations",
                payload: undefined,
            });
        }
    };

    const removeError = () => {
        dispatch({ type: "removeError" });
    };

    return (
        <UserContext.Provider
            value={{
                ...state,
                setUser,
                getOrganizations,
                removeError,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
