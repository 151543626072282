export interface ReportsState {
    errorMessage?: string;
    loading: boolean;
    reports?: string[];
}

type ReportsActions =
    | { type: "setReports"; payload: string[] | undefined }
    | { type: "addError"; payload: string }
    | { type: "setLoading"; payload: boolean }
    | { type: "cleanReports" }
    | { type: "removeError" };

export const ReportsReducer = (state: ReportsState, action: ReportsActions): ReportsState => {
    switch (action.type) {
        case "setLoading":
            return {
                ...state,
                loading: action.payload,
            };
        case "addError":
            return {
                ...state,
                errorMessage: action.payload,
            };
        case "removeError":
            return {
                ...state,
                errorMessage: undefined,
            };
        case "setReports":
            return {
                ...state,
                reports: action.payload,
            };
        case "cleanReports":
            return {
                ...state,
                reports: undefined,
            };
        default:
            return state;
    }
};
