import * as React from "react";

import { Box, Card, CircularProgress, Grid } from "@mui/material";
import { Cookies } from "react-cookie";
import Retool from "react-retool";

import { ReportsContext } from "../../context/reports/ReportsContext";
import CommonLayout from "../../layout/common";

import "./index.scss";

export const Reports = () => {
    const cookies = new Cookies();
    const [report, setReport] = React.useState(0);
    const { reports, getReports } = React.useContext(ReportsContext);

    React.useEffect(() => {
        if (!reports) getReports();
    }, []);

    return (
        <div className="pages-reports">
            <CommonLayout loading={false} title="Tableros">
                <div className="container">
                    {!reports && <CircularProgress />}
                    {reports && reports.length === 0 && "No hay tableros disponibles para su organización"}
                    {reports && reports.length === 1 && (
                        <Retool data={{ token: cookies.get("access_token_api") }} url={reports[0]} />
                    )}
                    {reports && reports.length > 1 && (
                        <Box sx={{ height: "100%" }}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="start"
                                spacing={2}
                                sx={{ marginBottom: "16px" }}
                            >
                                {reports.map((url, index) => (
                                    <Grid key={index} item xs md={4} sm={6}>
                                        <Card
                                            sx={{
                                                cursor: "pointer",
                                                padding: "24px",
                                                backgroundColor: report === index ? "#F0F0F0" : "",
                                            }}
                                            onClick={() => setReport(index)}
                                        >
                                            {url}
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                            <Retool
                                key={reports[report]}
                                data={{ token: cookies.get("access_token_api") }}
                                url={reports[report]}
                            />
                        </Box>
                    )}
                </div>
            </CommonLayout>
        </div>
    );
};
