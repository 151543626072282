import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
    DataGrid,
    DataGridProps,
    GridFooter,
    GridFooterContainer,
    GridSortModel,
    GridToolbarExport,
} from "@mui/x-data-grid";

function CustomFooter() {
    return (
        <GridFooterContainer>
            <GridToolbarExport touchRippleRef={undefined} />
            <GridFooter />
        </GridFooterContainer>
    );
}

type PropTypes = Omit<
    DataGridProps,
    | "apiRef"
    | "checkboxSelectionVisibleOnly"
    | "disableColumnResize"
    | "disableColumnReorder"
    | "disableMultipleColumnsFiltering"
    | "disableMultipleColumnsSorting"
    | "disableMultipleSelection"
    | "disableChildrenFiltering"
    | "disableChildrenSorting"
    | "throttleRowsMs"
    | "hideFooterRowCount"
    | "options"
    | "onRowsScrollEnd"
    | "scrollEndThreshold"
    | "signature"
> & {
    pagination?: true | undefined;
    initialSortModel: GridSortModel;
}; // to fix problem with pagination types

const useStyles = makeStyles(() => ({
    root: {
        border: 0,
        borderTop: "1px solid #E0E0E0",
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(72, 94, 144, 0.06)",
        },
        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within": {
            outline: "none",
        },

        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-row:nth-child(even):not(:hover)": {
            backgroundColor: "#fafafa",
        },
        "& .MuiDataGrid-footerContainer": {
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            padding: "2px",
        },
        "& .MuiButton-root": {
            paddingLeft: "10px",
        },
        "& .MuiDataGrid-row": {
            cursor: "pointer",
        },
    },
}));

export const Table = (props: PropTypes) => {
    const { initialSortModel, ...args } = props;
    const [sortModel, setSortModel] = React.useState(props.initialSortModel);
    const [pageSize, setPageSize] = React.useState(10);
    const classes = useStyles();

    return (
        <div className="ui-table" style={{ display: "flex", width: "100%" }}>
            <DataGrid
                autoHeight
                disableSelectionOnClick
                classes={{
                    root: classes.root,
                }}
                components={{
                    Footer: CustomFooter,
                }}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 25, 50, 100]}
                sortModel={sortModel}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onSortModelChange={(sort) => setSortModel(sort)}
                {...args}
            />
        </div>
    );
};
