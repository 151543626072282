import React from "react";

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { Contact } from "../../interfaces/contacts";
import { RequirementImportExcel } from "../../interfaces/requirements";

interface PropsTypes {
    data: Contact[] | RequirementImportExcel[] | undefined;
    columns: GridColDef[];
    loading: boolean;
    cleanData: () => void;
    completePreview: () => void;
}

const ImportPreviewDialog = (props: PropsTypes) => {
    return (
        <Dialog fullWidth maxWidth="md" open={Boolean(props.data)} onClose={props.cleanData}>
            <DialogTitle>Preview {}</DialogTitle>
            {props.data && (
                <DialogContent sx={{ height: 500, width: "100%" }}>
                    <DataGrid
                        columns={props.columns.filter((c) => c.field !== "actions")}
                        getRowId={(row: any) => row.id_contact || row.id_requirement}
                        rows={props.data}
                    />
                </DialogContent>
            )}
            <DialogActions>
                <Button color="secondary" variant="outlined" onClick={props.cleanData}>
                    cancelar
                </Button>
                <Button color="primary" disabled={props.loading} variant="contained" onClick={props.completePreview}>
                    {props.loading ? <CircularProgress size={18} /> : "Cargar"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImportPreviewDialog;
