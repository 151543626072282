import React, { createContext, useContext, useReducer } from "react";

import { useSnackbar } from "notistack";

import api from "../../api";
import { AnalyticsResponse, CumplimientoAnalyticsResponse } from "../../interfaces/api/home";
import { HomeReducer, HomeState } from "./HomeReducer";

type HomeContextProps = {
    analytics?: AnalyticsResponse;
    cumplimientoAnalytics?: CumplimientoAnalyticsResponse;
    errorMessage?: string;
    loading: boolean;
    getAnalytics: () => Promise<void>;
    getCumplimientoAnalytics: () => Promise<void>;
    removeError: () => void;
    cleanAnalytics: () => void;
};

const homeInitialState: HomeState = {
    errorMessage: undefined,
    loading: false,
    analytics: undefined,
    cumplimientoAnalytics: undefined,
};

export const HomeContext = createContext({} as HomeContextProps);

export const HomeProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(HomeReducer, homeInitialState);
    const { enqueueSnackbar } = useSnackbar();

    const onSuccess = () => {
        dispatch({
            type: "removeError",
        });
        dispatch({
            type: "setLoading",
            payload: false,
        });
    };

    const onError = (errorMsg?: string) => {
        dispatch({
            type: "addError",
            payload: errorMsg || "Hubo un error",
        });
        dispatch({
            type: "setLoading",
            payload: false,
        });
        enqueueSnackbar(errorMsg || "Hubo un error", {
            variant: "error",
        });
    };

    const getAnalytics = async () => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<AnalyticsResponse>("/home/analytics");
            const analytics = resp.data;

            dispatch({
                type: "setAnalytics",
                payload: analytics || undefined,
            });
            onSuccess();
        } catch (error: any) {
            onError(error.response?.data?.message);
            cleanAnalytics();
        }
    };

    const getCumplimientoAnalytics = async () => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<CumplimientoAnalyticsResponse>("/cumplimiento/home/analytics");
            const analytics = resp.data;

            dispatch({
                type: "setCumplimientoAnalytics",
                payload: analytics || undefined,
            });
            onSuccess();
        } catch (error: any) {
            onError(error.response?.data?.message);
            cleanAnalytics();
        }
    };

    const cleanAnalytics = () => {
        dispatch({ type: "cleanAnalytics" });
    };

    const removeError = () => {
        dispatch({ type: "removeError" });
    };

    return (
        <HomeContext.Provider
            value={{
                ...state,
                getAnalytics,
                cleanAnalytics,
                getCumplimientoAnalytics,
                removeError,
            }}
        >
            {children}
        </HomeContext.Provider>
    );
};
