import React, { createContext, useReducer } from "react";

import { DialogReducer, DialogState } from "./DialogReducer";

type DialogContextProps = {
    open: boolean;
    options: any;
    openDialog: (options: any) => void;
    closeDialog: () => void;
};

const DialogInitialState: DialogState = {
    open: false,
    options: undefined,
};

export const DialogContext = createContext({} as DialogContextProps);

export const DialogProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(DialogReducer, DialogInitialState);

    const openDialog = (options: any) => {
        dispatch({
            type: "openDialog",
            payload: options,
        });
    };

    const closeDialog = () => {
        dispatch({
            type: "closeDialog",
        });
    };

    return (
        <DialogContext.Provider
            value={{
                ...state,
                openDialog,
                closeDialog,
            }}
        >
            {children}
        </DialogContext.Provider>
    );
};
