import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Cookies } from "react-cookie";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { UserContext } from "../context/user/UserContext";
import useApp from "../hooks/useApp";

import Contacts from "../pages/contacts";
import { AddContact } from "../pages/contacts/create";
import { EditContact } from "../pages/contacts/edit";
import Deadlines from "../pages/cumplimiento/deadlines";
import { ViewDeadlines } from "../pages/cumplimiento/deadlines/view";
import Entities from "../pages/cumplimiento/entities";
import HomeCumplimiento from "../pages/cumplimiento/home";
import Novedades from "../pages/cumplimiento/novedades";
import { ViewNew } from "../pages/cumplimiento/novedades/view";
import Regulations from "../pages/cumplimiento/regulations";
import Emails from "../pages/emails";
import ForgotPassword from "../pages/forgot-password";
import { FormBuilder } from "../pages/form-builder";
import Home from "../pages/home";
import Login from "../pages/login";
import Logout from "../pages/logout";
import { Reports } from "../pages/reports";
import Requirements from "../pages/requirements";
import { AddRequirement } from "../pages/requirements/create";
import { ViewRequirement } from "../pages/requirements/view";
import Submissions from "../pages/submissions";
import { ViewSubmission } from "../pages/submissions/view";
import Templates from "../pages/templates";
import { AddTemplate } from "../pages/templates/create";
import { EditTemplate } from "../pages/templates/edit";
import { supabase } from "../utils/supabase";
import PrivateRoute from "./private-route/private-route";

export const AppRouter: React.FC = () => {
    const { user, setUser } = useContext(UserContext);

    useEffect(() => {
        const cookies = new Cookies();
        const session = supabase.auth.session();

        setUser(session?.user ?? null);
        cookies.set("access_token_api", session?.access_token, { path: "/" });

        const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
            const currentUser = session?.user;

            setUser(currentUser ?? null);
        });

        return () => {
            authListener?.unsubscribe();
        };
    }, []);

    return (
        <>
            <Router>
                <Switch>
                    <PrivateRoute exact component={Home} path="/" />
                    {/* Cumplimiento */}
                    <PrivateRoute
                        path="/cumplimiento"
                        render={({ match: { url } }) => (
                            <>
                                <PrivateRoute exact component={HomeCumplimiento} path={`${url}/`} />
                                <PrivateRoute exact component={Novedades} path={`${url}/novedades`} />
                                <PrivateRoute component={ViewNew} path={`${url}/novedades/:id`} />
                                <PrivateRoute exact component={Deadlines} path={`${url}/deadlines`} />
                                <PrivateRoute component={ViewDeadlines} path={`${url}/deadlines/:id`} />
                                <PrivateRoute exact component={Regulations} path={`${url}/regulations`} />
                                <PrivateRoute exact component={Entities} path={`${url}/entities`} />
                                {/* 
                                <PrivateRoute component={Novedades} path={`${url}/obligaciones`} />
                                <PrivateRoute component={Novedades} path={`${url}/entidades`} /> */}
                            </>
                        )}
                    />

                    {/* Requerimientos */}
                    <PrivateRoute exact component={Submissions} path="/submissions" />
                    <PrivateRoute component={ViewSubmission} path="/submissions/:id" />
                    <PrivateRoute exact component={Contacts} path="/contacts" />
                    <PrivateRoute component={AddContact} path="/contacts/new" />
                    <PrivateRoute component={EditContact} path="/contacts/:id" />
                    <PrivateRoute component={Emails} path="/emails" />
                    <PrivateRoute exact component={Templates} path="/templates" />
                    <PrivateRoute exact component={AddTemplate} path="/templates/new" />
                    <PrivateRoute component={EditTemplate} path="/templates/:id" />
                    <PrivateRoute exact component={Requirements} path="/requirements" />
                    <PrivateRoute component={AddRequirement} path="/requirements/new" />
                    <PrivateRoute component={ViewRequirement} path="/requirements/:id" />
                    <PrivateRoute component={FormBuilder} path="/form-builder" />
                    <PrivateRoute component={Reports} path="/reports" />
                    <PrivateRoute component={Reports} path="/reports/:id" />
                    <Route component={Login} path="/login" />
                    <Route component={ForgotPassword} path="/forgot-password" />
                    <Route component={Logout} path="/logout" />
                </Switch>
            </Router>
        </>
    );
};
