import React, { useContext } from "react";

import { Button, ButtonGroup } from "@mui/material";

import "./index.scss";
import { UserContext } from "../../../context/user/UserContext";

interface PropTypes {
    onClick: (f?: "pending" | "approved" | "rejected") => void;
    value?: "pending" | "approved" | "rejected";
}

export const SubmissionsFilters = (props: PropTypes) => {
    const { onClick, value } = props;
    const { user, organization } = useContext(UserContext);

    const handleClickFilter = (v: "pending" | "approved" | "rejected") => (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        window.analytics.track("click_filter_button_submissions", {
            filter: v,
            id_user: user?.id,
            id_organization: organization?.id_organization,
        });
        if (value === v) {
            onClick();
        } else {
            onClick(v);
        }
    };

    return (
        <div className="filters-container">
            <ButtonGroup>
                <Button
                    color={value === "pending" ? "secondary" : "primary"}
                    size="small"
                    variant={value === "pending" ? "contained" : "outlined"}
                    onClick={handleClickFilter("pending")}
                >
                    Pendientes
                </Button>
                <Button
                    color={value === "approved" ? "secondary" : "primary"}
                    size="small"
                    variant={value === "approved" ? "contained" : "outlined"}
                    onClick={handleClickFilter("approved")}
                >
                    Aceptadas
                </Button>
                <Button
                    color={value === "rejected" ? "secondary" : "primary"}
                    size="small"
                    variant={value === "rejected" ? "contained" : "outlined"}
                    onClick={handleClickFilter("rejected")}
                >
                    Rechazadas
                </Button>
            </ButtonGroup>
        </div>
    );
};
