import React from "react";

import { FileIcon, defaultStyles, DefaultExtensionType } from "react-file-icon";

import { Attachment } from "../../interfaces/api/submissions";

import "./index.scss";

interface PropTypes {
    file: File | Attachment;
    onClick?: () => void;
}

export const FilePreview = ({ file, onClick }: PropTypes) => {
    const fileExtension = file.name.split(".")[1];

    const getStyles = () => {
        switch (fileExtension) {
            case "pdf":
            case "txt":
            case "png":
            case "jpg":
            case "jpeg": {
                return defaultStyles[fileExtension];
            }
            case "xls":
            case "xlsx":
            case "doc":
            case "docx": {
                return { ...defaultStyles[fileExtension], color: undefined, foldColor: undefined };
            }
            default: {
                return undefined;
            }
        }
    };

    return (
        <div className="ui-file-preview" onClick={onClick}>
            <FileIcon extension={fileExtension} {...getStyles()} />
            <div>{file.name}</div>
        </div>
    );
};
