import React from "react";

import { DialogContentText } from "@mui/material";
import Button from "@mui/material/Button";

import { Contact } from "../../../interfaces/contacts";
import { DialogContent } from "../../../ui/dialog/content";
import { FormLabel } from "../../../ui/form-label";

import "./index.scss";

interface PropTypes {
    contact: Contact;
    onClick: (id: string) => void;
}
export const ContactDialog = ({ contact, onClick }: PropTypes) => {
    const handleClickContactDetail = () => {
        onClick(contact.id_contact);
    };

    return (
        <DialogContent displayCloseButton buttons={<Button onClick={handleClickContactDetail}>Ver detalle</Button>}>
            <DialogContentText id="alert-dialog-description">
                <FormLabel label="Primer Nombre" values={[contact.first_name]} />
                {contact.middle_name && <FormLabel label="Segundo Nombre" values={[contact.middle_name]} />}
                <FormLabel label="Apellido" values={[contact.last_name]} />
                <FormLabel label="Email" values={[contact.email]} />
                {contact.phone && <FormLabel label="Teléfono" values={[contact.phone]} />}
            </DialogContentText>
        </DialogContent>
    );
};
