import React, { createContext, useReducer } from "react";

import _, { isArray } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";

import api from "../../api";
import { DeadlinesResponse, Version } from "../../interfaces/api/deadlines";
import { Deadline, DeadlineImportExcel, DeadlinesFilterType } from "../../interfaces/deadlines";
import { DeadlinesReducer, DeadlinesState } from "./DeadlinesReducer";

type DeadlinesContextProps = {
    deadlines?: DeadlinesResponse[];
    deadline?: Deadline;
    viewVersion?: Version;
    dayDeadlines?: DeadlinesResponse[];
    weekDeadlines?: DeadlinesResponse[];
    monthDeadlines?: DeadlinesResponse[];
    yearDeadlines?: DeadlinesResponse[];
    errorMessage?: string;
    loading: boolean;
    getDeadlines: () => Promise<void>;
    setViewVersion: (version: Version | undefined) => Promise<void>;
    getDeadlinesByFilter: (v: DeadlinesFilterType) => Promise<void>;
    getDeadlineById: (id: string) => Promise<Deadline | undefined>;
    getDeadlinesByContact: (contactId: string) => Promise<DeadlinesResponse[] | undefined>;
    getDeadlinesByTemplate: (contactId: string) => Promise<DeadlinesResponse[] | undefined>;
    removeError: () => void;
    cleanDeadlines: () => void;
    createDeadline: (deadline: Partial<Deadline> | DeadlineImportExcel[], by: string) => Promise<boolean>;
    updateDeadline: (deadline: Partial<Deadline>, id: string) => Promise<boolean>;
    cancelDeadline: (id: string) => Promise<void>;
    pauseDeadline: (id: string) => Promise<void>;
    resumeDeadline: (id: string) => Promise<void>;
    restartDeadline: (id: string) => Promise<void>;
    completeDeadline: (id: string) => Promise<void>;
    createFiling: (id: string, body: string, attachments: File[], status: "DRAFT" | "SUBMITTED") => Promise<void>;
    createVersion: (id: string, body: string, attachments: File[], status: "DRAFT" | "SUBMITTED") => Promise<void>;
    createNewVersion: (deadline: Deadline) => Promise<void>;
    updateVersion: (
        id_deadline: string,
        id_version: string,
        status: "DRAFT" | "REJECTED" | "SUBMITTED",
        body?: string,
    ) => Promise<void>;
};

const deadlinesInitialState: DeadlinesState = {
    loading: false,
    errorMessage: undefined,
    deadlines: undefined,
    deadline: undefined,
    viewVersion: undefined,
};

export const DeadlinesContext = createContext({} as DeadlinesContextProps);

export const DeadlinesProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(DeadlinesReducer, deadlinesInitialState);
    const { enqueueSnackbar } = useSnackbar();

    const onSuccess = (action: boolean) => {
        dispatch({
            type: "removeError",
        });
        if (action) {
            dispatch({
                type: "setLoadingAction",
                payload: { value: false, type: undefined, reqId: undefined },
            });
        } else {
            dispatch({
                type: "setLoading",
                payload: false,
            });
        }
    };

    const onSuccessWithMessage = (action: boolean, successMessage: string) => {
        onSuccess(action);
        enqueueSnackbar(successMessage, {
            variant: "success",
        });
    };

    const onError = (action: boolean, errorMsg?: string) => {
        dispatch({
            type: "addError",
            payload: errorMsg || "Hubo un error",
        });
        if (action) {
            dispatch({
                type: "setLoadingAction",
                payload: { value: false, type: undefined, reqId: undefined },
            });
        } else {
            dispatch({
                type: "setLoading",
                payload: false,
            });
        }
        enqueueSnackbar(errorMsg || "Hubo un error", {
            variant: "error",
        });
    };

    const getDeadlines = async () => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<DeadlinesResponse[]>("/deadlines?");
            const deadlines = resp.data.map((d: any) => ({
                ...d,
                filings: d.filings[0],
            }));

            dispatch({
                type: "setDeadlines",
                payload: deadlines || undefined,
            });
            onSuccess(false);
        } catch (error: any) {
            onError(false, error.response?.data?.message);
            cleanDeadlines();
        }
    };

    const getDeadlineById = async (id: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get(`/deadlines/${id}`);
            const deadline = {
                ...resp.data,
                filings: resp.data.filings[0],
                /* versions: [
                    {
                        id_version: "32ljkflkasdf",
                        name: "Version 1",
                        number: 1,
                        attachemnts: [{ path: "sdaf", name: "balance 2022.pdf", mimetype: "application/pdf" }],
                        body: "esto es una observacion",
                        status: "DRAFT",
                        created_at: "2022-03-01T00:00:00",
                        updated_at: "2022-03-01T00:00:00",
                        created_by: {
                            id: "324u234234234",
                            name: "Andrés Ryzy",
                        },
                    },
                    {
                        id_version: "32ljkflkasdf",
                        name: "Version 2",
                        number: 2,
                        attachemnts: [
                            {
                                path: "sdaf/asdf.pdf",
                                name: "balance 2022 - v2.pdf",
                                mimetype: "application/pdf",
                            },
                        ],
                        body: "esto es una observacion. cambiamos a la version 2 porque estaba mal ganancias",
                        status: "DRAFT",
                        created_at: "2022-03-10T00:00:00",
                        updated_at: "2022-03-10T00:00:00",
                        created_by: {
                            id: "324u234234234",
                            name: "Lucas Ranallo",
                        },
                    },
                ], */
            };

            onSuccess(false);

            dispatch({
                type: "setDeadline",
                payload: deadline || undefined,
            });

            return deadline;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };
    const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

    const createFiling = async (id: string, body: string, attachments: File[], status: "DRAFT" | "SUBMITTED") => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });

            const formData = new FormData();

            for (let i = 0; i < attachments.length; i++) {
                formData.append("attachments", attachments[i]);
            }
            formData.append("body", body);
            formData.append("status", status);

            const resp = await api.post<any>(`/deadlines/${id}`, formData);

            //await sleep(2000);
            const deadline = await getDeadlineById(id);

            if (deadline && deadline.filings) {
                const last_version: Version | undefined = _.maxBy<Version | undefined>(
                    deadline.filings.versions,
                    "updated_at",
                );

                setViewVersion(last_version);
            }

            onSuccessWithMessage(false, "Hemos guardado una nueva versión");
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const createVersion = async (id: string, body: string, attachments: File[], status: "DRAFT" | "SUBMITTED") => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });

            const formData = new FormData();

            for (let i = 0; i < attachments.length; i++) {
                formData.append("attachments", attachments[i]);
            }
            formData.append("body", body);
            formData.append("status", status);

            const resp = await api.post<any>(`/deadlines/${id}/versions`, formData);

            //await sleep(2000);
            const deadline = await getDeadlineById(id);

            if (deadline && deadline.filings) {
                const last_version: Version | undefined = _.maxBy<Version | undefined>(
                    deadline.filings.versions,
                    "updated_at",
                );

                setViewVersion(last_version);
            }
            onSuccessWithMessage(false, "Hemos guardado una nueva versión");
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const createNewVersion = async (deadline: Deadline) => {
        dispatch({
            type: "updateDeadline",
            payload: {
                filings: {
                    ...deadline.filings,
                    versions: [
                        ...deadline.filings.versions,
                        {
                            name: "Version " + deadline.filings.versions.length + 1,
                            number: deadline.filings.versions.length + 1,
                            attachemnts: [],
                            body: "",
                        },
                    ],
                },
            },
        });
    };

    const updateVersion = async (
        id_deadline: string,
        id_version: string,
        status: "DRAFT" | "REJECTED" | "SUBMITTED",
        body?: string,
    ) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });

            const resp = await api.patch<any>(`/deadlines/${id_deadline}/${id_version}`, { status, body });

            //await sleep(2000);
            await getDeadlineById(id_deadline);
            onSuccessWithMessage(
                false,
                status === "DRAFT" ? "Versión actualizada con éxito" : "Versión presentada con éxito",
            );
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const setViewVersion = async (version: Version | undefined) =>
        dispatch({ type: "setViewVersion", payload: version });

    const getDeadlinesByContact = async (contactId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<DeadlinesResponse[]>(`/deadlines?contact->>id_contact=eq.${contactId}`);

            onSuccess(false);

            return data;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const getDeadlinesByFilter = async (status: DeadlinesFilterType) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const day = moment().format("YYYY-MM-DD");

            if (status === DeadlinesFilterType.day) {
                const { data } = await api.get<DeadlinesResponse[]>(
                    "/deadlines?regulation->>type=eq.RECURRING&date=eq." + day,
                );
                const deadlines = data.map((d: any) => ({
                    ...d,
                    filings: d.filings[0],
                }));

                dispatch({
                    type: "setDayDeadlines",
                    payload: deadlines || undefined,
                });
            } else if (status === DeadlinesFilterType.week) {
                const startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
                const endOfWeek = moment().endOf("week").format("YYYY-MM-DD");
                const { data } = await api.get<DeadlinesResponse[]>(
                    `/deadlines?regulation->>type=eq.RECURRING&date=gte.${startOfWeek}&date=lte.${endOfWeek}`,
                );
                const deadlines = data.map((d: any) => ({
                    ...d,
                    filings: d.filings[0],
                }));

                dispatch({
                    type: "setWeekDeadlines",
                    payload: deadlines || undefined,
                });
            } else if (status === DeadlinesFilterType.month) {
                const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
                const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
                const { data } = await api.get<DeadlinesResponse[]>(
                    `/deadlines?regulation->>type=eq.RECURRING&date=gte.${startOfMonth}&date=lte.${endOfMonth}`,
                );
                const deadlines = data.map((d: any) => ({
                    ...d,
                    filings: d.filings[0],
                }));

                dispatch({
                    type: "setMonthDeadlines",
                    payload: deadlines || undefined,
                });
            } else {
                const startOfYear = moment().startOf("year").format("YYYY-MM-DD");
                const endOfYear = moment().endOf("year").format("YYYY-MM-DD");
                const { data } = await api.get<DeadlinesResponse[]>(
                    `/deadlines?regulation->>type=eq.RECURRING&date=gte.${startOfYear}&date=lte.${endOfYear}`,
                );
                const deadlines = data.map((d: any) => ({
                    ...d,
                    filings: d.filings[0],
                }));

                dispatch({
                    type: "setYearDeadlines",
                    payload: deadlines || undefined,
                });
            }
            onSuccess(false);
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const getDeadlinesByTemplate = async (templateId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<DeadlinesResponse[]>(`/deadlines?template->>id_template=eq.${templateId}`);

            onSuccess(false);

            return data;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const createDeadline = async (deadline: Partial<Deadline> | DeadlineImportExcel[], by: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const mapDeadline = () => {
                if (isArray(deadline)) {
                    return deadline.map((r) => ({ ...r, id_deadline: undefined }));
                } else {
                    return {
                        ...deadline,
                        id_deadline: undefined,
                        contact: undefined,
                        template: undefined,
                    };
                }
            };
            const mappedDeadline = mapDeadline();

            if (by === "accounts") {
                await api.post<DeadlinesResponse[]>("/deadlines_from_accounts", mappedDeadline);
            } else {
                await api.post<DeadlinesResponse[]>("/deadlines", mappedDeadline);
            }
            onSuccessWithMessage(false, "El requerimiento ha sido creado correctamente");
            getDeadlines();
            const success = true;

            return success;
        } catch (error: any) {
            onError(false, error.response?.data?.message);
            const success = false;

            return success;
        }
    };

    const updateDeadline = async (deadline: Partial<Deadline>, id: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            await api.patch<DeadlinesResponse[]>(`/deadlines/${id}`, deadline);
            onSuccessWithMessage(false, "El requerimiento ha sido actualizado correctamente");
            getDeadlines();
            const success = true;

            return success;
        } catch (error: any) {
            onError(false, error.response?.data?.message);
            const success = false;

            return success;
        }
    };

    const cancelDeadline = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "cancel", reqId: id },
            });
            await api.post(`/deadlines/${id}/cancel`);
            onSuccessWithMessage(true, "El requerimiento ha sido cancelado correctamente");
            getDeadlines();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };

    const pauseDeadline = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "pause", reqId: id },
            });
            await api.post(`/deadlines/${id}/pause`);
            onSuccessWithMessage(true, "El requerimiento ha sido pausado correctamente");
            getDeadlines();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };

    const completeDeadline = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "complete", reqId: id },
            });
            await api.post(`/deadlines/${id}/complete`);
            onSuccessWithMessage(true, "El requerimiento ha sido completado correctamente");
            getDeadlines();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };

    const resumeDeadline = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "resume", reqId: id },
            });
            await api.post(`/deadlines/${id}/resume`);
            onSuccessWithMessage(true, "El requerimiento ha sido reanudado correctamente");
            getDeadlines();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };

    const restartDeadline = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "restart", reqId: id },
            });
            await api.post(`/deadlines/${id}/restart`);
            onSuccessWithMessage(true, "El requerimiento ha sido reiniciado correctamente");
            getDeadlines();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };
    const removeError = () => {
        dispatch({ type: "removeError" });
    };

    const cleanDeadlines = () => {
        dispatch({ type: "cleanDeadlines" });
    };

    return (
        <DeadlinesContext.Provider
            value={{
                ...state,
                getDeadlines,
                getDeadlinesByFilter,
                cleanDeadlines,
                removeError,
                createDeadline,
                updateDeadline,
                getDeadlineById,
                getDeadlinesByContact,
                getDeadlinesByTemplate,
                cancelDeadline,
                completeDeadline,
                pauseDeadline,
                resumeDeadline,
                restartDeadline,
                createFiling,
                createNewVersion,
                updateVersion,
                createVersion,
                setViewVersion,
            }}
        >
            {children}
        </DeadlinesContext.Provider>
    );
};
