import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CircularProgress, Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import api from "../../../api";
import { HomeContext } from "../../../context/home/HomeContext";
import CommonLayout from "../../../layout/common";

import "./index.scss";

interface MetricsData {
    label: string;
    accessor: "health" | "week_news" | "month_news";
    color: string;
    backgroundColor: string;
    path: string;
}

interface AnalyticsData {
    health: number;
    week_news: number;
    month_news: number;
}

type PropTypes = RouteComponentProps;

const useStyles = makeStyles(() => ({
    root: {
        "&.MuiCard-root": {
            boxShadow: "none",
            textAlign: "center",
            padding: "35px",
            borderRadius: "8px",
            height: "200px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "10px",
            marginRight: "18px",
            textDecoration: "none",
            transitionDuration: "1000ms",
            transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        },
        "&.MuiCard-root:hover": {
            transform: "scale(1.1)",
            transitionDuration: "1000ms",
            transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        },
    },
}));

const HomeCumplimiento = (props: PropTypes) => {
    const { cumplimientoAnalytics, getCumplimientoAnalytics, loading } = React.useContext(HomeContext);

    React.useEffect(() => {
        getCumplimientoAnalytics();
    }, []);

    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const metrics: MetricsData[] = [
        {
            label: "Salud Compliance",
            accessor: "health",
            color: "rgb(255,102,0)",
            backgroundColor: "rgba(255, 102, 0, 0.2)",
            path: "/cumplimiento/pendings",
        },
        {
            label: "Novedades de la semana",
            accessor: "week_news",
            color: "#00AE46",
            backgroundColor: "rgba(0, 250, 95, 0.2)",
            path: "/cumplimiento/novedades?filters=week",
        },
        {
            label: "Novedades del mes",
            accessor: "month_news",
            color: "rgb(10, 100, 150)",
            backgroundColor: "rgba(10, 100, 240, 0.2)",
            path: "/cumplimiento/novedades?filters=month",
        },
    ];

    return (
        <div className="pages-home-cumplimiento">
            <CommonLayout loading={false} title="Home">
                <div className="grid-container">
                    {metrics.map((metric: MetricsData) => (
                        <Grid key={metric.accessor} style={{ width: "195px" }}>
                            <Card
                                classes={{
                                    root: classes.root,
                                }}
                                component={Link}
                                sx={{ color: metric.color, backgroundColor: metric.backgroundColor }}
                                to={metric.path}
                            >
                                <Typography sx={{ fontWeight: "700" }} variant="h2">
                                    {(cumplimientoAnalytics && cumplimientoAnalytics[metric.accessor]) || "-"}
                                </Typography>
                                <Typography sx={{ opacity: 0.72, paddingTop: "25px" }} variant="subtitle2">
                                    {metric.label}
                                </Typography>
                            </Card>
                        </Grid>
                    ))}
                </div>
            </CommonLayout>
        </div>
    );
};

export default withRouter(HomeCumplimiento);
