import React, { useContext, useEffect, useState } from "react";

import { ContentPaste, PeopleAltOutlined, SystemUpdateAlt } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { GridColDef } from "@mui/x-data-grid";
import { RouteComponentProps, withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";

import ImportPreviewDialog from "../../../components/import-preview";
import { NovedadesFilters } from "../../../components/novedades/filters";
import NovedadesTable from "../../../components/novedades/table";
import { NovedadesContext } from "../../../context/novedades/NovedadesContext";
import { NovedadesFilterType, NovedadImportExcel } from "../../../interfaces/novedades";
import CommonLayout from "../../../layout/common";

import "./index.scss";

type PropTypes = RouteComponentProps<any>;

const Novedades = (props: PropTypes) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filters = queryParams.get("filters");

    const getFilterInitialState = (): NovedadesFilterType => {
        if (
            filters === NovedadesFilterType.day ||
            filters === NovedadesFilterType.month ||
            filters === NovedadesFilterType.month
        ) {
            return filters;
        }

        return NovedadesFilterType.week;
    };

    const {
        getNovedades,
        getNovedadesByFilter,
        loading,
        errorMessage,
        novedades,
        dayNovedades,
        weekNovedades,
        monthNovedades,
        allNovedades,
    } = useContext(NovedadesContext);
    const [novedadesFilters, setNovedadesFilters] = useState<NovedadesFilterType | undefined>(getFilterInitialState());

    useEffect(() => {
        if (novedadesFilters === undefined) {
            getNovedades();
        } else {
            getNovedadesByFilter(novedadesFilters);
        }
    }, []);

    const handleChangeFilters = (value?: NovedadesFilterType) => {
        if (value === undefined) {
            getNovedades();
            props.history.push("/cumplimiento/novedades");
        } else {
            getNovedadesByFilter(value);
            props.history.push(`/cumplimiento/novedades?filters=${value}`);
        }
        setNovedadesFilters(value);
    };

    const newsByFilter = {
        day: dayNovedades,
        week: weekNovedades,
        month: monthNovedades,
        all: allNovedades,
    };

    const isLoading = () => {
        if (loading || (!novedades && novedadesFilters && !newsByFilter[novedadesFilters])) {
            return true;
        }

        return false;
    };

    const getRefreshFunction = () => {
        if (novedadesFilters === undefined) {
            return getNovedades();
        }

        return getNovedadesByFilter(novedadesFilters);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="pages-novedades">
            <CommonLayout loading={loading} refreshFunction={getRefreshFunction} title="Novedades">
                <div className="options-novedades">
                    <NovedadesFilters value={novedadesFilters} onClick={handleChangeFilters} />
                </div>
                <NovedadesTable
                    loading={isLoading()}
                    novedades={novedadesFilters ? newsByFilter[novedadesFilters] || [] : novedades || []}
                />
            </CommonLayout>
        </div>
    );
};

export default withRouter(Novedades);
