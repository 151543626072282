import React from "react";

import { CircularProgress } from "@mui/material";
import { Icon, IconButton } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/system";
import { RouteComponentProps, withRouter } from "react-router";

import { Refresh } from "../../components/refresh";
import CustomDialog from "../../ui/dialog";
import { Sidebar } from "../../ui/sidebar";

import "./index.scss";

interface PropTypes extends RouteComponentProps {
    children?: React.ReactChild | React.ReactChild[] | React.ReactNode | React.ReactNode[];
    title?: string;
    loading: boolean;
    refreshFunction?: () => void;
}

const CommonLayout = ({ children, history, title, loading, refreshFunction }: PropTypes) => {
    const drawerWidth = 240;

    const handleClickBack = () => {
        history.goBack();
    };

    return (
        <Box className="common-layout" sx={{ display: "flex", height: "100%" }}>
            <CssBaseline />
            <Sidebar />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    paddingBottom: 0,
                    height: "100vh",
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
            >
                <div className="title-wrapper">
                    <IconButton
                        sx={{ p: 0, cursor: "pointer", zIndex: 5, height: "fit-content", width: "fit-content" }}
                        onClick={handleClickBack}
                    >
                        <Icon style={{ fontSize: 24 }}>arrow_back_ios_icon</Icon>
                    </IconButton>
                    <h1 className="title">{title}</h1>
                    {loading ? (
                        <CircularProgress size={18} sx={{ marginLeft: "8px" }} />
                    ) : (
                        refreshFunction && <Refresh refreshFunction={refreshFunction} />
                    )}
                </div>
                {children}
            </Box>
            <CustomDialog />
        </Box>
    );
};

export default withRouter(CommonLayout);
