import React, { useContext, useEffect, useState } from "react";

import { CircularProgress, Icon, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import DialogContentText from "@mui/material/DialogContentText";
import { GridColDef, GridRenderCellParams, GridSortModel, GridValueGetterParams } from "@mui/x-data-grid";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router";

import { DialogContext } from "../../../context/dialog/DialogContext";
import { RegulationsContext } from "../../../context/regulations/RegulationsContext";
import { RegulationsResponse, RegulationStatus } from "../../../interfaces/api/regulations";
import { Entity } from "../../../interfaces/entities";
import { DialogContent } from "../../../ui/dialog/content";
import { Table } from "../../../ui/table";
import {
    getContactFullName,
    getRegulationStatusClassName,
    getRegulationStatusLabel,
    getRegulationTypeClassName,
    getRegulationTypeLabel,
} from "../../../utils";

import "./index.scss";

interface Action {
    type?: string;
    id?: string;
}

interface PropTypes extends RouteComponentProps<any> {
    regulations: RegulationsResponse[];
    isDetailSection?: boolean;
    loading?: boolean;
}

const RegulationsTable = (props: PropTypes) => {
    const { regulations, loading } = props;
    const { cancelRegulation, completeRegulation, pauseRegulation, resumeRegulation, restartRegulation } = useContext(
        RegulationsContext,
    );

    const { openDialog: openCustomDialog, closeDialog: closeCustomDialog } = useContext(DialogContext);

    const [openDialog, setOpenDialog] = useState(false);
    const [action, setAction] = useState<Action | undefined>(undefined);

    useEffect(() => {
        if (action?.type) {
            openCustomDialog({
                children: <DialogComponent />,
            });
        }
    }, [action]);

    const getActionIcons = (regulation: RegulationsResponse) => [
        {
            label: "pause",
            action: handlePauseRegulation(regulation),
            disabled: handleDisablePauseButton(regulation),
            type: "pause",
        },
        {
            label: "play_arrow",
            action: handleResumeRegulation(regulation),
            disabled: handleDisableResumeButton(regulation),
            type: "resume",
        },
        {
            label: "check",
            action: handleCompleteRegulation(regulation),
            disabled: handleDisableCompleteButton(regulation),
            type: "complete",
        },
        {
            label: "clear",
            action: handleCancelRegulation(regulation),
            disabled: handleDisableCancelButton(regulation),
            type: "cancel",
        },
        {
            label: "cached",
            action: handleRestartRegulation(regulation),
            disabled: handleDisableRestartButton(regulation),
            type: "restart",
        },
    ];

    const handleClickContact = (contactID: string) => (event: any) => {
        event.stopPropagation();
        props.history.push(`/contacts/${contactID}`);
    };
    const handleClickTemplate = (templateID: string) => (event: any) => {
        event.stopPropagation();
        props.history.push(`/templates/${templateID}`);
    };

    const columns: GridColDef[] = [
        {
            field: "date",
            headerName: "Fecha",
            width: 150,
            valueFormatter: (params: any) => {
                return moment(params.value?.toString()).format("DD/MM/YYYY");
            },
        },
        {
            field: "name",
            headerName: "Nombre",
            width: 300,
            renderCell: (params: GridRenderCellParams) => <b>{params.value}</b>,
        },
        {
            field: "regulator",
            headerName: "Regulador",
            width: 300,
            valueGetter: (params: any) => params.row.regulator.name,
            renderCell: (params: GridRenderCellParams) => (
                <p>
                    <b>[{params.row.regulator.code}]</b> {params.row.regulator.name}
                </p>
            ),
        },
        {
            field: "type",
            headerName: "Tipo",
            width: 145,
            renderCell: (params: GridRenderCellParams) => (
                <Chip
                    className={getRegulationTypeClassName(params.row.type)}
                    label={getRegulationTypeLabel(params.row.type)}
                    variant={params.row.type === RegulationStatus.Expired ? "filled" : "outlined"}
                />
            ),
        },
        {
            field: "condition",
            headerName: "Motivo",
            width: 100,
            valueGetter: (params: any) => params.row.condition.name,
        },
        /* {
            field: "status",
            headerName: "Estado",
            width: 145,
            renderCell: (params: GridRenderCellParams) => (
                <Chip
                    className={getRegulationStatusClassName(params.row.status)}
                    label={getRegulationStatusLabel(params.row.status)}
                    variant={params.row.status === RegulationStatus.Expired ? "filled" : "outlined"}
                />
            ),
        }, */
        {
            field: "actions",
            headerName: "Acciones",
            sortable: false,
            filterable: false,
        },
    ];

    const DialogComponent = () => (
        <DialogContent
            buttons={
                <>
                    <Button onClick={handleConfirmDialog}>Sí</Button>
                    <Button autoFocus onClick={() => closeCustomDialog()}>
                        No
                    </Button>
                </>
            }
        >
            <DialogContentText id="alert-dialog-description">{getDialogText()}</DialogContentText>
        </DialogContent>
    );

    const handleCancelRegulation = (regulation: RegulationsResponse) => (event: any) => {
        event.stopPropagation();
        setAction({ type: "cancel", id: regulation.id_regulation });
        //setOpenDialog(true);
    };

    const handlePauseRegulation = (regulation: RegulationsResponse) => (event: any) => {
        event.stopPropagation();
        setAction({ type: "pause", id: regulation.id_regulation });
    };

    const handleCompleteRegulation = (regulation: RegulationsResponse) => (event: any) => {
        event.stopPropagation();
        setAction({ type: "complete", id: regulation.id_regulation });
    };

    const handleRestartRegulation = (regulation: RegulationsResponse) => (event: any) => {
        event.stopPropagation();
        const isForbidden = regulation.status === RegulationStatus.Review;

        if (!isForbidden) {
            setAction({ type: "restart", id: regulation.id_regulation });
        }
    };

    const handleResumeRegulation = (regulation: RegulationsResponse) => (event: any) => {
        event.stopPropagation();
        const isForbidden = regulation.status === RegulationStatus.Review;

        if (!isForbidden) {
            setAction({ type: "resume", id: regulation.id_regulation });
        }
    };

    const handleDisableResumeButton = (regulation: RegulationsResponse) => {
        if (regulation.status === RegulationStatus.Paused) {
            return false;
        } else return true;
    };

    const handleDisablePauseButton = (regulation: RegulationsResponse) => {
        if (regulation.status === RegulationStatus.Pending) {
            return false;
        } else return true;
    };

    const handleDisableCompleteButton = (regulation: RegulationsResponse) => {
        if (
            regulation.status === (RegulationStatus.Completed || RegulationStatus.Cancelled || RegulationStatus.Expired)
        ) {
            return true;
        } else return false;
    };

    const handleDisableCancelButton = (regulation: RegulationsResponse) => {
        if (
            regulation.status === (RegulationStatus.Cancelled || RegulationStatus.Review || RegulationStatus.Completed)
        ) {
            return true;
        } else return false;
    };

    const handleDisableRestartButton = (regulation: RegulationsResponse) => {
        if (regulation.status !== RegulationStatus.Expired) {
            return true;
        } else return false;
    };

    const getDialogText = () => {
        switch (action?.type) {
            case "cancel":
                return "¿Está seguro de que quiere cancelar este requerimiento?";
            case "pause":
                return "¿Está seguro de que quiere pausar este requerimiento?";
            case "resume":
                return "¿Está seguro de que quiere reanudar este requerimiento?";
            case "complete":
                return "¿Está seguro de que quiere completar este requerimiento?";
            case "restart":
                return "¿Está seguro de que quiere reiniciar este requerimiento?";
            default:
                return "";
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        if (!openDialog) {
            setAction(undefined);
        }
    };

    const handleConfirmDialog = () => {
        if (action && action.id) {
            switch (action.type) {
                case "cancel":
                    cancelRegulation(action.id);
                    break;
                case "pause":
                    pauseRegulation(action.id);
                    break;
                case "resume":
                    resumeRegulation(action.id);
                    break;
                case "complete":
                    completeRegulation(action.id);
                    break;
                case "restart":
                    restartRegulation(action.id);
                    break;
                default:
                    return;
            }
            handleCloseDialog();
            closeCustomDialog();
        }
    };

    const initialSortModel: GridSortModel = [
        {
            field: "date",
            sort: "desc",
        },
    ];

    return (
        <div className="regulations-table">
            <Table
                columns={columns}
                getRowClassName={() => "cursor-pointer"}
                getRowId={(row: any) => row["id_regulation"]}
                initialSortModel={initialSortModel}
                loading={loading}
                rows={regulations}
                onRowClick={(params: any, event: any) => {
                    event.stopPropagation();
                    //props.history.push(`/cumplimiento/regulations/${params.row.id_regulation}`);
                }}
            />
        </div>
    );
};

export default withRouter(RegulationsTable);
