import React, { useContext } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import "ace-builds";
import "ace-builds/src-noconflict/ext-error_marker";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/ext-spellcheck";
import "ace-builds/src-noconflict/keybinding-vscode";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/snippets/json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/worker-javascript";
import "ace-builds/src-noconflict/worker-json";
import "ace-builds/webpack-resolver";
import _ from "lodash";
import AceEditor from "react-ace";
import { Controller, useForm } from "react-hook-form";
import { RouteComponentProps, withRouter } from "react-router";
import * as yup from "yup";

import { ContactsContext } from "../../../context/contacts/ContactsContext";
import { Contact } from "../../../interfaces/contacts";
import { difference } from "../../../utils";

import "./index.scss";

interface PropTypes extends RouteComponentProps {
    contact?: Contact;
}

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    accounts: yup.mixed().nullable(),
    first_name: yup.string().required("Este campo es obligatorio"),
    middle_name: yup.string().nullable(),
    last_name: yup.string().required("Este campo es obligatorio"),
    email: yup.string().email("Debe ingresar un email válido").required("Este campo es obligatorio"),
    phone: yup
        .string()
        .nullable()
        .matches(new RegExp(/^\+[1-9]{1}[0-9]{3,14}$/), {
            message: "Ingresar número de teléfono válido: +54114000000",
            excludeEmptyString: true,
        }),
    data: yup.object().nullable(),
});

const ContactForm = (props: PropTypes) => {
    const { contact } = props;
    const { createContact, updateContact, loading, errorMessage } = useContext(ContactsContext);

    const isCreateMode = !contact;

    const initialValues = {
        // used to initialize the data
        id_contact: contact?.id_contact,
        accounts: contact?.accounts?.join(", "),
        first_name: contact?.first_name,
        middle_name: contact?.middle_name,
        last_name: contact?.last_name,
        email: contact?.email,
        phone: contact?.phone,
        data: contact?.data,
    };

    const { control, formState, setValue, handleSubmit } = useForm({
        mode: "onChange",
        defaultValues: initialValues,
        resolver: yupResolver(schema),
    });

    const { dirtyFields, errors } = formState;

    const onSubmit = async (data: Contact) => {
        if (data && isCreateMode) {
            const cleanValues = _.mapValues(data, (v) => (v === "" ? null : v));
            //@ts-ignore
            const success = await createContact(cleanValues);

            if (success) {
                props.history.push("/contacts");
            }
        } else if (data && data.id_contact) {
            //const updates: Partial<Contact> = difference(data, contact);
            //if (Object.keys(data).length !== 0) {
            //@ts-ignore
            const cleanValues = _.mapValues(data, (v) => (v === "" ? null : v));
            const success = await updateContact(
                {
                    ...cleanValues,
                    data: undefined,
                    ...(cleanValues.data || {}),
                    //@ts-ignore
                    accounts:
                        cleanValues.accounts && typeof cleanValues.accounts === "string"
                            ? cleanValues.accounts.split(", ")
                            : cleanValues.accounts,
                },
                cleanValues.id_contact,
            );

            if (success) {
                props.history.push("/contacts");
            }
            //}
        }
    };

    return (
        <div className="contact-form">
            <Box
                noValidate
                autoComplete="off"
                className="ui-form"
                component="form"
                id="form1"
                //@ts-ignore
                onSubmit={handleSubmit(onSubmit)}
            >
                <Controller
                    control={control}
                    name="first_name"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            required
                            error={!!errors.first_name}
                            helperText={errors?.first_name?.message}
                            label="Primer Nombre"
                            variant="outlined"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="middle_name"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={!!errors.middle_name}
                            helperText={errors?.middle_name?.message}
                            label="Segundo Nombre"
                            variant="outlined"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="last_name"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            required
                            error={!!errors.last_name}
                            helperText={errors?.last_name?.message}
                            label="Apellido"
                            variant="outlined"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            required
                            error={!!errors.email}
                            helperText={errors?.email?.message}
                            label="Email"
                            variant="outlined"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="phone"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={!!errors.phone}
                            helperText={errors?.phone?.message}
                            label="Teléfono"
                            variant="outlined"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="accounts"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            //@ts-ignore
                            fullWidth
                            error={!!errors.accounts}
                            helperText={!!errors.accounts?.message}
                            label="Cuentas"
                            value={
                                typeof field.value === "string"
                                    ? field.value
                                    : Array.isArray(field.value)
                                    ? //@ts-ignore
                                      field.value.join(", ")
                                    : ""
                            }
                            variant="outlined"
                            onChange={(ev: any) => {
                                field.onChange({ target: { value: ev.target.value.split(", ") } });
                                setValue(field.name, ev.target.value.split(", "), {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                });
                            }}
                        />
                    )}
                />
                {/* <Controller
          control={control}
          name="data"
          render={({field}) => (
            <AceEditor 
              {...field}
              width="100%"
              height='200'
              value={typeof field.value === "string" ? field.value : JSON.stringify((field.value || {}), null, 4)}
              onChange={(newValue) =>  {
                field.onChange({target: {value: newValue}})
                setValue(field.name, newValue, {shouldDirty: true, shouldValidate: true})
              }}
							fontSize={14}
							mode="json"
							theme="monokai"
							keyboardHandler="vscode"
							highlightActiveLine={true}
							//onValidate={(annotations) => annotations.length ? setInvalidJson(true) : setInvalidJson(false)}
							setOptions={{
								enableLiveAutocompletion: true,
								showLineNumbers: true,
								spellcheck: true,
								enableSnippets: true,
								tabSize: 4,
								useSoftTabs: true,
								maxLines: 15,
								minLines: 4,
							}}
            />
          )}
        /> */}
                <Button disabled={_.isEmpty(dirtyFields) || loading} form="form1" type="submit" variant="outlined">
                    {loading ? <CircularProgress size={16} /> : "Completar"}
                </Button>
            </Box>
        </div>
    );
};

export default withRouter(ContactForm);
