import React from "react";

import TemplateForm from "../../../components/templates/form";
import CommonLayout from "../../../layout/common";

import "./index.scss";

export const AddTemplate = () => {
    return (
        <div className="pages-add-template">
            <CommonLayout loading={false} title="Crear Template">
                <TemplateForm />
            </CommonLayout>
        </div>
    );
};
