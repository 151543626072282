import React, { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import SubmissionForm from "../../../components/submissions/form";
import { SubmissionsContext } from "../../../context/submissions/SubmissionsContext";
import { UserContext } from "../../../context/user/UserContext";
import { SubmissionsResponse } from "../../../interfaces/api/submissions";
import CommonLayout from "../../../layout/common";

import "./index.scss";

type SubmissionParams = {
    id: string;
};

export const ViewSubmission = () => {
    const { id } = useParams<SubmissionParams>();
    const { user, organization } = useContext(UserContext);
    const { getSubmissionById, loading } = useContext(SubmissionsContext);
    const [submission, setSubmission] = useState<SubmissionsResponse | undefined>(undefined);

    const getSubmission = async () => {
        const resp = await getSubmissionById(id);

        setSubmission(resp);
    };

    useEffect(() => {
        if (id) {
            getSubmission();
            window.analytics.track("view_detail_submissions", {
                id_user: user?.id,
                id_submission: id,
                id_organization: organization?.id_organization,
            });
        }
    }, [id]);

    const handleRefreshFunction = () => {
        window.analytics.track("click_refresh_button_submissions", {
            id_user: user?.id,
            id_organization: organization?.id_organization,
        });
        getSubmission();
    };

    return (
        <div className="pages-view-submission">
            <CommonLayout loading={loading} refreshFunction={handleRefreshFunction} title="Detalle de recepción">
                {submission && <SubmissionForm submission={submission} />}
            </CommonLayout>
        </div>
    );
};
