import { DeadlinesResponse, Version } from "../../interfaces/api/deadlines";
import { Deadline } from "../../interfaces/deadlines";

export interface DeadlinesState {
    errorMessage?: string;
    loading: boolean;
    deadlines?: DeadlinesResponse[];
    deadline?: Deadline;
    dayDeadlines?: DeadlinesResponse[];
    weekDeadlines?: DeadlinesResponse[];
    monthDeadlines?: DeadlinesResponse[];
    yearDeadlines?: DeadlinesResponse[];
    viewVersion?: Version;
}

type DeadlinesActions =
    | { type: "setDeadlines"; payload: DeadlinesResponse[] | undefined }
    | { type: "setDeadline"; payload: Deadline | undefined }
    | { type: "updateDeadline"; payload: any }
    | { type: "setDayDeadlines"; payload: DeadlinesResponse[] | undefined }
    | { type: "setWeekDeadlines"; payload: DeadlinesResponse[] | undefined }
    | { type: "setMonthDeadlines"; payload: DeadlinesResponse[] | undefined }
    | { type: "setYearDeadlines"; payload: DeadlinesResponse[] | undefined }
    | { type: "setViewVersion"; payload: Version | undefined }
    | { type: "addError"; payload: string }
    | { type: "setLoading"; payload: boolean }
    | { type: "setLoadingAction"; payload: { value: boolean; type?: string; reqId?: string } }
    | { type: "cleanDeadlines" }
    | { type: "removeError" };

export const DeadlinesReducer = (state: DeadlinesState, action: DeadlinesActions): DeadlinesState => {
    switch (action.type) {
        case "setLoading":
            return {
                ...state,
                loading: action.payload,
            };
        case "setLoadingAction":
            return {
                ...state,
            };
        case "addError":
            return {
                ...state,
                errorMessage: action.payload,
            };
        case "removeError":
            return {
                ...state,
                errorMessage: undefined,
            };
        case "setDeadlines":
            return {
                ...state,
                deadlines: action.payload,
            };
        case "setDeadline":
            return {
                ...state,
                deadline: action.payload,
            };
        case "updateDeadline":
            return {
                ...state,
                deadline: {
                    ...state.deadline,
                    ...action.payload,
                },
            };
        case "setDayDeadlines":
            return {
                ...state,
                dayDeadlines: action.payload,
            };
        case "setWeekDeadlines":
            return {
                ...state,
                weekDeadlines: action.payload,
            };
        case "setMonthDeadlines":
            return {
                ...state,
                monthDeadlines: action.payload,
            };
        case "setYearDeadlines":
            return {
                ...state,
                yearDeadlines: action.payload,
            };
        case "setViewVersion":
            return {
                ...state,
                viewVersion: action.payload,
            };
        case "cleanDeadlines":
            return {
                ...state,
                deadlines: undefined,
            };
        default:
            return state;
    }
};
