import { User } from "@supabase/supabase-js";
import { AnalyticsResponse, CumplimientoAnalyticsResponse } from "../../interfaces/api/home";
import { Organization } from "../../interfaces/user";

export interface UserState {
    errorMessage?: string;
    user?: User | null;
    loading: boolean;
    organizations?: Organization[];
    organization?: Organization;
}

type UserActions =
    | { type: "setUser"; payload: User | undefined | null }
    | { type: "setOrganizations"; payload: Organization[] | undefined }
    | { type: "setOrganization"; payload: Organization | undefined }
    | { type: "removeError" }
    | { type: "addError"; payload: string }
    | { type: "setLoading"; payload: boolean };

export const UserReducer = (state: UserState, action: UserActions): UserState => {
    switch (action.type) {
        case "addError":
            return {
                ...state,
                errorMessage: action.payload,
            };
        case "removeError":
            return {
                ...state,
                errorMessage: undefined,
            };
        case "setUser":
            return {
                ...state,
                user: action.payload,
            };
        case "setOrganizations":
            return {
                ...state,
                organizations: action.payload,
            };
        case "setOrganization":
            return {
                ...state,
                organization: action.payload,
            };
        case "setLoading":
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};
