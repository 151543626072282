import React, { useContext, useEffect, useState } from "react";

import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";

import RequirementsTable from "../../../components/requirements/table";
import SubmissionsTable from "../../../components/submissions/table";
import TemplateForm from "../../../components/templates/form";
import { RequirementsContext } from "../../../context/requirements/RequirementsContext";
import { SubmissionsContext } from "../../../context/submissions/SubmissionsContext";
import { TemplatesContext } from "../../../context/templates/TemplatesContext";
import { RequirementsResponse } from "../../../interfaces/api/requirements";
import { SubmissionsResponse } from "../../../interfaces/api/submissions";
import { Template } from "../../../interfaces/templates";
import CommonLayout from "../../../layout/common";
import { TabPanel } from "../../../ui/tab-panel";

import "./index.scss";

type TemplateParams = {
    id: string;
};

export const EditTemplate = () => {
    const { id } = useParams<TemplateParams>();
    const { getTemplateById, loading } = useContext(TemplatesContext);
    const [template, setTemplate] = useState<Template | undefined | null>(undefined);
    const { getRequirementsByTemplate, loading: requirementsLoading } = useContext(RequirementsContext);
    const { getSubmissionsByTemplate, loading: submissionsLoading } = useContext(SubmissionsContext);
    const [requirements, setRequirements] = useState<RequirementsResponse[] | undefined>(undefined);
    const [submissions, setSubmissions] = useState<SubmissionsResponse[] | undefined>(undefined);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        const getTemplate = async () => {
            const resp = await getTemplateById(id);

            setTemplate(resp);
        };

        const getTemplateRequirements = async (id: string) => {
            const reqs = await getRequirementsByTemplate(id);

            setRequirements(reqs);
        };
        const getTemplateSubmissions = async (id: string) => {
            const subs = await getSubmissionsByTemplate(id);

            setSubmissions(subs);
        };

        if (id) {
            getTemplate();
            getTemplateSubmissions(id);
            getTemplateRequirements(id);
        }
    }, [id]);

    return (
        <div className="pages-edit-template">
            <CommonLayout loading={loading} title="Editar Template">
                {!loading && (
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs value={tab} onChange={(ev, newValue) => setTab(newValue)}>
                                <Tab label="Detalle" />
                                <Tab label="Requerimientos" />
                                <Tab label="Recepciones" />
                            </Tabs>
                        </Box>
                        <TabPanel index={0} value={tab}>
                            {template && <TemplateForm template={template} />}
                        </TabPanel>
                        <TabPanel index={1} value={tab}>
                            <div>
                                {requirements && requirements.length > 0 ? (
                                    <RequirementsTable isDetailSection requirements={requirements} />
                                ) : (
                                    "No hay requerimientos"
                                )}
                            </div>
                        </TabPanel>
                        <TabPanel index={2} value={tab}>
                            <div>
                                {submissions && submissions.length > 0 ? (
                                    <SubmissionsTable isDetailSection submissions={submissions} />
                                ) : (
                                    "No hay recepciones"
                                )}
                            </div>
                        </TabPanel>
                    </Box>
                )}
            </CommonLayout>
        </div>
    );
};
