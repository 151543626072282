import React, { createContext, useReducer } from "react";

import { useSnackbar } from "notistack";

import api from "../../api";
import { SubmissionsResponse } from "../../interfaces/api/submissions";
import { SubmissionsReducer, SubmissionsState } from "./SubmissionsReducer";

type SubmissionsContextProps = {
    submissions?: SubmissionsResponse[];
    approvedSubmissions?: SubmissionsResponse[];
    rejectedSubmissions?: SubmissionsResponse[];
    pendingSubmissions?: SubmissionsResponse[];
    errorMessage?: string;
    loading: boolean;
    loadingApprove: boolean;
    loadingReject: boolean;
    getSubmissions: () => Promise<void>;
    getSubmissionById: (id: string) => Promise<SubmissionsResponse | undefined>;
    getSubmissionsByContact: (contactId: string) => Promise<SubmissionsResponse[] | undefined>;
    getSubmissionsByTemplate: (templateId: string) => Promise<SubmissionsResponse[] | undefined>;
    getSubmissionsByRequirement: (contactId: string) => Promise<SubmissionsResponse[] | undefined>;
    approveSubmission: (id: string, detail?: string) => Promise<void>;
    rejectSubmission: (id: string, detail?: string) => Promise<void>;
    getSubmissionsByFilter: (filter: "pending" | "approved" | "rejected") => Promise<void>;
    removeError: () => void;
    cleanSubmissions: () => void;
};

const submissionsInitialState: SubmissionsState = {
    loading: false,
    loadingApprove: false,
    loadingReject: false,
    errorMessage: undefined,
    submissions: undefined,
};

export const SubmissionsContext = createContext({} as SubmissionsContextProps);

export const SubmissionsProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(SubmissionsReducer, submissionsInitialState);
    const { enqueueSnackbar } = useSnackbar();

    const onSuccess = () => {
        dispatch({
            type: "removeError",
        });
        dispatch({
            type: "setLoading",
            payload: false,
        });
        dispatch({
            type: "setLoadingApprove",
            payload: false,
        });
        dispatch({
            type: "setLoadingReject",
            payload: false,
        });
    };

    const onSuccessWithMessage = (successMessage: string) => {
        onSuccess();
        enqueueSnackbar(successMessage, {
            variant: "success",
        });
    };

    const onError = (errorMsg?: string) => {
        dispatch({
            type: "addError",
            payload: errorMsg || "Hubo un error",
        });
        dispatch({
            type: "setLoading",
            payload: false,
        });
        enqueueSnackbar(errorMsg || "Hubo un error", {
            variant: "error",
        });
    };

    const getSubmissions = async () => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<SubmissionsResponse[]>("/submissions");
            const submissions = resp.data;

            dispatch({
                type: "setSubmissions",
                payload: submissions || undefined,
            });
            onSuccess();
        } catch (error: any) {
            onError(error.response?.data?.message);
            cleanSubmissions();
        }
    };

    const getSubmissionById = async (id: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<SubmissionsResponse>(`/submissions/${id}`);

            onSuccess();

            return data;
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const getSubmissionsByContact = async (contactId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<SubmissionsResponse[]>(`/submissions?contact->>id_contact=eq.${contactId}`);

            onSuccess();

            return data;
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const getSubmissionsByTemplate = async (templateId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<SubmissionsResponse[]>(
                `/submissions?template->>id_template=eq.${templateId}`,
            );

            onSuccess();

            return data;
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const getSubmissionsByRequirement = async (requirementId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<SubmissionsResponse[]>(`/submissions?id_requirement=eq.${requirementId}`);

            onSuccess();

            return data;
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const getSubmissionsByFilter = async (approval: "pending" | "approved" | "rejected") => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });

            const orderAndSelect =
                "&order=created_at.desc&select=id_submission,created_at,updated_at,approval,approval_at,approval_by,approval_detail,template,contact,id_requirement";

            switch (approval) {
                case "pending":
                    const { data: pending } = await api.get<SubmissionsResponse[]>(
                        `/submissions?approval=is.null${orderAndSelect}`,
                    );

                    dispatch({
                        type: "setPendingSubmissions",
                        payload: pending || undefined,
                    });
                    break;
                case "approved":
                    const { data: approved } = await api.get<SubmissionsResponse[]>(
                        `/submissions?approval=eq.true${orderAndSelect}`,
                    );

                    dispatch({
                        type: "setApprovedSubmissions",
                        payload: approved || undefined,
                    });
                    break;
                case "rejected":
                    const { data: rejected } = await api.get<SubmissionsResponse[]>(
                        `/submissions?approval=eq.false${orderAndSelect}`,
                    );

                    dispatch({
                        type: "setRejectedSubmissions",
                        payload: rejected || undefined,
                    });
                    break;
                default:
                    break;
            }
            onSuccess();
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const approveSubmission = async (id: string, detail?: string) => {
        try {
            dispatch({
                type: "setLoadingApprove",
                payload: true,
            });
            await api.post(`/submissions/${id}/approve`, { detail: detail });
            onSuccessWithMessage("La recepción ha sido aprobada correctamente");
            getSubmissions();
        } catch (error: any) {
            onError(error.response?.data?.message);
        }
    };

    const rejectSubmission = async (id: string, detail?: string) => {
        try {
            dispatch({
                type: "setLoadingReject",
                payload: true,
            });
            await api.post(`/submissions/${id}/reject`, { detail: detail });
            onSuccessWithMessage("La recepción ha sido rechazada correctamente");
            getSubmissions();
        } catch (error: any) {
            onError(error.response?.data?.message);
        }
    };

    const removeError = () => {
        dispatch({ type: "removeError" });
    };

    const cleanSubmissions = () => {
        dispatch({ type: "cleanSubmissions" });
    };

    return (
        <SubmissionsContext.Provider
            value={{
                ...state,
                getSubmissions,
                cleanSubmissions,
                removeError,
                getSubmissionById,
                getSubmissionsByContact,
                getSubmissionsByTemplate,
                getSubmissionsByRequirement,
                getSubmissionsByFilter,
                approveSubmission,
                rejectSubmission,
            }}
        >
            {children}
        </SubmissionsContext.Provider>
    );
};
