import React, { useContext, useEffect, useState } from "react";

import { CircularProgress, Icon, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import DialogContentText from "@mui/material/DialogContentText";
import { GridColDef, GridRenderCellParams, GridSortModel, GridValueGetterParams } from "@mui/x-data-grid";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router";

import { DialogContext } from "../../../context/dialog/DialogContext";
import { RequirementsContext } from "../../../context/requirements/RequirementsContext";
import { RequirementsResponse, RequirementStatus } from "../../../interfaces/api/requirements";
import { DialogContent } from "../../../ui/dialog/content";
import { Table } from "../../../ui/table";
import { getContactFullName, getRequirementStatusClassName, getRequirementStatusLabel } from "../../../utils";

import "./index.scss";

interface Action {
    type?: string;
    id?: string;
}

interface PropTypes extends RouteComponentProps<any> {
    requirements: RequirementsResponse[];
    isDetailSection?: boolean;
    loading?: boolean;
}

const RequirementsTable = (props: PropTypes) => {
    const { requirements, loading } = props;
    const {
        cancelRequirement,
        completeRequirement,
        pauseRequirement,
        resumeRequirement,
        restartRequirement,
        loadingAction,
    } = useContext(RequirementsContext);

    const { openDialog: openCustomDialog, closeDialog: closeCustomDialog } = useContext(DialogContext);

    const [openDialog, setOpenDialog] = useState(false);
    const [action, setAction] = useState<Action | undefined>(undefined);

    useEffect(() => {
        if (action?.type) {
            openCustomDialog({
                children: <DialogComponent />,
            });
        }
    }, [action]);

    const getActionIcons = (requirement: RequirementsResponse) => [
        {
            label: "pause",
            action: handlePauseRequirement(requirement),
            disabled: handleDisablePauseButton(requirement),
            type: "pause",
        },
        {
            label: "play_arrow",
            action: handleResumeRequirement(requirement),
            disabled: handleDisableResumeButton(requirement),
            type: "resume",
        },
        {
            label: "check",
            action: handleCompleteRequirement(requirement),
            disabled: handleDisableCompleteButton(requirement),
            type: "complete",
        },
        {
            label: "clear",
            action: handleCancelRequirement(requirement),
            disabled: handleDisableCancelButton(requirement),
            type: "cancel",
        },
        {
            label: "cached",
            action: handleRestartRequirement(requirement),
            disabled: handleDisableRestartButton(requirement),
            type: "restart",
        },
    ];

    const handleClickContact = (contactID: string) => (event: any) => {
        event.stopPropagation();
        props.history.push(`/contacts/${contactID}`);
    };
    const handleClickTemplate = (templateID: string) => (event: any) => {
        event.stopPropagation();
        props.history.push(`/templates/${templateID}`);
    };

    const columns: GridColDef[] = [
        {
            field: "created_at",
            headerName: "Fecha",
            width: 150,
            valueFormatter: (params: any) => {
                return moment(params.value?.toString()).format("DD/MM/YYYY");
            },
        },
        {
            field: "contact",
            headerName: "Contacto",
            width: 300,
            valueGetter: (params: any) => getContactFullName(params.value),
            renderCell: (params: GridRenderCellParams) => (
                <Button
                    sx={{ textTransform: "none", cursor: "pointer" }}
                    variant="text"
                    onClick={handleClickContact(params.row.contact.id_contact)}
                >
                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {getContactFullName(params.row.contact)}
                    </span>
                </Button>
            ),
        },
        {
            field: "template",
            headerName: "Template",
            width: 250,
            valueGetter: (params: any) => params.row.template.name,
            renderCell: (params: GridRenderCellParams) => (
                <Button
                    color="inherit"
                    sx={{ textTransform: "none", cursor: "pointer" }}
                    variant="text"
                    onClick={handleClickTemplate(params.row.template.id_template)}
                >
                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {params.row.template.name || ""}
                    </span>
                </Button>
            ),
        },
        {
            field: "reference",
            headerName: "Detalle",
            width: 250,
        },
        {
            field: "status",
            headerName: "Estado",
            width: 145,
            renderCell: (params: GridRenderCellParams) => (
                <Chip
                    className={getRequirementStatusClassName(params.row.status)}
                    label={getRequirementStatusLabel(params.row.status)}
                    variant={params.row.status === RequirementStatus.Expired ? "filled" : "outlined"}
                />
            ),
        },
        {
            field: "actions",
            headerName: "Acciones",
            sortable: false,
            filterable: false,
            width: props.isDetailSection ? 205 : 255,
            renderCell: (params: any) => {
                const actionIcons = getActionIcons(params.row);

                return (
                    <div className="actions-icons-wrapper">
                        {actionIcons.map((i, index) => (
                            <IconButton key={index} disabled={i.disabled} onClick={i.action}>
                                {loadingAction.value &&
                                loadingAction.type === i.type &&
                                loadingAction.reqId === params.row.id_requirement ? (
                                    <CircularProgress size={16} />
                                ) : (
                                    <Icon>{i.label}</Icon>
                                )}
                            </IconButton>
                        ))}
                    </div>
                );
            },
        },
    ];

    const DialogComponent = () => (
        <DialogContent
            buttons={
                <>
                    <Button onClick={handleConfirmDialog}>Sí</Button>
                    <Button autoFocus onClick={() => closeCustomDialog()}>
                        No
                    </Button>
                </>
            }
        >
            <DialogContentText id="alert-dialog-description">{getDialogText()}</DialogContentText>
        </DialogContent>
    );

    const handleCancelRequirement = (requirement: RequirementsResponse) => (event: any) => {
        event.stopPropagation();
        setAction({ type: "cancel", id: requirement.id_requirement });
        //setOpenDialog(true);
    };

    const handlePauseRequirement = (requirement: RequirementsResponse) => (event: any) => {
        event.stopPropagation();
        setAction({ type: "pause", id: requirement.id_requirement });
    };

    const handleCompleteRequirement = (requirement: RequirementsResponse) => (event: any) => {
        event.stopPropagation();
        setAction({ type: "complete", id: requirement.id_requirement });
    };

    const handleRestartRequirement = (requirement: RequirementsResponse) => (event: any) => {
        event.stopPropagation();
        const isForbidden = requirement.status === RequirementStatus.Review;

        if (!isForbidden) {
            setAction({ type: "restart", id: requirement.id_requirement });
        }
    };

    const handleResumeRequirement = (requirement: RequirementsResponse) => (event: any) => {
        event.stopPropagation();
        const isForbidden = requirement.status === RequirementStatus.Review;

        if (!isForbidden) {
            setAction({ type: "resume", id: requirement.id_requirement });
        }
    };

    const handleDisableResumeButton = (requirement: RequirementsResponse) => {
        if (requirement.status === RequirementStatus.Paused) {
            return false;
        } else return true;
    };

    const handleDisablePauseButton = (requirement: RequirementsResponse) => {
        if (requirement.status === RequirementStatus.Pending) {
            return false;
        } else return true;
    };

    const handleDisableCompleteButton = (requirement: RequirementsResponse) => {
        if (
            requirement.status ===
            (RequirementStatus.Completed || RequirementStatus.Cancelled || RequirementStatus.Expired)
        ) {
            return true;
        } else return false;
    };

    const handleDisableCancelButton = (requirement: RequirementsResponse) => {
        if (
            requirement.status ===
            (RequirementStatus.Cancelled || RequirementStatus.Review || RequirementStatus.Completed)
        ) {
            return true;
        } else return false;
    };

    const handleDisableRestartButton = (requirement: RequirementsResponse) => {
        if (requirement.status !== RequirementStatus.Expired) {
            return true;
        } else return false;
    };

    const getDialogText = () => {
        switch (action?.type) {
            case "cancel":
                return "¿Está seguro de que quiere cancelar este requerimiento?";
            case "pause":
                return "¿Está seguro de que quiere pausar este requerimiento?";
            case "resume":
                return "¿Está seguro de que quiere reanudar este requerimiento?";
            case "complete":
                return "¿Está seguro de que quiere completar este requerimiento?";
            case "restart":
                return "¿Está seguro de que quiere reiniciar este requerimiento?";
            default:
                return "";
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        if (!openDialog) {
            setAction(undefined);
        }
    };

    const handleConfirmDialog = () => {
        if (action && action.id) {
            switch (action.type) {
                case "cancel":
                    cancelRequirement(action.id);
                    break;
                case "pause":
                    pauseRequirement(action.id);
                    break;
                case "resume":
                    resumeRequirement(action.id);
                    break;
                case "complete":
                    completeRequirement(action.id);
                    break;
                case "restart":
                    restartRequirement(action.id);
                    break;
                default:
                    return;
            }
            handleCloseDialog();
            closeCustomDialog();
        }
    };

    const initialSortModel: GridSortModel = [
        {
            field: "created_at",
            sort: "desc",
        },
    ];

    return (
        <div className="requirements-table">
            <Table
                columns={columns}
                getRowClassName={() => "cursor-pointer"}
                getRowId={(row: any) => row["id_requirement"]}
                initialSortModel={initialSortModel}
                loading={loading}
                rows={requirements}
                onRowClick={(params: any, event: any) => {
                    event.stopPropagation();
                    props.history.push(`/requirements/${params.row.id_requirement}`);
                }}
            />
        </div>
    );
};

export default withRouter(RequirementsTable);
