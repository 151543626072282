import React, { useContext, useEffect } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Button, Icon, IconButton, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GridColDef, GridSortModel, GridValueGetterParams } from "@mui/x-data-grid";
import { RouteComponentProps, withRouter } from "react-router";

import { TemplatesContext } from "../../context/templates/TemplatesContext";
import CommonLayout from "../../layout/common";
import { Table } from "../../ui/table";
import { getTemplateTypeLabel } from "../../utils";

import "./index.scss";

type PropTypes = RouteComponentProps<any>;

const Templates = (props: PropTypes) => {
    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Nombre",
            width: 470,
        },
        {
            field: "type",
            headerName: "Tipo",
            width: 548,
            valueGetter: (params: GridValueGetterParams) => getTemplateTypeLabel(params.row.type),
        },
        {
            field: "actions",
            headerName: "Acciones",
            sortable: false,
            filterable: false,
            renderCell: (params: any) => (
                <IconButton
                    onClick={(ev: any) => {
                        ev.stopPropagation();
                        handleEditTemplate(params.row.id_template);
                    }}
                >
                    <Icon>edit</Icon>
                </IconButton>
            ),
        },
    ];

    const handleAddTemplate = () => {
        props.history.push("/templates/new");
    };

    const handleEditTemplate = (id: string) => {
        props.history.push(`/templates/${id}`);
    };

    const { templates, getTemplates, loading } = useContext(TemplatesContext);

    useEffect(() => {
        getTemplates();
    }, []);

    const initialSortModel: GridSortModel = [
        {
            field: "name",
            sort: "asc",
        },
    ];

    return (
        <div className="pages-templates">
            <CommonLayout loading={loading} title="Templates">
                <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "8px" }}>
                    <Button
                        startIcon={<AddIcon sx={{ fontWeight: 300 }} />}
                        sx={{ marginRight: "16px", alignItems: "center", padding: "2px 16px", height: 36.5 }}
                        variant="contained"
                        onClick={handleAddTemplate}
                    >
                        Agregar
                    </Button>
                </Box>
                {templates && (
                    <Table
                        columns={columns}
                        getRowClassName={() => "cursor-pointer"}
                        getRowId={(row: any) => row["id_template"]}
                        initialSortModel={initialSortModel}
                        rows={templates}
                        onRowClick={(params: any, event: React.MouseEvent) => {
                            event.stopPropagation();
                            props.history.push(`/templates/${params.row.id_template}`);
                        }}
                    />
                )}
            </CommonLayout>
        </div>
    );
};

export default withRouter(Templates);
