export interface TemplateResponse {
    id_template: string;
    id_organization: string;
    name: string;
    type: TemplateTypes;
    is_public: boolean;
    needs_review: boolean;
    instructions: null | string;
    description: null | string;
    expiration_days: number;
    frequency_days: number;
    config: any;
    created_by: CreatedBy;
    created_at: Date;
    updated_at: string;
    data_example: any;
}

export enum TemplateTypes {
    Form = "FORM",
    Signature = "SIGNATURE",
    FileUpload = "FILE_UPLOAD",
    FormSignature = "FORM_SIGNATURE",
}

export interface CreatedBy {
    id: string;
    email: string;
}
