import React, { FC } from "react";

import { Typography } from "@mui/material";

interface PropTypes {
    label: string;
    values: string[] | JSX.Element[];
    direction?: "row" | "column";
}

export const FormLabel = ({ label, values, direction = "row" }: PropTypes) => {
    return (
        <div className={`ui-form-label ${direction}`}>
            <Typography variant="subtitle2">{label}</Typography>
            {values.map((value) => value)}
        </div>
    );
};
