import React, { createContext, useReducer } from "react";

import { isArray } from "lodash";
import { useSnackbar } from "notistack";

import api from "../../api";
import { EntitiesResponse } from "../../interfaces/api/entities";
import { Entity } from "../../interfaces/entities";
import { EntitiesReducer, EntitiesState } from "./EntitiesReducer";

type EntitiesContextProps = {
    entities?: EntitiesResponse[];
    entity?: Entity;
    errorMessage?: string;
    loading: boolean;
    getEntities: () => Promise<void>;
    getEntitiesByFilter: (v: "active" | "inactive" | "expired") => Promise<void>;
    getEntityById: (id: string) => Promise<Entity | undefined>;
    getEntitiesByContact: (contactId: string) => Promise<EntitiesResponse[] | undefined>;
    getEntitiesByTemplate: (contactId: string) => Promise<EntitiesResponse[] | undefined>;
    removeError: () => void;
    cleanEntities: () => void;
};

const entitiesInitialState: EntitiesState = {
    loading: false,
    errorMessage: undefined,
    entities: undefined,
    entity: undefined,
};

export const EntitiesContext = createContext({} as EntitiesContextProps);

export const EntitiesProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(EntitiesReducer, entitiesInitialState);
    const { enqueueSnackbar } = useSnackbar();

    const onSuccess = (action: boolean) => {
        dispatch({
            type: "removeError",
        });
        if (action) {
            dispatch({
                type: "setLoadingAction",
                payload: { value: false, type: undefined, reqId: undefined },
            });
        } else {
            dispatch({
                type: "setLoading",
                payload: false,
            });
        }
    };

    const onSuccessWithMessage = (action: boolean, successMessage: string) => {
        onSuccess(action);
        enqueueSnackbar(successMessage, {
            variant: "success",
        });
    };

    const onError = (action: boolean, errorMsg?: string) => {
        dispatch({
            type: "addError",
            payload: errorMsg || "Hubo un error",
        });
        if (action) {
            dispatch({
                type: "setLoadingAction",
                payload: { value: false, type: undefined, reqId: undefined },
            });
        } else {
            dispatch({
                type: "setLoading",
                payload: false,
            });
        }
        enqueueSnackbar(errorMsg || "Hubo un error", {
            variant: "error",
        });
    };

    const getEntities = async () => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<EntitiesResponse[]>("/entities");
            const entities = resp.data;

            dispatch({
                type: "setEntities",
                payload: entities || undefined,
            });
            onSuccess(false);
        } catch (error: any) {
            onError(false, error.response?.data?.message);
            cleanEntities();
        }
    };

    const getEntityById = async (id: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<EntitiesResponse>(`/entities/${id}`);
            const entity = resp.data;
            const mappedEntity: Entity = {
                ...entity,
            };

            onSuccess(false);

            dispatch({
                type: "setEntity",
                payload: mappedEntity || undefined,
            });

            return mappedEntity;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const getEntitiesByContact = async (contactId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<EntitiesResponse[]>(`/entities?contact->>id_contact=eq.${contactId}`);

            onSuccess(false);

            return data;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const getEntitiesByFilter = async (status: "active" | "inactive" | "expired") => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });

            if (status === "active") {
                const { data } = await api.get<EntitiesResponse[]>("/entities");

                dispatch({
                    type: "setActiveEntities",
                    payload: data || undefined,
                });
            } else if (status === "inactive") {
                const { data } = await api.get<EntitiesResponse[]>("/entities");

                dispatch({
                    type: "setInactiveEntities",
                    payload: data || undefined,
                });
            } else {
                const { data } = await api.get<EntitiesResponse[]>("/entities");

                dispatch({
                    type: "setexpiredEntities",
                    payload: data || undefined,
                });
            }
            onSuccess(false);
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const getEntitiesByTemplate = async (templateId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<EntitiesResponse[]>(`/entities?template->>id_template=eq.${templateId}`);

            onSuccess(false);

            return data;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };
    const removeError = () => {
        dispatch({ type: "removeError" });
    };

    const cleanEntities = () => {
        dispatch({ type: "cleanEntities" });
    };

    return (
        <EntitiesContext.Provider
            value={{
                ...state,
                getEntities,
                getEntitiesByFilter,
                cleanEntities,
                removeError,
                getEntityById,
                getEntitiesByContact,
                getEntitiesByTemplate,
            }}
        >
            {children}
        </EntitiesContext.Provider>
    );
};
