import React, { useContext, useEffect } from "react";

import { Chip, Icon, IconButton } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridSortModel, GridValueGetterParams } from "@mui/x-data-grid";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router";

import { EmailsResponse, EmailStatus } from "../../../interfaces/api/emails";
import { Table } from "../../../ui/table";
import { getEmailStatusClassName, getEmailStatusLabel } from "../../../utils";

import "./index.scss";

interface PropTypes extends RouteComponentProps<any> {
    emails: EmailsResponse[];
    isDetailSection?: boolean;
}

const EmailsTable = (props: PropTypes) => {
    const { emails, isDetailSection } = props;

    const columns: GridColDef[] = [
        {
            field: "created_at",
            headerName: "Fecha",
            width: 200,
            valueFormatter: (params: any) => {
                return moment(params.value?.toString()).format("DD/MM/YYYY, hh:mm:ss");
            },
        },
        {
            field: "to",
            headerName: "Destinatario",
            width: isDetailSection ? 300 : 330,
        },
        {
            field: "subject",
            headerName: "Asunto",
            width: 470,
        },
        {
            field: "status",
            headerName: "Estado",
            width: 150,
            renderCell: (params: GridRenderCellParams) => (
                <Chip
                    className={getEmailStatusClassName(params.row.status)}
                    label={getEmailStatusLabel(params.row.status)}
                    variant="outlined"
                />
            ),
        },

        /*     {
      field: 'actions',
      headerName: 'Acciones',
      sortable: false,
      filterable: false,
      renderCell: (params: any) => (
        <IconButton onClick={(ev: any) => {
          ev.stopPropagation()
          handleViewEmail(params.row.id_email)
        }}>
          <Icon>visibility</Icon>
        </IconButton>
      )
    } */
    ];

    /*   const handleViewEmail = (id: string) => {
    props.history.push(`/emails/${id}`);
  } */

    const initialSortModel: GridSortModel = [
        {
            field: "created_at",
            sort: "desc",
        },
    ];

    return (
        <div className="emails-table">
            {emails && (
                <Table
                    columns={columns}
                    getRowId={(row: any) => row["id_email"]}
                    initialSortModel={initialSortModel}
                    rows={emails}
                />
            )}
        </div>
    );
};

export default withRouter(EmailsTable);
