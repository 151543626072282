import React, { useContext, useEffect } from "react";

import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

import { CeleriMarkdown } from "../../../../components/celeri/markdown";
import { NovedadesContext } from "../../../../context/novedades/NovedadesContext";
import CommonLayout from "../../../../layout/common";

import "./index.scss";

type NewParams = {
    id: string;
};

export const ViewNew = () => {
    const { id } = useParams<NewParams>();
    const { novedad, getNovedadById, loading } = useContext(NovedadesContext);

    useEffect(() => {
        getNovedadById(id);
    }, [id]);

    return (
        <div className="pages-view-news">
            <CommonLayout loading={loading} title={novedad?.title}>
                {!loading && novedad && (
                    <Box sx={{ width: "100%", padding: "24px", paddingTop: "0px" }}>
                        <CeleriMarkdown markdown={novedad.description} />
                    </Box>
                )}
            </CommonLayout>
        </div>
    );
};
