import _ from "lodash";
import moment from "moment";

import { DeadlineStatus } from "../interfaces/api/deadlines";
import { EmailStatus } from "../interfaces/api/emails";
import { RegulationStatus, RegulationType } from "../interfaces/api/regulations";
import { RequirementStatus } from "../interfaces/api/requirements";
import { SubmissionsResponse } from "../interfaces/api/submissions";
import { TemplateTypes } from "../interfaces/api/templates";
import { Contact } from "../interfaces/contacts";

export const difference = (newObject: any, baseObject: any) => {
    function changes(newObject: any, baseObject: any) {
        return _.transform(newObject, function (result: any, value: any, key: any) {
            if (!_.isEqual(value, baseObject[key])) {
                result[key] =
                    _.isObject(value) && _.isObject(baseObject[key]) ? changes(value, baseObject[key]) : value;
            }
        });
    }

    const rval = changes(newObject, baseObject);
    const lval = changes(baseObject, newObject);

    return _.assign(lval, rval);
};

export const isArray = function (a: any) {
    return !!a && a.constructor === Array;
};

export const getTemplateTypeLabel = (type: string): string => {
    switch (type) {
        case TemplateTypes.FileUpload:
            return "Carga de Archivos";
        case TemplateTypes.Form:
            return "Formulario";
        case TemplateTypes.Signature:
            return "Firma";
        case TemplateTypes.FormSignature:
            return "Formulario + Firma";
        default:
            return "Sin clasificar";
    }
};

export const getRequirementStatusLabel = (type: string): string => {
    switch (type) {
        case RequirementStatus.Completed:
            return "Completado";
        case RequirementStatus.Paused:
            return "Pausado";
        case RequirementStatus.Review:
            return "En revisión";
        case RequirementStatus.Cancelled:
            return "Cancelado";
        case RequirementStatus.Expired:
            return "Expirado";
        case RequirementStatus.Pending:
            return "Pendiente";
        default:
            return "Sin clasificar";
    }
};

export const getDeadlineStatusClassName = (type: string): string => {
    switch (type) {
        case DeadlineStatus.Created:
            return "draft";
        case DeadlineStatus.Draft:
            return "draft";
        case DeadlineStatus.Submitted:
            return "submitted";
        case DeadlineStatus.Deleted:
            return "deleted";
        case DeadlineStatus.Expired:
            return "expired";
        default:
            return "no-presentado";
    }
};

export const getDeadlineStatusLabel = (type: string): string => {
    switch (type) {
        case DeadlineStatus.Created:
            return "Borrador";
        case DeadlineStatus.Draft:
            return "Borrador";
        case DeadlineStatus.Submitted:
            return "Presentado";
        case DeadlineStatus.Deleted:
            return "Anulado";
        case DeadlineStatus.Expired:
            return "Vencido";
        default:
            return "Falta presentar";
    }
};

export const getRegulationStatusClassName = (type: string): string => {
    switch (type) {
        case RegulationStatus.Completed:
            return "completed";
        case RegulationStatus.Paused:
            return "paused";
        case RegulationStatus.Review:
            return "review";
        case RegulationStatus.Cancelled:
            return "cancelled";
        case RegulationStatus.Expired:
            return "expired";
        case RegulationStatus.Pending:
            return "pending";
        default:
            return "no-clasification";
    }
};

export const getRegulationStatusLabel = (type: string): string => {
    switch (type) {
        case RegulationStatus.Completed:
            return "Completado";
        case RegulationStatus.Paused:
            return "Pausado";
        case RegulationStatus.Review:
            return "En revisión";
        case RegulationStatus.Cancelled:
            return "Cancelado";
        case RegulationStatus.Expired:
            return "Expirado";
        case RegulationStatus.Pending:
            return "Pendiente";
        default:
            return "Sin clasificar";
    }
};

export const getRegulationTypeLabel = (type: string): string => {
    switch (type) {
        case RegulationType.Recurring:
            return "Recurrente";
        default:
            return "Sin clasificar";
    }
};

export const getRegulationTypeClassName = (type: string): string => {
    switch (type) {
        case RegulationType.Recurring:
            return "recurring";
        default:
            return "no-clasification";
    }
};

export const getRequirementStatusClassName = (type: string): string => {
    switch (type) {
        case RequirementStatus.Completed:
            return "completed";
        case RequirementStatus.Paused:
            return "paused";
        case RequirementStatus.Review:
            return "review";
        case RequirementStatus.Cancelled:
            return "cancelled";
        case RequirementStatus.Expired:
            return "expired";
        case RequirementStatus.Pending:
            return "pending";
        default:
            return "no-clasification";
    }
};

export const getEmailStatusLabel = (status: EmailStatus) => {
    switch (status) {
        case EmailStatus.Created:
            return "Creado";
        case EmailStatus.Sent:
            return "Enviado";
        case EmailStatus.Error:
            return "Con errores";
        default:
            return "Sin clasificar";
    }
};

export const getEmailStatusClassName = (status: EmailStatus) => {
    switch (status) {
        case EmailStatus.Created:
            return "created";
        case EmailStatus.Sent:
            return "sent";
        case EmailStatus.Error:
            return "error";
        default:
            return "no-clasification";
    }
};

export const getSubmissionStatusLabel = (approval: SubmissionsResponse["approval"]) => {
    switch (approval) {
        case true:
            return "Aprobada";
        case false:
            return "Rechazada";
        case null:
            return "Pendiente";
        default:
            return "Sin clasificar";
    }
};

export const getSubmissionStatusClassName = (approval: SubmissionsResponse["approval"]) => {
    switch (approval) {
        case true:
            return "approved";
        case false:
            return "rejected";
        case null:
            return "pending";
        default:
            return "no-clasification";
    }
};

export const getFormattedDate = (date: Date) => moment(date.toString()).format("DD/MM/YYYY");

export const getContactFullName = (contact: Contact) => {
    if (contact.middle_name) {
        return `${contact.first_name} ${contact.middle_name} ${contact.last_name}`;
    }

    return `${contact.first_name} ${contact.last_name}`;
};
