import React, { useContext } from "react";

import { KeyboardDoubleArrowRightSharp, Logout } from "@mui/icons-material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Cookies } from "react-cookie";

import useApp from "../../../hooks/useApp";
import history from "./../../../utils/history";

import "./index.scss";
import { UserContext } from "../../../context/user/UserContext";
import CustomDialog from "../../dialog";
import { DialogContext } from "../../../context/dialog/DialogContext";

export const GoToCumplimiento = () => {
    const { type } = useApp();
    const { openDialog, closeDialog } = useContext(DialogContext);
    const { organization } = useContext(UserContext);

    const Dialog = () => (
        <>
            <DialogTitle id="alert-dialog-description">¡Nueva App Cumplimiento Normativo!</DialogTitle>
            <DialogContent sx={{ color: "gray", fontWeight: "400" }}>
                Si queres saber más sobre la nueva <b style={{ fontWeight: 700 }}>App de Cumplimiento Normativo</b>{" "}
                podés agendar una <b style={{ fontWeight: 700, color: "#327bf5" }}>demo</b> haciendo click abajo!
            </DialogContent>
            <DialogActions>
                <Button
                    color="inherit"
                    size="small"
                    variant="text"
                    onClick={() => {
                        window.location.href = `mailto:andres.ryzy@celeri.app?subject=Quiero%20saber%20m%C3%A1s%20sobre%20Cumplimiento&body=Hola%20Andr%C3%A9s%21%0A%0AMe%20contacto%20desde%20${
                            organization ? organization.name : "%3Ccompletar%20empresa%3E"
                        }%20porque%20estoy%20interesado%20en%20una%20demo%20sobre%20la%20nueva%20secci%C3%B3n%20de%20Cumplimiento%20Normativo%20que%20celeri%20me%20ofrece.%0A%0AMuchas%20Gracias%21%0A%0A%0A%0A%5Bcontenido%20generado%20automaticamente%20a%20trav%C3%A9s%20de%20${
                            window.location.origin
                        }%5D`;
                        closeDialog();
                    }}
                >
                    enviar email
                </Button>
                <Button
                    autoFocus
                    color="primary"
                    variant="contained"
                    onClick={async () => {
                        window.open("https://meetings.hubspot.com/andres-ryzy", "_blank");
                        closeDialog();
                    }}
                >
                    AGENDAR DEMO
                </Button>
            </DialogActions>
        </>
    );

    return (
        <div className="ui-cumplimiento-button">
            <Button
                color="secondary"
                endIcon={<SwapHorizIcon />}
                variant="contained"
                onClick={(ev: any) => {
                    if (organization && organization.regulations_subscription) {
                        window.location.replace(type === "CUMPLIMIENTO" ? "/" : "/cumplimiento");
                    } else {
                        openDialog({
                            children: <Dialog />,
                        });
                    }
                }}
            >
                {type === "CUMPLIMIENTO" ? "Requerimientos" : "Cumplimiento"}
            </Button>
        </div>
    );
};
