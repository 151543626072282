import { TemplateResponse } from "../../interfaces/api/templates";

export interface TemplatesState {
    errorMessage?: string;
    loading: boolean;
    templates?: TemplateResponse[];
}

type TemplatesActions =
    | { type: "setTemplates"; payload: TemplateResponse[] | undefined }
    | { type: "addError"; payload: string }
    | { type: "setLoading"; payload: boolean }
    | { type: "cleanTemplates" }
    | { type: "removeError" };

export const TemplatesReducer = (state: TemplatesState, action: TemplatesActions): TemplatesState => {
    switch (action.type) {
        case "setLoading":
            return {
                ...state,
                loading: action.payload,
            };
        case "addError":
            return {
                ...state,
                errorMessage: action.payload,
            };
        case "removeError":
            return {
                ...state,
                errorMessage: undefined,
            };
        case "setTemplates":
            return {
                ...state,
                templates: action.payload,
            };
        case "cleanTemplates":
            return {
                ...state,
                templates: undefined,
            };
        default:
            return state;
    }
};
