import React, { useContext, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, DialogContentText, Typography } from "@mui/material";
import { Icon, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import "ace-builds";
import "ace-builds/src-noconflict/ext-error_marker";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/ext-spellcheck";
import "ace-builds/src-noconflict/keybinding-vscode";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/snippets/json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/worker-javascript";
import "ace-builds/src-noconflict/worker-json";
import "ace-builds/webpack-resolver";
import _ from "lodash";
import AceEditor from "react-ace";
import { Controller, useForm } from "react-hook-form";
import { RouteComponentProps, withRouter } from "react-router";
import * as yup from "yup";

import api from "../../../api";
import { DialogContext } from "../../../context/dialog/DialogContext";
import { SubmissionsContext } from "../../../context/submissions/SubmissionsContext";
import { SubmissionsResponse } from "../../../interfaces/api/submissions";
import { TemplateTypes } from "../../../interfaces/api/templates";
import { DialogContent } from "../../../ui/dialog/content";
import { FilePreview } from "../../../ui/file-preview";
import { FormLabel } from "../../../ui/form-label";

import "./index.scss";
import { UserContext } from "../../../context/user/UserContext";
import { useSnackbar } from "notistack";

interface PropTypes extends RouteComponentProps {
    submission?: SubmissionsResponse;
}

interface Data {
    id_submission?: string;
    detail?: string;
}
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    detail: yup.string(),
});

const SubmissionForm = (props: PropTypes) => {
    const { submission, history } = props;
    const { user, organization } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const { approveSubmission, rejectSubmission, loading, loadingApprove, loadingReject, errorMessage } = useContext(
        SubmissionsContext,
    );
    const isPendingApproval = submission?.approval === null || undefined;

    const [loadingRefresh, setLoadingRefresh] = useState(false);
    const { openDialog: openCustomDialog, closeDialog: closeCustomDialog } = useContext(DialogContext);
    const initialValues = {
        // used to initialize the data
        id_submission: submission?.id_submission,
        detail: undefined,
    };

    const { control, formState, watch, handleSubmit } = useForm({
        mode: "onChange",
        defaultValues: initialValues,
        resolver: yupResolver(schema),
    });
    const formData = watch();

    const ApproveDialog = () => (
        <DialogContent
            buttons={
                <>
                    <Button onClick={() => onSubmitApprove(formData)}>Sí</Button>
                    <Button autoFocus onClick={() => closeCustomDialog()}>
                        No
                    </Button>
                </>
            }
        >
            <DialogContentText id="alert-dialog-description">
                ¿Estás seguro que desea aceptar esta recepción?
            </DialogContentText>
        </DialogContent>
    );
    const RejectDialog = () => (
        <DialogContent
            buttons={
                <>
                    <Button onClick={() => onSubmitReject(formData)}>Si</Button>
                    <Button autoFocus onClick={() => closeCustomDialog()}>
                        No
                    </Button>
                </>
            }
        >
            <DialogContentText id="alert-dialog-description">
                ¿Estás seguro que desea rechazar esta recepción?
            </DialogContentText>
        </DialogContent>
    );
    const displayDialog = (type: "approve" | "reject") => (event: React.MouseEvent<HTMLElement>) => {
        if (type === "approve") {
            openCustomDialog({
                children: <ApproveDialog />,
            });
        }
        if (type === "reject") {
            openCustomDialog({
                children: <RejectDialog />,
            });
        }
    };

    const onSubmitReject = async (data: Data) => {
        if (data.id_submission) {
            window.analytics.track("tx_rejected_submissions", {
                id_user: user?.id,
                observations: data.detail,
                id_organization: organization?.id_organization,
            });
            closeCustomDialog();
            await rejectSubmission(data.id_submission, data.detail);
            if (!errorMessage) {
                history.push("/submissions");
            }
        }
    };

    const onSubmitApprove = async (data: Data) => {
        if (data.id_submission) {
            window.analytics.track("tx_approved_submissions", {
                id_user: user?.id,
                observations: data.detail,
                id_organization: organization?.id_organization,
            });
            closeCustomDialog();
            await approveSubmission(data.id_submission, data.detail);
            if (!errorMessage) {
                history.push("/submissions");
            }
        }
    };

    const getSubmissionState = (): string => {
        if (isPendingApproval) {
            return "Pendiente de aprobación";
        }
        if (submission?.approval) {
            return "Aprobada";
        }

        return "Rechazada";
    };

    const isSignature =
        submission?.template.type === TemplateTypes.Signature ||
        submission?.template.type === TemplateTypes.FormSignature;
    const isFileUpload = submission?.template.type === TemplateTypes.FileUpload;
    const isForm =
        submission?.template.type === TemplateTypes.Form || submission?.template.type === TemplateTypes.FormSignature;

    const downloadFile = (id_attachemnt: string) => {
        api.get(`/submissions/${submission?.id_submission}/attachments/${id_attachemnt}`).then((response) => {
            const link = document.createElement("a");

            link.href = response.data.url;
            link.setAttribute("target", "_blank");

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link?.parentNode?.removeChild(link);
        });
    };

    const handleClickContact = (id: string) => (event: any) => {
        event.stopPropagation();
        props.history.push(`/contacts/${id}`);
    };

    const handleClickTemplate = (id: string) => (event: any) => {
        event.stopPropagation();
        props.history.push(`/templates/${id}`);
    };

    const handleRefreshPdf = async (id: string) => {
        enqueueSnackbar("Regenerando pdf", {
            variant: "info",
        });
        try {
            const r = await api.post(`/submissions/${id}/regenerate-pdf`);

            enqueueSnackbar("Pdf regenerado", {
                variant: "success",
            });
        } catch (e: any) {
            enqueueSnackbar(e.response?.data?.message || "Hubo un error", {
                variant: "error",
            });
        }
    };

    const signedPdf = submission?.attachments.find((a) => a.metadata.mimetype === "application/pdf");

    return (
        <div className="submission-form">
            {submission && (
                <Box noValidate autoComplete="off" className="ui-form" component="form" id="form1">
                    <div className="content-wrapper">
                        <FormLabel
                            direction="row"
                            label="Contacto"
                            values={[
                                <div key={1} style={{ display: "flex" }}>
                                    <Typography
                                        style={{ width: "fit-content", marginRight: 10 }}
                                        variant="body1"
                                        onClick={handleClickContact(submission.contact.id_contact)}
                                    >
                                        {submission.contact.first_name} {submission.contact.middle_name || ""}{" "}
                                        {submission.contact.last_name}
                                    </Typography>
                                    <IconButton
                                        sx={{ p: 0, cursor: "pointer", zIndex: 5 }}
                                        onClick={handleClickContact(submission.contact.id_contact)}
                                    >
                                        <Icon style={{ fontSize: 20 }}>visibility</Icon>
                                    </IconButton>
                                </div>,
                            ]}
                        />
                        <FormLabel
                            direction="row"
                            label="Template"
                            values={[
                                <div key={2} style={{ display: "flex" }}>
                                    <Typography style={{ width: "fit-content", marginRight: 10 }} variant="body1">
                                        {submission.template.name}
                                    </Typography>
                                    <IconButton
                                        sx={{ p: 0, cursor: "pointer", zIndex: 5 }}
                                        onClick={handleClickTemplate(submission.template.id_template)}
                                    >
                                        <Icon style={{ fontSize: 20 }}>visibility</Icon>
                                    </IconButton>
                                </div>,
                            ]}
                        />
                        {isFileUpload && submission.attachments.length > 0 && (
                            <FormLabel
                                direction="row"
                                label="Adjuntos"
                                values={[
                                    <Box key={2} sx={{ display: "flex", flexWrap: "wrap" }}>
                                        {submission.attachments.map((a, index) => (
                                            <FilePreview key={index} file={a} onClick={() => downloadFile(a.id)} />
                                        ))}
                                    </Box>,
                                ]}
                            />
                        )}
                        {isSignature && signedPdf && (
                            <div className="form-label row">
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Typography sx={{ marginBottom: 0 }} variant="subtitle2">
                                        Documento firmado
                                    </Typography>
                                    <IconButton
                                        sx={{ marginRigth: "16px" }}
                                        onClick={async (ev) => {
                                            ev.stopPropagation();
                                            setLoadingRefresh(true);
                                            await handleRefreshPdf(submission.id_submission);
                                            setLoadingRefresh(false);
                                        }}
                                    >
                                        {loadingRefresh ? <CircularProgress size={16} /> : <Icon>refresh</Icon>}
                                    </IconButton>
                                </Box>
                                <FilePreview file={signedPdf} onClick={() => downloadFile(signedPdf.id)} />
                            </div>
                        )}
                        {isSignature && submission?.data && (
                            <div className="form-label row">
                                <Typography variant="subtitle2">Firma</Typography>
                                <img alt="signature" src={submission.data.signature} />
                            </div>
                        )}
                        {isForm && (
                            <div>
                                <Typography variant="subtitle2">Formulario</Typography>
                                <AceEditor
                                    readOnly
                                    fontSize={14}
                                    highlightActiveLine={true}
                                    keyboardHandler="vscode"
                                    mode="json"
                                    setOptions={{
                                        showLineNumbers: true,
                                        tabSize: 4,
                                        maxLines: 15,
                                        minLines: 4,
                                    }}
                                    theme="monokai"
                                    //onValidate={(annotations) => annotations.length ? setInvalidJson(true) : setInvalidJson(false)}
                                    value={
                                        typeof submission.data === "string"
                                            ? submission.data
                                            : JSON.stringify(submission.data || {}, null, 4)
                                    }
                                />
                            </div>
                        )}
                        <FormLabel direction="row" label="Estado" values={[getSubmissionState()]} />
                        {!isPendingApproval && submission?.approval_detail && (
                            <FormLabel direction="row" label="Observaciones" values={[submission.approval_detail]} />
                        )}
                        {!isPendingApproval && !!submission?.approval_by && (
                            <FormLabel
                                direction="row"
                                label={submission.approval ? "Aprobada por" : "Rechazada por"}
                                values={[submission.approval_by.email]}
                            />
                        )}
                        {isPendingApproval && (
                            <div className="form-content-wrapper">
                                <Controller
                                    control={control}
                                    name="detail"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            required
                                            label="Observaciones"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {isPendingApproval && (
                            <div className="buttons-wrapper">
                                <Button
                                    className="reject"
                                    disabled={loadingReject || loadingApprove}
                                    variant="outlined"
                                    onClick={displayDialog("reject")}
                                >
                                    {loadingReject ? <CircularProgress size={16} /> : "Rechazar"}
                                </Button>
                                <Button
                                    className="approve"
                                    disabled={loadingReject || loadingApprove}
                                    variant="outlined"
                                    onClick={displayDialog("approve")}
                                >
                                    {loadingApprove ? <CircularProgress size={16} /> : "Aprobar"}
                                </Button>
                            </div>
                        )}
                    </div>
                </Box>
            )}
        </div>
    );
};

export default withRouter(SubmissionForm);
