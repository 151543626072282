import React, { memo } from "react";
import "./index.scss";

const CeleriLoading = () => (
    <div id="fuse-splash-screen">
        <div className="center">
            <div className="logo">
                <img alt="logo" src="/logo-192.png" width="128" />
            </div>
            <div className="spinner-wrapper">
                <div className="spinner">
                    <div className="inner">
                        <div className="gap" />
                        <div className="left">
                            <div className="half-circle" />
                        </div>
                        <div className="right">
                            <div className="half-circle" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default CeleriLoading;
