import Button from "@mui/material/Button";
import { GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid";
import moment from "moment";
import React, { useContext, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { DialogContext } from "../../../context/dialog/DialogContext";
import { Condition } from "../../../interfaces/api/deadlines";
import { NovedadesResponse } from "../../../interfaces/api/novedades";
import { Entity } from "../../../interfaces/novedades";
import { Table } from "../../../ui/table";
import "./index.scss";

interface Action {
    type?: string;
    id?: string;
}

interface PropTypes extends RouteComponentProps<any> {
    novedades: NovedadesResponse[];
    isDetailSection?: boolean;
    loading?: boolean;
}

const NovedadesTable = (props: PropTypes) => {
    const { novedades, loading } = props;
    const { openDialog: openCustomDialog, closeDialog: closeCustomDialog } = useContext(DialogContext);

    const [openDialog, setOpenDialog] = useState(false);
    const [action, setAction] = useState<Action | undefined>(undefined);

    const getActionIcons = (novedad: NovedadesResponse) => [
        /* {
            label: "pause",
            action: handlePauseNovedad(novedad),
            disabled: handleDisablePauseButton(novedad),
            type: "pause",
        },
        {
            label: "play_arrow",
            action: handleResumeNovedad(novedad),
            disabled: handleDisableResumeButton(novedad),
            type: "resume",
        },
        {
            label: "check",
            action: handleCompleteNovedad(novedad),
            disabled: handleDisableCompleteButton(novedad),
            type: "complete",
        },
        {
            label: "clear",
            action: handleCancelNovedad(novedad),
            disabled: handleDisableCancelButton(novedad),
            type: "cancel",
        },
        {
            label: "cached",
            action: handleRestartNovedad(novedad),
            disabled: handleDisableRestartButton(novedad),
            type: "restart",
        }, */
    ];

    const columns: GridColDef[] = [
        {
            field: "date",
            headerName: "Fecha",
            width: 150,
            valueFormatter: (params: any) => {
                return moment(params.value?.toString()).format("DD/MM/YYYY");
            },
        },
        {
            field: "title",
            headerName: "Title",
            width: 450,
        },
        {
            field: "entities",
            headerName: "Entidades",
            width: 250,
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    {params.value.map((entity: Entity) => (
                        <Button
                            key={entity.id_entity}
                            color="inherit"
                            sx={{ textTransform: "none", cursor: "pointer" }}
                            variant="text"
                        >
                            <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                {entity.legal_name || ""}
                            </span>
                        </Button>
                    ))}
                </div>
            ),
        },
        {
            field: "condition",
            headerName: "Condicion",
            width: 250,
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    <Button
                        key={params.value.id_condition}
                        color="inherit"
                        sx={{ textTransform: "none", cursor: "pointer" }}
                        variant="text"
                    >
                        <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {params.value.name || ""}
                        </span>
                    </Button>
                </div>
            ),
        },
        {
            field: "actions",
            headerName: "Acciones",
            sortable: false,
            filterable: false,
            width: props.isDetailSection ? 205 : 255,
            renderCell: (params: any) => {
                const actionIcons = getActionIcons(params.row);

                return <div className="actions-icons-wrapper" />;
            },
        },
    ];

    const initialSortModel: GridSortModel = [
        {
            field: "created_at",
            sort: "desc",
        },
    ];

    return (
        <div className="novedades-table">
            <Table
                columns={columns}
                getRowClassName={() => "cursor-pointer"}
                getRowId={(row: any) => row["id_news"]}
                initialSortModel={initialSortModel}
                loading={loading}
                rows={novedades}
                onRowClick={(params: any, event: any) => {
                    event.stopPropagation();
                    props.history.push(`/cumplimiento/novedades/${params.row.id_news}`);
                }}
            />
        </div>
    );
};

export default withRouter(NovedadesTable);
