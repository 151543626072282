export interface DialogState {
    open: boolean;
    options: any;
}

type DialogActions = { type: "openDialog"; payload: any } | { type: "closeDialog" };

export const DialogReducer = (state: DialogState, action: DialogActions): DialogState => {
    switch (action.type) {
        case "openDialog":
            return {
                ...state,
                open: true,
                options: action.payload,
            };
        case "closeDialog":
            return {
                ...state,
                open: false,
            };
        default:
            return state;
    }
};
