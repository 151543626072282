import React from "react";

import ContactForm from "../../../components/contacts/form";
import CommonLayout from "../../../layout/common";

import "./index.scss";

export const AddContact = () => {
    return (
        <div className="pages-add-contact">
            <CommonLayout loading={false} title="Crear Contacto">
                <ContactForm />
            </CommonLayout>
        </div>
    );
};
