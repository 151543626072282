import * as React from "react";
import { useEffect } from "react";

import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import axios, { CancelToken, CancelTokenSource } from "axios";

import api from "../../api";

let call: CancelTokenSource;
let timerId: any;

function AsyncAutocomplete(props: any) {
    const { url, inputLabel, accessor, optionLabel, customSearch } = props;

    const [open, setOpen] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<any[]>([]);
    const [searchText, setSearchText] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    //const loading = open && options.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading && options.length !== 0) {
            return undefined;
        }

        if (!customSearch) {
            api.get(url)
                .then((response: any) => {
                    response ? setOptions([...response.data]) : setOptions([]);
                })
                .catch((e) => {
                    console.log(e);
                });
        }

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearchText("");
        }
    }, [open]);

    useEffect(() => {
        const getOptions = () => {
            api.get(url, {
                params: { q: searchText },
                cancelToken: call.token,
            })
                .then((response) => {
                    response ? setOptions([...response.data]) : setOptions([]);
                    setLoading(false);
                })
                .catch((e) => {
                    if (!axios.isCancel(e)) {
                        setLoading(false);
                    }
                });
        };

        // ENVIAR API
        clearTimeout(timerId);
        if (call) {
            setLoading(false);
            call.cancel();
        }
        call = axios.CancelToken.source();

        if (customSearch && searchText.length > 2) {
            setLoading(true);
            timerId = setTimeout(function () {
                getOptions();
                clearTimeout(timerId);
            }, 500);
        }
    }, [searchText]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            {...props}
            getOptionLabel={props.getOptionLabel ? props.getOptionLabel : (option: any) => option[accessor]}
            id="asynchronous-demo"
            isOptionEqualToValue={(option: any, value: any) => option[accessor] === value[accessor]}
            loading={loading}
            noOptionsText={
                customSearch
                    ? searchText.length < 3
                        ? "Escribí al menos 3 caracteres"
                        : "No se encuentran coincidencias"
                    : "No se encuentran coincidencias"
            }
            open={open}
            options={options}
            renderInput={(params: any) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    classes={{
                        root: "text-16",
                    }}
                    label={inputLabel}
                    placeholder={
                        customSearch ? "Escribí al menos 3 caracteres" : `Ingresar ${inputLabel.toLowerCase()}`
                    }
                    value={searchText}
                    variant="outlined"
                />
            )}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={(event, newInputValue) => {
                clearTimeout(timerId);
                setSearchText(newInputValue);
            }}
            onOpen={() => {
                setOpen(true);
            }}
        />
    );
}

export default AsyncAutocomplete;
