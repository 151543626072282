import { NovedadesResponse } from "../../interfaces/api/novedades";
import { Novedad } from "../../interfaces/novedades";

export interface NovedadesState {
    errorMessage?: string;
    loading: boolean;
    loadingAction: {
        value: boolean;
        type?: string;
    };
    novedades?: NovedadesResponse[];
    novedad?: Novedad;
    dayNovedades?: NovedadesResponse[];
    weekNovedades?: NovedadesResponse[];
    monthNovedades?: NovedadesResponse[];
    allNovedades?: NovedadesResponse[];
}

type NovedadesActions =
    | { type: "setNovedades"; payload: NovedadesResponse[] | undefined }
    | { type: "setNovedad"; payload: Novedad | undefined }
    | { type: "setDayNovedades"; payload: NovedadesResponse[] | undefined }
    | { type: "setMonthNovedades"; payload: NovedadesResponse[] | undefined }
    | { type: "setWeekNovedades"; payload: NovedadesResponse[] | undefined }
    | { type: "setAllNovedades"; payload: NovedadesResponse[] | undefined }
    | { type: "addError"; payload: string }
    | { type: "setLoading"; payload: boolean }
    | { type: "setLoadingAction"; payload: { value: boolean; type?: string; reqId?: string } }
    | { type: "cleanNovedades" }
    | { type: "removeError" };

export const NovedadesReducer = (state: NovedadesState, action: NovedadesActions): NovedadesState => {
    switch (action.type) {
        case "setLoading":
            return {
                ...state,
                loading: action.payload,
            };
        case "setLoadingAction":
            return {
                ...state,
                loadingAction: action.payload,
            };
        case "addError":
            return {
                ...state,
                errorMessage: action.payload,
            };
        case "removeError":
            return {
                ...state,
                errorMessage: undefined,
            };
        case "setNovedades":
            return {
                ...state,
                novedades: action.payload,
            };
        case "setNovedad":
            return {
                ...state,
                novedad: action.payload,
            };
        case "setDayNovedades":
            return {
                ...state,
                dayNovedades: action.payload,
            };
        case "setWeekNovedades":
            return {
                ...state,
                weekNovedades: action.payload,
            };
        case "setMonthNovedades":
            return {
                ...state,
                monthNovedades: action.payload,
            };
        case "setAllNovedades":
            return {
                ...state,
                allNovedades: action.payload,
            };
        case "cleanNovedades":
            return {
                ...state,
                novedades: undefined,
            };
        default:
            return state;
    }
};
