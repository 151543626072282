import React, { useContext } from "react";

import { Typography } from "@mui/material";
import { Icon, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import "ace-builds";
import "ace-builds/src-noconflict/ext-error_marker";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/ext-spellcheck";
import "ace-builds/src-noconflict/keybinding-vscode";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/snippets/json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/worker-javascript";
import "ace-builds/src-noconflict/worker-json";
import "ace-builds/webpack-resolver";
import _ from "lodash";
import AceEditor from "react-ace";
import { RouteComponentProps, withRouter } from "react-router";

import { RequirementsContext } from "../../../context/requirements/RequirementsContext";
import { Requirement } from "../../../interfaces/requirements";
import { FormLabel } from "../../../ui/form-label";
import { getContactFullName, getFormattedDate } from "../../../utils";

import "./index.scss";

interface PropTypes extends RouteComponentProps {
    requirement?: Requirement;
}

const RequirementDetail = (props: PropTypes) => {
    const { requirement, history } = props;
    const { loading, errorMessage } = useContext(RequirementsContext);

    const handleClickContact = (id: string) => (event: any) => {
        event.stopPropagation();
        props.history.push(`/contacts/${id}`);
    };

    const handleClickTemplate = (id: string) => (event: any) => {
        event.stopPropagation();
        props.history.push(`/templates/${id}`);
    };

    return (
        <div className="requirement-detail">
            {requirement && (
                <Box noValidate autoComplete="off" className="ui-form" component="form" id="form1">
                    <div className="content-wrapper">
                        <FormLabel
                            direction="row"
                            label="Contacto"
                            values={[
                                <div key={1} style={{ display: "flex" }}>
                                    <Typography
                                        style={{ width: "fit-content", marginRight: 10 }}
                                        variant="body1"
                                        onClick={handleClickContact(requirement.contact.id_contact)}
                                    >
                                        {getContactFullName(requirement.contact)}
                                    </Typography>
                                    <IconButton
                                        sx={{ p: 0, cursor: "pointer", zIndex: 5 }}
                                        onClick={handleClickContact(requirement.contact.id_contact)}
                                    >
                                        <Icon style={{ fontSize: 20 }}>visibility</Icon>
                                    </IconButton>
                                </div>,
                            ]}
                        />
                        <FormLabel direction="row" label="Detalle" values={[requirement.reference]} />
                        <FormLabel
                            direction="row"
                            label="Template"
                            values={[
                                <div key={1} style={{ display: "flex" }}>
                                    <Typography style={{ width: "fit-content", marginRight: 10 }} variant="body1">
                                        {requirement.template.name}
                                    </Typography>
                                    <IconButton
                                        sx={{ p: 0, cursor: "pointer", zIndex: 5 }}
                                        onClick={handleClickTemplate(requirement.template.id_template)}
                                    >
                                        <Icon style={{ fontSize: 20 }}>visibility</Icon>
                                    </IconButton>
                                </div>,
                            ]}
                        />
                        <FormLabel
                            direction="row"
                            label="Fecha de creación"
                            values={[getFormattedDate(requirement.created_at)]}
                        />
                        <FormLabel
                            direction="row"
                            label="Última actualización"
                            values={[getFormattedDate(requirement.updated_at)]}
                        />
                        <AceEditor
                            fontSize={14}
                            height="200"
                            highlightActiveLine={true}
                            keyboardHandler="vscode"
                            mode="json"
                            setOptions={{
                                readOnly: true,
                                enableLiveAutocompletion: true,
                                showLineNumbers: true,
                                spellcheck: true,
                                enableSnippets: true,
                                tabSize: 4,
                                useSoftTabs: true,
                                maxLines: 15,
                                minLines: 4,
                            }}
                            theme="monokai"
                            value={JSON.stringify(requirement.data, null, 4)}
                            //onValidate={(annotations) => annotations.length ? setInvalidJson(true) : setInvalidJson(false)}
                            width="100%"
                        />
                    </div>
                </Box>
            )}
        </div>
    );
};

export default withRouter(RequirementDetail);
