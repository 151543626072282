import { SubmissionsResponse } from "../../interfaces/api/submissions";

export interface SubmissionsState {
    errorMessage?: string;
    loading: boolean;
    loadingApprove: boolean;
    loadingReject: boolean;
    submissions?: SubmissionsResponse[];
    approvedSubmissions?: SubmissionsResponse[];
    rejectedSubmissions?: SubmissionsResponse[];
    pendingSubmissions?: SubmissionsResponse[];
}

type SubmissionsActions =
    | { type: "setSubmissions"; payload: SubmissionsResponse[] | undefined }
    | { type: "setPendingSubmissions"; payload: SubmissionsResponse[] | undefined }
    | { type: "setApprovedSubmissions"; payload: SubmissionsResponse[] | undefined }
    | { type: "setRejectedSubmissions"; payload: SubmissionsResponse[] | undefined }
    | { type: "addError"; payload: string }
    | { type: "setLoading"; payload: boolean }
    | { type: "setLoadingApprove"; payload: boolean }
    | { type: "setLoadingReject"; payload: boolean }
    | { type: "cleanSubmissions" }
    | { type: "removeError" };

export const SubmissionsReducer = (state: SubmissionsState, action: SubmissionsActions): SubmissionsState => {
    switch (action.type) {
        case "setLoading":
            return {
                ...state,
                loading: action.payload,
            };
        case "setLoadingApprove":
            return {
                ...state,
                loadingApprove: action.payload,
            };
        case "setLoadingReject":
            return {
                ...state,
                loadingReject: action.payload,
            };
        case "addError":
            return {
                ...state,
                errorMessage: action.payload,
            };
        case "removeError":
            return {
                ...state,
                errorMessage: undefined,
            };
        case "setSubmissions":
            return {
                ...state,
                submissions: action.payload,
            };
        case "setPendingSubmissions":
            return {
                ...state,
                pendingSubmissions: action.payload,
            };
        case "setApprovedSubmissions":
            return {
                ...state,
                approvedSubmissions: action.payload,
            };
        case "setRejectedSubmissions":
            return {
                ...state,
                rejectedSubmissions: action.payload,
            };
        case "cleanSubmissions":
            return {
                ...state,
                submissions: undefined,
            };
        default:
            return state;
    }
};
