import React, { useContext, useEffect, useState } from "react";

import { RouteComponentProps, withRouter } from "react-router";
import { useLocation } from "react-router-dom";

import { SubmissionsFilters } from "../../components/submissions/filters";
import SubmissionsTable from "../../components/submissions/table";
import { SubmissionsContext } from "../../context/submissions/SubmissionsContext";
import CommonLayout from "../../layout/common";

import "./index.scss";

type PropTypes = RouteComponentProps<any>;

const Submissions = (props: PropTypes) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filters = queryParams.get("filters");

    const getFilterInitialState = (): "pending" | "approved" | "rejected" => {
        if (filters === "pending" || filters === "approved" || filters === "rejected") {
            return filters;
        }

        return "pending";
    };

    const {
        submissions,
        approvedSubmissions,
        pendingSubmissions,
        rejectedSubmissions,
        getSubmissions,
        getSubmissionsByFilter,
        loading,
    } = useContext(SubmissionsContext);
    const [submissionsFilters, setSubmissionsFilters] = useState<"pending" | "approved" | "rejected" | undefined>(
        getFilterInitialState(),
    );

    useEffect(() => {
        if (submissionsFilters === undefined) {
            getSubmissions();
        } else {
            getSubmissionsByFilter(submissionsFilters);
        }
    }, []);

    const submissionsByFilter = {
        pending: pendingSubmissions,
        approved: approvedSubmissions,
        rejected: rejectedSubmissions,
    };

    const handleChangeFilters = (value?: "pending" | "approved" | "rejected") => {
        if (value === undefined) {
            getSubmissions();
            props.history.push("/submissions");
        } else {
            getSubmissionsByFilter(value);
            props.history.push(`/submissions?filters=${value}`);
        }
        setSubmissionsFilters(value);
    };

    const isLoading = () => {
        if (loading || (!submissions && submissionsFilters && !submissionsByFilter[submissionsFilters])) {
            return true;
        }

        return false;
    };

    const getRefreshFunction = () => {
        if (submissionsFilters === undefined) {
            return getSubmissions();
        }

        return getSubmissionsByFilter(submissionsFilters);
    };

    return (
        <div className="pages-submissions">
            <CommonLayout loading={loading} refreshFunction={getRefreshFunction} title="Recepciones">
                <SubmissionsFilters value={submissionsFilters} onClick={handleChangeFilters} />
                <SubmissionsTable
                    loading={isLoading()}
                    submissions={submissionsFilters ? submissionsByFilter[submissionsFilters] || [] : submissions || []}
                />
            </CommonLayout>
        </div>
    );
};

export default withRouter(Submissions);
