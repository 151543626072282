import React from "react";
import { useContext } from "react";

import { Dialog } from "@mui/material";

import { DialogContext } from "../../context/dialog/DialogContext";

const CustomDialog = () => {
    const { open, options, closeDialog } = useContext(DialogContext);

    return <Dialog open={open} onClose={(ev: any) => closeDialog()} {...options} />;
};

export default CustomDialog;
