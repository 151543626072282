import React, { useContext, useEffect, useState } from "react";

import { ContentPaste, PeopleAltOutlined, SystemUpdateAlt } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { GridColDef } from "@mui/x-data-grid";
import { RouteComponentProps, withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";

import ImportPreviewDialog from "../../../components/import-preview";
import { RegulationsFilters } from "../../../components/regulations/filters";
import RegulationsTable from "../../../components/regulations/table";
import { RegulationsContext } from "../../../context/regulations/RegulationsContext";
import { RegulationImportExcel, RegulationsFilterType } from "../../../interfaces/regulations";
import CommonLayout from "../../../layout/common";

import "./index.scss";

type PropTypes = RouteComponentProps<any>;

interface Action {
    type?: string;
    id?: string;
}

const Regulations = (props: PropTypes) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filters = queryParams.get("filters");

    const getFilterInitialState = (): RegulationsFilterType => {
        if (
            filters === RegulationsFilterType.completed ||
            filters === RegulationsFilterType.pending ||
            filters === RegulationsFilterType.all
        ) {
            return filters;
        }

        return RegulationsFilterType.all;
    };

    const {
        createRegulation,
        getRegulations,
        getRegulationsByFilter,
        loading,
        errorMessage,
        regulations,
        completedRegulations,
        pendingRegulations,
        allRegulations,
    } = useContext(RegulationsContext);
    const [regulationsFilters, setRegulationsFilters] = useState<RegulationsFilterType | undefined>(
        getFilterInitialState(),
    );

    const handleAddRegulation = () => {
        props.history.push("/regulations/new");
    };

    useEffect(() => {
        if (regulationsFilters === undefined) {
            getRegulations();
        } else {
            getRegulationsByFilter(regulationsFilters);
        }
    }, []);

    const handleChangeFilters = (value?: RegulationsFilterType) => {
        if (value === undefined) {
            getRegulations();
            props.history.push("/cumplimiento/regulations");
        } else {
            getRegulationsByFilter(value);
            props.history.push(`/cumplimiento/regulations?filters=${value}`);
        }
        setRegulationsFilters(value);
    };

    const regulationsByFilter = {
        completed: completedRegulations,
        pending: pendingRegulations,
        all: allRegulations,
    };

    const isLoading = () => {
        if (loading || (!regulations && regulationsFilters && !regulationsByFilter[regulationsFilters])) {
            return true;
        }

        return false;
    };

    const getRefreshFunction = () => {
        if (regulationsFilters === undefined) {
            return getRegulations();
        }

        return getRegulationsByFilter(regulationsFilters);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="pages-regulations">
            <CommonLayout loading={loading} refreshFunction={getRefreshFunction} title="Obligaciones">
                <div className="options-regulations">
                    <RegulationsFilters value={regulationsFilters} onClick={handleChangeFilters} />
                </div>
                <RegulationsTable
                    loading={isLoading()}
                    regulations={regulationsFilters ? regulationsByFilter[regulationsFilters] || [] : regulations || []}
                />
            </CommonLayout>
        </div>
    );
};

export default withRouter(Regulations);
