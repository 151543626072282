import React from "react";

import { Button, Icon, IconButton, InputBase, OutlinedInput, TextField } from "@mui/material";

import "./index.scss";

interface PropTypes {
    onChange: (s: string) => void;
    onClickSearch: (s: string) => void;
    value?: string;
}

export const SearchInput = (props: PropTypes) => {
    const { value, onChange, onClickSearch } = props;

    const handleChange = (e: any) => {
        e.preventDefault();
        onChange(e.target.value);
    };

    const handleClickSearch = (e: any) => {
        e.stopPropagation();
        onClickSearch(e.target.value);
    };

    return (
        <div className="ui-search-input">
            <OutlinedInput
                aria-describedby="outlined-weight-helper-text"
                autoComplete="off"
                endAdornment={<Icon>search</Icon>}
                id="outlined-adornment-weight"
                placeholder={"Search"}
                value={value}
                onChange={handleChange}
            />
        </div>
    );
};
