import React, { useContext, useEffect } from "react";

import RequerimientoForm from "../../../components/requirements/form";
import { UserContext } from "../../../context/user/UserContext";
import CommonLayout from "../../../layout/common";

import "./index.scss";

export const AddRequirement = () => {
    const { user, organization } = useContext(UserContext);

    useEffect(() => {
        window.analytics.track("view_create_requirements", {
            id_user: user?.id,
            id_organization: organization?.id_organization,
        });
    }, []);

    return (
        <div className="pages-add-requirement">
            <CommonLayout loading={false} title="Crear Requerimiento">
                <RequerimientoForm />
            </CommonLayout>
        </div>
    );
};
