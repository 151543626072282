import React, { useContext } from "react";

import { Icon, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { default as Content } from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { DialogContext } from "../../../context/dialog/DialogContext";

import "./index.scss";

interface PropTypes {
    buttons: JSX.Element;
    children?:
        | React.ReactChild
        | React.ReactChild[]
        | React.ReactNode
        | React.ReactNode[]
        | JSX.Element
        | JSX.Element[];
    displayCloseButton?: boolean;
    displayCloseIcon?: boolean;
    onCloseDialog?: () => void;
}

export const DialogContent = ({
    buttons,
    children,
    displayCloseButton = false,
    displayCloseIcon = true,
    onCloseDialog,
}: PropTypes) => {
    const { closeDialog: closeCustomDialog } = useContext(DialogContext);

    const handleCloseDialog = () => {
        closeCustomDialog();
        if (onCloseDialog) {
            onCloseDialog();
        }
    };

    return (
        <>
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    display: "flex",
                    justifyContent: "right",
                    width: 500,
                    paddingBottom: "0px",
                    paddingTop: "10px",
                    paddingRight: "10px",
                }}
            >
                {displayCloseIcon && (
                    <IconButton
                        aria-label="close"
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                        onClick={handleCloseDialog}
                    >
                        <Icon>clear</Icon>
                    </IconButton>
                )}
            </DialogTitle>
            <Content>
                <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
            </Content>
            {(displayCloseButton || buttons) && (
                <DialogActions className={`${displayCloseButton ? "with-close" : ""}`}>
                    {displayCloseButton && (
                        <Button autoFocus className="close-button" onClick={handleCloseDialog}>
                            Cerrar
                        </Button>
                    )}
                    {buttons && <div>{buttons}</div>}
                </DialogActions>
            )}
        </>
    );
};
