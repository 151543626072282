import React, { useContext, useState } from "react";

import {
    Button,
    ButtonGroup,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

import "./index.scss";
import { DeadlinesContext } from "../../../context/deadlines/DeadlinesContext";
import { useSnackbar } from "notistack";
import { DialogContext } from "../../../context/dialog/DialogContext";
import { Deadline } from "../../../interfaces/deadlines";

interface PropTypes {
    body: string;
    attachments: File[];
}

export const DeadlinesFooters = (props: PropTypes) => {
    const { deadline, viewVersion, createFiling, updateVersion, createVersion, loading, errorMessage } = useContext(
        DeadlinesContext,
    );
    const { openDialog: openCustomDialog, closeDialog: closeCustomDialog } = useContext(DialogContext);
    const { attachments, body } = props;
    const { enqueueSnackbar } = useSnackbar();

    const handleClickSaveBorrador = async () => {
        if (deadline) {
            if (!deadline?.filings) {
                await createFiling(deadline?.id_deadline, body, attachments, "DRAFT");
            } else if (viewVersion && viewVersion.id_version) {
                await updateVersion(deadline?.id_deadline, viewVersion?.id_version, "DRAFT", body);
            } else {
                await createVersion(deadline?.id_deadline, body, attachments, "DRAFT");
            }
            closeCustomDialog();
        }
    };
    const handleClickAnular = () => {
        if (!loading && !errorMessage) {
            closeCustomDialog();
        }
    };
    const handleClickPresentar = async () => {
        if (deadline) {
            if (viewVersion?.id_version) {
                await updateVersion(deadline?.id_deadline, viewVersion?.id_version, "SUBMITTED");
            } else if (!deadline?.filings) {
                await createFiling(deadline?.id_deadline, body, attachments, "SUBMITTED");
            } else if (viewVersion && viewVersion.id_version) {
                await updateVersion(deadline?.id_deadline, viewVersion?.id_version, "SUBMITTED", body);
            } else {
                await createVersion(deadline?.id_deadline, body, attachments, "SUBMITTED");
            }
            closeCustomDialog();
        }
    };

    const handleConfirm = (action: string) => (ev: any) => {
        openCustomDialog({
            children: <DialogComponent action={action} loading={loading} />,
        });
    };

    const getDialogText = (action: string) => {
        switch (action) {
            case "DRAFT":
                return "¿Guardar borrador?";
            case "REJECTED":
                return "Anular presentación?";
            case "SUBMITTED":
                return "¿Presentar vencimiento?";
            case "DELETED":
                return "¿Anular Presentación?";
            default:
                return "";
        }
    };
    const handleConfirmAction = async (action: string) => {
        switch (action) {
            case "DRAFT":
                return await handleClickSaveBorrador();
            case "REJECTED":
                return await handleClickAnular();
            case "SUBMITTED":
                return await handleClickPresentar();
            case "DELETED":
                return await handleClickAnular();
            default:
                return await console.log("action not found");
        }
    };

    interface DialogPropTypes {
        action: string;
        loading: boolean;
    }

    const DialogComponent = (props: DialogPropTypes) => {
        const [loading, setLoading] = useState(false);

        return (
            <DialogContent sx={{ paddingBottom: "8px" }}>
                <DialogTitle id="alert-dialog-description">{getDialogText(props.action)}</DialogTitle>
                <DialogActions>
                    {loading && <CircularProgress size={18} />}
                    <Button autoFocus disabled={loading} onClick={() => closeCustomDialog()}>
                        No
                    </Button>
                    <Button
                        color="primary"
                        disabled={loading}
                        variant="outlined"
                        onClick={async () => {
                            setLoading(true);
                            await handleConfirmAction(props.action);
                            setLoading(false);
                        }}
                    >
                        Sí
                    </Button>
                </DialogActions>
            </DialogContent>
        );
    };

    return (
        <div className="deadlines-footer-container">
            {loading && <CircularProgress size={18} />}
            <div className="borrador-button">
                <Button
                    disabled={loading || (viewVersion && viewVersion.status !== "DRAFT")}
                    onClick={handleConfirm("DRAFT")}
                >
                    Guardar borrador
                </Button>
            </div>
            <div className="action-buttons">
                {loading && <CircularProgress size={18} />}
                <Button className="anular" disabled={loading} variant="contained" onClick={handleConfirm("REJECTED")}>
                    Anular
                </Button>
                {(!viewVersion || viewVersion.status !== "SUBMITTED") && (
                    <Button
                        className="presentar"
                        disabled={loading}
                        variant="contained"
                        onClick={handleConfirm("SUBMITTED")}
                    >
                        Presentar
                    </Button>
                )}
            </div>
        </div>
    );
};
