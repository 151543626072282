import React, { useState } from "react";

import { Button, Card, CircularProgress, Icon, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import validator from "validator";

import { supabase } from "../../utils/supabase";

import "./index.scss";

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

    const access_token: string | undefined = window.location.hash
        .split("&")
        .find((a) => a.includes("token"))
        ?.split("=")[1];
    const type: string | undefined = window.location.hash
        .split("&")
        .find((a) => a.includes("type"))
        ?.split("=")[1];

    if (access_token && type) {
        window.localStorage.setItem("access_token", access_token);
        window.localStorage.setItem("type", type);
    }

    const handleSendLink = async (email: string) => {
        setLoading(true);
        await supabase.auth.api.resetPasswordForEmail(email, {
            redirectTo: window.location.href,
        });
        setEmailSent(true);
        setLoading(false);
    };

    const handleChangePassword = async (password: string) => {
        setLoading(true);
        const access_token = window.localStorage.getItem("access_token");

        if (access_token) {
            const { error } = await supabase.auth.api.updateUser(access_token, { password });

            if (error) window.alert(error.message);
            else {
                setResetPasswordSuccess(true);
                window.localStorage.setItem("access_token", "");
                window.localStorage.setItem("type", "");
            }
        }
        setLoading(false);
    };

    if (resetPasswordSuccess) {
        return (
            <div className="forgot-password-root">
                <Card className="forgot-password-box">
                    <div className="logo">
                        <img
                            alt="logo"
                            src="https://celeri-public.nyc3.cdn.digitaloceanspaces.com/celeri-logo-black.png"
                        />
                    </div>
                    <h3>Nueva contraseña creada</h3>
                    <Icon
                        sx={{
                            color: "green",
                        }}
                    >
                        check
                    </Icon>
                    <div className="login-button">
                        <Button variant="contained" onClick={() => window.location.replace("/")}>
                            Volver a inicio
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }

    if (!window.localStorage.getItem("access_token") && window.localStorage.getItem("type") !== "recovery") {
        return (
            <div className="forgot-password-root">
                <Card className="forgot-password-box">
                    <div className="logo">
                        <img
                            alt="logo"
                            src="https://celeri-public.nyc3.cdn.digitaloceanspaces.com/celeri-logo-black.png"
                        />
                    </div>
                    <h3>Reiniciar contraseña</h3>

                    {emailSent && (
                        <Typography
                            sx={{
                                color: "grey",
                                paddingLeft: "32px",
                                paddingY: "16px",
                            }}
                        >
                            Revisa tu casilla desde <b>{email}</b> y reinicia la contraseña (no olvides revisar spam).
                        </Typography>
                    )}
                    {!emailSent && (
                        <>
                            <div className="text-field">
                                <TextField
                                    fullWidth
                                    autoCapitalize={"none"}
                                    error={!!email && !validator.isEmail(email)}
                                    helperText={!!email && !validator.isEmail(email) ? "Ingresar un email válido" : ""}
                                    label="Email"
                                    sx={{
                                        marginX: "16px",
                                        width: "100%",
                                    }}
                                    type="email"
                                    value={email}
                                    onChange={(ev: any) => setEmail(ev.target.value)}
                                    onKeyPress={(ev: any) => ev.key === "Enter" && email && handleSendLink(email)}
                                />
                            </div>
                            <div className="login-button">
                                <Button
                                    disabled={loading || !email || !(!!email && validator.isEmail(email))}
                                    variant="contained"
                                    onClick={() => handleSendLink(email)}
                                >
                                    Enviar Email
                                </Button>
                                {loading && <CircularProgress size={16} />}
                            </div>
                        </>
                    )}
                    <Link
                        style={{
                            paddingBottom: "16px",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: "14px",
                        }}
                        to="/login"
                    >
                        Volver a ingresar
                    </Link>
                </Card>
            </div>
        );
    }

    return (
        <div className="forgot-password-root">
            <Card className="forgot-password-box">
                <div className="logo">
                    <img alt="logo" src="https://celeri-public.nyc3.cdn.digitaloceanspaces.com/celeri-logo-black.png" />
                </div>
                <h3>Reiniciar contraseña</h3>
                <div className="text-field">
                    <TextField
                        fullWidth
                        autoCapitalize={"none"}
                        label="Contraseña"
                        sx={{
                            marginX: "16px",
                            width: "100%",
                        }}
                        type="password"
                        value={password}
                        onChange={(ev: any) => setPassword(ev.target.value)}
                    />
                </div>
                <div className="text-field">
                    <TextField
                        fullWidth
                        autoCapitalize={"none"}
                        label="Confirmar contraseña"
                        placeholder="Confirmar contraseña"
                        sx={{
                            marginX: "16px",
                            width: "100%",
                        }}
                        type="password"
                        value={confirmPassword}
                        onChange={(ev: any) => setConfirmPassword(ev.target.value)}
                    />
                </div>
                <div className="login-button">
                    <Button
                        disabled={loading || !password || password !== confirmPassword}
                        variant="contained"
                        onClick={() => handleChangePassword(password)}
                    >
                        Reiniciar contraseña
                    </Button>
                    {loading && <CircularProgress size={16} />}
                </div>
            </Card>
        </div>
    );
};

export default ForgotPassword;
