import React from "react";

import { DialogContentText } from "@mui/material";
import Button from "@mui/material/Button";

import { Template } from "../../../interfaces/templates";
import { DialogContent } from "../../../ui/dialog/content";
import { FormLabel } from "../../../ui/form-label";
import { getTemplateTypeLabel } from "../../../utils";

import "./index.scss";

interface PropTypes {
    template: Template;
    onClick: (id: string) => void;
}
export const TemplateDialog = ({ template, onClick }: PropTypes) => {
    const handleClickTemplateDetail = () => {
        onClick(template.id_template);
    };

    return (
        <DialogContent displayCloseButton buttons={<Button onClick={handleClickTemplateDetail}>Ver detalle</Button>}>
            <DialogContentText id="alert-dialog-description">
                <FormLabel label="Nombre" values={[template.name]} />
                <FormLabel label="Tipo" values={[getTemplateTypeLabel(template.type)]} />
                {template.frequency_days && (
                    <FormLabel label="Frecuencia" values={[`${template.frequency_days} días`]} />
                )}
                {template.instructions && <FormLabel label="Instrucciones" values={[template.instructions]} />}
                {template.expiration_days && (
                    <FormLabel label="Deadline" values={[`${template.expiration_days} días`]} />
                )}
            </DialogContentText>
        </DialogContent>
    );
};
