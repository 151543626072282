import React, { useContext, useEffect } from "react";

import { Button, Icon, IconButton } from "@mui/material";
import Chip from "@mui/material/Chip";
import { GridColDef, GridRenderCellParams, GridSortModel, GridValueGetterParams } from "@mui/x-data-grid";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router";

import { SubmissionsContext } from "../../../context/submissions/SubmissionsContext";
import { SubmissionsResponse } from "../../../interfaces/api/submissions";
import { Table } from "../../../ui/table";
import { getContactFullName, getSubmissionStatusClassName, getSubmissionStatusLabel } from "../../../utils";

import "./index.scss";
import { UserContext } from "../../../context/user/UserContext";

interface PropTypes extends RouteComponentProps<any> {
    submissions: SubmissionsResponse[];
    isDetailSection?: boolean;
    loading?: boolean;
}

const SubmissionsTable = (props: PropTypes) => {
    const isPendingApproval = (approval: SubmissionsResponse["approval"]) => approval === null || undefined;
    const { user, organization } = useContext(UserContext);

    const getSubmissionState = (approval: SubmissionsResponse["approval"]): string => {
        if (isPendingApproval(approval)) {
            return "Pendiente de aprobación";
        }
        if (approval) {
            return "Aprobada";
        }

        return "Rechazada";
    };

    const handleClickContact = (contactID: string) => (event: React.MouseEvent) => {
        event.stopPropagation();
        props.history.push(`/contacts/${contactID}`);
    };

    const handleClickTemplate = (templateID: string) => (event: React.MouseEvent) => {
        event.stopPropagation();
        props.history.push(`/templates/${templateID}`);
    };

    const columns: GridColDef[] = [
        {
            field: "created_at",
            headerName: "Fecha",
            width: 150,
            valueFormatter: (params: any) => {
                return moment(params.value?.toString()).format("DD/MM/YYYY");
            },
        },
        {
            field: "contact",
            headerName: "Contacto",
            width: 300,
            valueGetter: (params: any) => getContactFullName(params.value),
            renderCell: (params: GridRenderCellParams) => (
                <Button
                    sx={{ textTransform: "none", cursor: "pointer" }}
                    variant="text"
                    onClick={handleClickContact(params.row.contact.id_contact)}
                >
                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {getContactFullName(params.row.contact)}
                    </span>
                </Button>
            ),
        },
        {
            field: "template",
            headerName: "Template",
            width: 350,
            valueGetter: (params: any) => params.row.template.name,
            renderCell: (params: GridRenderCellParams) => (
                <Button
                    color="inherit"
                    sx={{ textTransform: "none", cursor: "pointer" }}
                    variant="text"
                    onClick={handleClickTemplate(params.row.template.id_template)}
                >
                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {params.row.template.name || ""}
                    </span>
                </Button>
            ),
        },
        {
            field: "status",
            headerName: "Estado",
            width: props.isDetailSection ? 153 : 200,
            valueGetter: (params: any) => getSubmissionStatusLabel(params.row.approval),
            renderCell: (params: GridRenderCellParams) => (
                <Chip
                    className={getSubmissionStatusClassName(params.row.approval)}
                    label={getSubmissionStatusLabel(params.row.approval)}
                    variant="outlined"
                />
            ),
        },
        {
            field: "actions",
            headerName: "Acciones",
            sortable: false,
            filterable: false,
            renderCell: (params: any) => (
                <IconButton
                    onClick={(ev: any) => {
                        ev.stopPropagation();
                        window.analytics.track("click_actions_button_submissions", {
                            id_user: user?.id,
                            id_submission: params.row.id_submission,
                            id_organization: organization?.id_organization,
                        });
                        handleViewSubmission(params.row.id_submission);
                    }}
                >
                    <Icon>visibility</Icon>
                </IconButton>
            ),
        },
    ];

    const handleViewSubmission = (id: string) => {
        props.history.push(`/submissions/${id}`);
    };

    const initialSortModel: GridSortModel = [
        {
            field: "created_at",
            sort: "desc",
        },
    ];

    return (
        <div className="submissions-table">
            <Table
                columns={columns}
                getRowId={(row: any) => row["id_submission"]}
                initialSortModel={initialSortModel}
                loading={props.loading}
                rows={props.submissions}
                onRowClick={(params: any, ev: any) => {
                    ev?.stopPropagation();
                    window.analytics.track("click_row_submissions", {
                        id_user: user?.id,
                        id_submissions: params.row.id_submission,
                        id_organization: organization?.id_organization,
                    });
                    handleViewSubmission(params.row.id_submission);
                }}
            />
        </div>
    );
};

export default withRouter(SubmissionsTable);
