import React, { useContext, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { AppRegistration } from "@mui/icons-material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import GestureIcon from "@mui/icons-material/Gesture";
import { CircularProgress, FormControl, FormHelperText, FormLabel, InputLabel, ListItemIcon } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import "ace-builds";
import "ace-builds/src-noconflict/ext-error_marker";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/ext-spellcheck";
import "ace-builds/src-noconflict/keybinding-vscode";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/snippets/json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/worker-javascript";
import "ace-builds/src-noconflict/worker-json";
import "ace-builds/webpack-resolver";
import _ from "lodash";
import AceEditor from "react-ace";
import { Controller, useForm } from "react-hook-form";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { RouteComponentProps, withRouter } from "react-router";
import * as yup from "yup";

import { TemplatesContext } from "../../../context/templates/TemplatesContext";
import { TemplateTypes } from "../../../interfaces/api/templates";
import { Template } from "../../../interfaces/templates";

import "./index.scss";

interface PropTypes extends RouteComponentProps {
    template?: Template;
}

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    name: yup.string().required("Este campo es obligatorio"),
    type: yup.string(),
    instructions: yup.string().required("Este campo es obligatorio"),
    expiration_days: yup.string().required("Este campo es obligatorio"),
    frequency_days: yup.string().required("Este campo es obligatorio"),
    config: yup.lazy((value) => {
        switch (typeof value) {
            case "object":
                return yup.object().nullable(); // schema for object
            case "string":
                return yup.string().nullable(); // schema for string
            default:
                return yup.mixed().nullable(); // here you can decide what is the default
        }
    }),
    data_example: yup.lazy((value) => {
        switch (typeof value) {
            case "object":
                return yup.object().nullable(); // schema for object
            case "string":
                return yup.string().nullable(); // schema for string
            default:
                return yup.mixed().nullable(); // here you can decide what is the default
        }
    }),
    legal_text: yup.string().nullable(),
});

const TemplateForm = (props: PropTypes) => {
    const { template } = props;
    const { createTemplate, updateTemplate, errorMessage, loading } = useContext(TemplatesContext);
    const [invalidJson, setInvalidJson] = useState(false);
    const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
    const isCreateMode = !template;

    const initialValues = {
        // used to initialize the data
        id_template: template?.id_template,
        name: template?.name,
        type: template?.type || TemplateTypes.Signature,
        instructions: template?.instructions,
        expiration_days: template?.expiration_days,
        frequency_days: template?.frequency_days,
        config: template?.config,
        data_example: template?.data_example,
        legal_text: template?.config?.legal_text,
    };

    const { control, formState, watch, handleSubmit, setValue } = useForm({
        mode: "onChange",
        defaultValues: initialValues,
        resolver: yupResolver(schema),
    });

    const { dirtyFields, errors } = formState;

    const onSubmit = async (data: Template) => {
        if (data && isCreateMode) {
            const success = await createTemplate(data);

            if (success) {
                props.history.push("/templates");
            }
        } else if (data && data.id_template) {
            if (Object.keys(data).length !== 0) {
                const success = await updateTemplate(data, data.id_template);

                if (success) {
                    props.history.push("/templates");
                }
            }
        }
    };

    const formData = watch();

    return (
        <div className="template-form">
            <Box
                noValidate
                autoComplete="off"
                className="ui-form"
                component="form"
                id="form1"
                //@ts-ignore
                onSubmit={handleSubmit(onSubmit)}
            >
                <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            required
                            error={!!errors.name}
                            helperText={errors?.name?.message}
                            label="Nombre"
                            variant="outlined"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="type"
                    render={({ field }) => (
                        <FormControl>
                            <InputLabel id="type-select" variant="outlined">
                                Tipo
                            </InputLabel>
                            <Select {...field} fullWidth id="type-select" label="Tipo" labelId="type-select">
                                <MenuItem value={TemplateTypes.Signature}>
                                    <ListItemIcon>
                                        <GestureIcon fontSize="small" />
                                    </ListItemIcon>
                                    Firma
                                </MenuItem>
                                <MenuItem value={TemplateTypes.Form}>
                                    <ListItemIcon>
                                        <AssignmentIcon />
                                    </ListItemIcon>
                                    Formulario
                                </MenuItem>
                                <MenuItem value={TemplateTypes.FormSignature}>
                                    <ListItemIcon>
                                        <AppRegistration />
                                    </ListItemIcon>
                                    Formulario + Firma
                                </MenuItem>
                                <MenuItem value={TemplateTypes.FileUpload}>
                                    <ListItemIcon>
                                        <AttachFileIcon fontSize="small" />
                                    </ListItemIcon>
                                    Carga de archivos
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}
                />
                <Controller
                    control={control}
                    name="instructions"
                    render={({ field }) => (
                        <FormControl {...field} error={!!errors.instructions}>
                            <FormLabel>Instrucciones</FormLabel>
                            <ReactMde
                                childProps={{
                                    writeButton: {
                                        tabIndex: -1,
                                    },
                                }}
                                generateMarkdownPreview={(markdown: any) =>
                                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                                }
                                selectedTab={selectedTab}
                                value={field.value || ""}
                                onChange={(value: string) => setValue("instructions", value, { shouldDirty: true })}
                                //loadSuggestions={loadSuggestions}
                                onTabChange={setSelectedTab}
                            />
                            {!!errors.instructions && <FormHelperText>{errors?.instructions?.message}</FormHelperText>}
                        </FormControl>
                    )}
                />
                <Controller
                    control={control}
                    name="expiration_days"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            required
                            error={!!errors.expiration_days}
                            helperText={errors?.expiration_days?.message}
                            label="Deadline"
                            placeholder="Días"
                            type="number"
                            variant="outlined"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="frequency_days"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={!!errors.frequency_days}
                            helperText={errors?.frequency_days?.message}
                            label="Frecuencia"
                            placeholder="Días"
                            type="number"
                            variant="outlined"
                        />
                    )}
                />
                {(formData.type === TemplateTypes.Signature || formData.type === TemplateTypes.FormSignature) && (
                    <Controller
                        control={control}
                        name="legal_text"
                        render={({ field }) => (
                            <FormControl error={!!errors.config}>
                                <FormLabel>Legal Text:</FormLabel>
                                <ReactMde
                                    childProps={{
                                        writeButton: {
                                            tabIndex: -1,
                                        },
                                    }}
                                    generateMarkdownPreview={(markdown: any) =>
                                        Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                                    }
                                    selectedTab={selectedTab}
                                    value={field.value || ""}
                                    onChange={(value: string) => {
                                        field.onChange({ target: { value } });
                                        setValue("legal_text", value, { shouldDirty: true, shouldValidate: true });
                                    }}
                                    //loadSuggestions={loadSuggestions}
                                    onTabChange={setSelectedTab}
                                />
                                {!!errors.instructions && (
                                    <FormHelperText>{errors?.instructions?.message}</FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                )}
                {formData.type !== TemplateTypes.Signature && (
                    <Controller
                        control={control}
                        name="config"
                        render={({ field }) => (
                            <FormControl {...field}>
                                <FormLabel>Config:</FormLabel>
                                <AceEditor
                                    fontSize={14}
                                    highlightActiveLine={true}
                                    keyboardHandler="vscode"
                                    mode="json"
                                    setOptions={{
                                        enableLiveAutocompletion: true,
                                        showLineNumbers: true,
                                        tabSize: 4,
                                        useSoftTabs: true,
                                        maxLines: 15,
                                        minLines: 4,
                                    }}
                                    theme="monokai"
                                    value={
                                        typeof field.value === "string"
                                            ? field.value
                                            : JSON.stringify(field.value || {}, null, 4)
                                    }
                                    width="100%"
                                    onChange={(newValue) => {
                                        field.onChange({ target: { value: newValue } });
                                        setValue("config", newValue, { shouldDirty: true, shouldValidate: true });
                                    }}
                                    onValidate={(annotations) => {
                                        annotations.length ? setInvalidJson(true) : setInvalidJson(false);
                                    }}
                                />
                            </FormControl>
                        )}
                    />
                )}
                <Controller
                    control={control}
                    name="data_example"
                    render={({ field }) => (
                        <FormControl {...field}>
                            <FormLabel>Data Example:</FormLabel>
                            <AceEditor
                                fontSize={14}
                                highlightActiveLine={true}
                                keyboardHandler="vscode"
                                mode="json"
                                setOptions={{
                                    enableLiveAutocompletion: true,
                                    showLineNumbers: true,
                                    tabSize: 4,
                                    useSoftTabs: true,
                                    maxLines: 15,
                                    minLines: 4,
                                }}
                                theme="monokai"
                                value={
                                    typeof field.value === "string"
                                        ? field.value
                                        : JSON.stringify(field.value || {}, null, 4)
                                }
                                width="100%"
                                onChange={(newValue) => {
                                    field.onChange({ target: { value: newValue } });
                                    setValue("data_example", newValue, { shouldDirty: true, shouldValidate: true });
                                }}
                                onValidate={(annotations) => {
                                    annotations.length ? setInvalidJson(true) : setInvalidJson(false);
                                }}
                            />
                        </FormControl>
                    )}
                />
                <Button
                    disabled={_.isEmpty(dirtyFields) || invalidJson || loading}
                    form="form1"
                    type="submit"
                    variant="outlined"
                >
                    {loading ? <CircularProgress size={16} /> : "Completar"}
                </Button>
            </Box>
        </div>
    );
};

export default withRouter(TemplateForm);
