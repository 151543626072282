import React from "react";

import Markdown from "markdown-to-jsx";

import "./index.scss";

interface PropTypes {
    markdown: string;
    overrides?: any | undefined;
}

export const CeleriMarkdown = ({ markdown, overrides }: PropTypes) => (
    <Markdown
        options={{
            overrides: {
                p: {
                    props: {
                        className: "legal-text",
                    },
                },
                table: {
                    props: {
                        className: "table",
                    },
                },
                thead: {
                    props: {
                        className: "table-head",
                    },
                },
                th: {
                    props: {
                        className: "table-th",
                    },
                },
                td: {
                    props: {
                        className: "table-td",
                    },
                },
            },
        }}
    >
        {markdown}
    </Markdown>
);
