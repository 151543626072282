import { ContactResponse } from "../../interfaces/api/contacts";

export interface ContactsState {
    errorMessage?: string;
    contacts?: ContactResponse[];
    loading: boolean;
    count?: number;
    pageSize: number;
    pageIndex: number;
    searchText: string;
}

type ContactsActions =
    | { type: "setContacts"; payload: ContactResponse[] | undefined }
    | { type: "setContactsCount"; payload: number }
    | { type: "setPageSize"; payload: number }
    | { type: "setSearchText"; payload: string }
    | { type: "setPageIndex"; payload: number }
    | { type: "addError"; payload: string }
    | { type: "cleanContacts" }
    | { type: "removeError" }
    | { type: "setLoading"; payload: boolean };

export const ContactsReducer = (state: ContactsState, action: ContactsActions): ContactsState => {
    switch (action.type) {
        case "addError":
            return {
                ...state,
                errorMessage: action.payload,
            };
        case "removeError":
            return {
                ...state,
                errorMessage: undefined,
            };
        case "setContacts":
            return {
                ...state,
                contacts: action.payload,
            };
        case "setContactsCount":
            return {
                ...state,
                count: action.payload,
            };
        case "setPageSize":
            return {
                ...state,
                pageSize: action.payload,
            };
        case "setPageIndex":
            return {
                ...state,
                pageIndex: action.payload,
            };
        case "setSearchText":
            return {
                ...state,
                searchText: action.payload,
            };
        case "cleanContacts":
            return {
                ...state,
                contacts: undefined,
            };
        case "setLoading":
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};
