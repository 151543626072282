import React from "react";

import { Button, ButtonGroup } from "@mui/material";

import { DeadlinesFilterType } from "../../../interfaces/deadlines";

import "./index.scss";

interface PropTypes {
    onClick: (f?: DeadlinesFilterType) => void;
    value?: DeadlinesFilterType;
}

export const DeadlinesFilters = (props: PropTypes) => {
    const { onClick, value } = props;

    const handleClickFilter = (v: DeadlinesFilterType) => (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (value === v) {
            onClick();
        } else {
            onClick(v);
        }
    };

    return (
        <div className="deadlines-filters-container">
            <ButtonGroup>
                <Button
                    color={value === DeadlinesFilterType.day ? "secondary" : "primary"}
                    size="small"
                    variant={value === DeadlinesFilterType.day ? "contained" : "outlined"}
                    onClick={handleClickFilter(DeadlinesFilterType.day)}
                >
                    Dia
                </Button>
                <Button
                    color={value === DeadlinesFilterType.week ? "secondary" : "primary"}
                    size="small"
                    variant={value === DeadlinesFilterType.week ? "contained" : "outlined"}
                    onClick={handleClickFilter(DeadlinesFilterType.week)}
                >
                    Semana
                </Button>
                <Button
                    color={value === DeadlinesFilterType.month ? "secondary" : "primary"}
                    size="small"
                    variant={value === DeadlinesFilterType.month ? "contained" : "outlined"}
                    onClick={handleClickFilter(DeadlinesFilterType.month)}
                >
                    Mes
                </Button>
                <Button
                    color={value === DeadlinesFilterType.yaer ? "secondary" : "primary"}
                    size="small"
                    variant={value === DeadlinesFilterType.yaer ? "contained" : "outlined"}
                    onClick={handleClickFilter(DeadlinesFilterType.yaer)}
                >
                    Año
                </Button>
            </ButtonGroup>
        </div>
    );
};
