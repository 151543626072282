import { AnalyticsResponse, CumplimientoAnalyticsResponse } from "../../interfaces/api/home";

export interface HomeState {
    errorMessage?: string;
    analytics?: AnalyticsResponse;
    cumplimientoAnalytics?: CumplimientoAnalyticsResponse;
    loading: boolean;
}

type HomeActions =
    | { type: "setAnalytics"; payload: AnalyticsResponse | undefined }
    | { type: "setCumplimientoAnalytics"; payload: CumplimientoAnalyticsResponse | undefined }
    | { type: "addError"; payload: string }
    | { type: "cleanAnalytics" }
    | { type: "removeError" }
    | { type: "setLoading"; payload: boolean };

export const HomeReducer = (state: HomeState, action: HomeActions): HomeState => {
    switch (action.type) {
        case "addError":
            return {
                ...state,
                errorMessage: action.payload,
            };
        case "removeError":
            return {
                ...state,
                errorMessage: undefined,
            };
        case "setAnalytics":
            return {
                ...state,
                analytics: action.payload,
            };
        case "setCumplimientoAnalytics":
            return {
                ...state,
                cumplimientoAnalytics: action.payload,
            };
        case "cleanAnalytics":
            return {
                ...state,
                analytics: undefined,
            };
        case "setLoading":
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};
