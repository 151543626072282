import { Contact } from "../../contacts";
import { Template } from "../../templates";

export interface RegulationsResponse {
    id_regulation: string;
    id_organization: string;
    template: Template;
    contact: Contact;
    is_hidden: boolean;
    reference: string;
    data: Data | null;
    expires_at: Date;
    created_by: CreatedBy;
    created_at: Date;
    updated_at: Date;
    status: RegulationStatus;
    updates: Update[];
    comments: Comment[];
    name: string;
    type: RegulationType;
}

export interface Comment {
    id_comment: string;
    comment: string;
    created_at: Date;
    updated_at: Date;
    created_by: CreatedBy;
    attachments: any[];
}

export interface CreatedBy {
    id: string;
    email: string;
}

/* export interface Contact {
  id_contact:  string;
  first_name:  string;
  middle_name: null | string;
  last_name:   string;
  email:       string;
} */

export interface Data {}

export enum RegulationStatus {
    Completed = "COMPLETED",
    Paused = "PAUSED",
    Pending = "PENDING",
    Review = "REVIEW",
    Cancelled = "CANCELLED",
    Expired = "EXPIRED",
}

export enum RegulationType {
    Recurring = "RECURRING",
}

export interface Update {
    created_at: Date;
    updated_at: Date;
    id_regulation_update: number;
    id_regulation: string;
    status: RegulationStatus;
    created_by: null | string;
}
