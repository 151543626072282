import { useEffect, useState } from "react";
import api from "../api";
import history from "../utils/history";

const useApp = () => {
    const splitUrl = window.location.pathname.split("/");

    const [organization, setOrganization] = useState();

    if (splitUrl.length > 1 && splitUrl[1] === "cumplimiento") {
        return { type: "CUMPLIMIENTO", organization };
    } else {
        return { type: "REQUERIMIENTO", organization };
    }
};

export default useApp;
