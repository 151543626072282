import { EntitiesResponse } from "../../interfaces/api/entities";
import { Entity } from "../../interfaces/entities";

export interface EntitiesState {
    errorMessage?: string;
    loading: boolean;
    entities?: EntitiesResponse[];
    entity?: Entity;
}

type EntitiesActions =
    | { type: "setEntities"; payload: EntitiesResponse[] | undefined }
    | { type: "setEntity"; payload: Entity | undefined }
    | { type: "setActiveEntities"; payload: EntitiesResponse[] | undefined }
    | { type: "setInactiveEntities"; payload: EntitiesResponse[] | undefined }
    | { type: "setexpiredEntities"; payload: EntitiesResponse[] | undefined }
    | { type: "addError"; payload: string }
    | { type: "setLoading"; payload: boolean }
    | { type: "setLoadingAction"; payload: { value: boolean; type?: string; reqId?: string } }
    | { type: "cleanEntities" }
    | { type: "removeError" };

export const EntitiesReducer = (state: EntitiesState, action: EntitiesActions): EntitiesState => {
    switch (action.type) {
        case "setLoading":
            return {
                ...state,
                loading: action.payload,
            };
        case "addError":
            return {
                ...state,
                errorMessage: action.payload,
            };
        case "removeError":
            return {
                ...state,
                errorMessage: undefined,
            };
        case "setEntities":
            return {
                ...state,
                entities: action.payload,
            };
        case "setEntity":
            return {
                ...state,
                entity: action.payload,
            };
        case "cleanEntities":
            return {
                ...state,
                entities: undefined,
            };
        default:
            return state;
    }
};
