import React from "react";

import { Logout } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Cookies } from "react-cookie";

import { supabase } from "../../../utils/supabase";

import "./index.scss";

export const LogoutButton = () => {
    return (
        <div className="ui-logout-button">
            <Button
                endIcon={<Logout />}
                variant="outlined"
                onClick={(ev: any) => {
                    const cookies = new Cookies();

                    ev.stopPropagation();
                    supabase.auth.signOut();
                    cookies.remove("access_token_api", { path: "/" });
                }}
            >
                logout
            </Button>
        </div>
    );
};
