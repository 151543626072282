import React, { useContext, useState, useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Typography } from "@mui/material";
import { Icon, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { flexbox } from "@mui/system";
import "ace-builds";
import "ace-builds/src-noconflict/ext-error_marker";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/ext-spellcheck";
import "ace-builds/src-noconflict/keybinding-vscode";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/snippets/json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/worker-javascript";
import "ace-builds/src-noconflict/worker-json";
import "ace-builds/webpack-resolver";
import _ from "lodash";
import AceEditor from "react-ace";
import { Controller, useForm } from "react-hook-form";
import { RouteComponentProps, withRouter } from "react-router";
import * as yup from "yup";

import { DialogContext } from "../../../context/dialog/DialogContext";
import { RequirementsContext } from "../../../context/requirements/RequirementsContext";
import { Requirement } from "../../../interfaces/requirements";
import AsyncAutocomplete from "../../../ui/autocomplete";
import { difference, getContactFullName } from "../../../utils";
import { ContactDialog } from "../../contacts/preview-dialog";
import { TemplateDialog } from "../../templates/preview-dialog";

import "./index.scss";
import { UserContext } from "../../../context/user/UserContext";

interface PropTypes extends RouteComponentProps {
    requirement?: Requirement;
}

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    reference: yup.string(),
    contact: yup.object(),
    template: yup.object(),
    data: yup.object().nullable(),
});

const RequerimientoForm = (props: PropTypes) => {
    const { requirement, history } = props;
    const { user, organization } = useContext(UserContext);
    const { createRequirement, updateRequirement, errorMessage, loading } = useContext(RequirementsContext);
    const { openDialog: openCustomDialog } = useContext(DialogContext);
    const { closeDialog: closeCustomDialog } = useContext(DialogContext);
    const isCreateMode = !requirement;

    const initialValues = {
        // used to initialize the data
        reference: requirement?.reference || "",
        contact: requirement?.contact,
        template: requirement?.template,
        data: requirement?.data,
    };

    const { control, formState, watch, handleSubmit, setValue } = useForm({
        mode: "onChange",
        defaultValues: initialValues,
        resolver: yupResolver(schema),
    });

    const handleClickContactDetail = (id: string) => {
        closeCustomDialog();
        props.history.push(`/contacts/${id}`);
    };
    const handleClickTemplateDetail = (id: string) => {
        closeCustomDialog();
        props.history.push(`/templates/${id}`);
    };
    const contact = watch("contact");
    const template = watch("template");

    useEffect(() => {
        setValue("data", template?.data_example, { shouldDirty: true, shouldValidate: true });
    }, [template]);

    const handleClickContact = (id: string) => async (event: any) => {
        event.stopPropagation();
        if (contact) {
            openCustomDialog({
                children: <ContactDialog contact={contact} onClick={handleClickContactDetail} />,
            });
        }
    };

    const handleClickTemplate = (id: string) => async (event: any) => {
        event.stopPropagation();
        if (template) {
            openCustomDialog({
                children: <TemplateDialog template={template} onClick={handleClickTemplateDetail} />,
            });
        }
    };

    const { dirtyFields, errors } = formState;

    const onSubmit = async (data: Requirement) => {
        window.analytics.track("click_create_button_requirements", {
            id_user: user?.id,
            id_organization: organization?.id_organization,
        });
        if (data && isCreateMode) {
            //@ts-ignore
            const success = await createRequirement(data);

            if (success) {
                history.push("/requirements");
            }
        } else if (data && data.id_requirement) {
            const updates: Partial<Requirement> = difference(data, requirement);

            if (Object.keys(updates).length !== 0) {
                //@ts-ignore
                const success = await updateRequirement(updates, data.id_requirement);

                if (success) {
                    history.push("/requirements");
                }
            }
        }
    };

    return (
        <div className="requirement-form">
            <Box
                noValidate
                autoComplete="off"
                className="ui-form"
                component="form"
                id="form1"
                //@ts-ignore
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="view-wrapper">
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Controller
                            control={control}
                            name="contact"
                            render={({ field }) => (
                                <AsyncAutocomplete
                                    customSearch
                                    accessor="email"
                                    getOptionLabel={(option: any) => {
                                        return (
                                            (option.accounts ? `[${(option.accounts || []).join(" - ")}] ` : "") +
                                            `${getContactFullName(option)} (${option.email})`
                                        );
                                    }}
                                    inputLabel="Contacto"
                                    url="/contacts/search"
                                    value={field.value}
                                    onChange={(ev: any, value: any) => setValue("contact", value)}
                                />
                            )}
                        />
                        {contact?.id_contact && (
                            <IconButton className="view-button" onClick={handleClickContact(contact.id_contact)}>
                                <Icon style={{ fontSize: 20 }}>visibility</Icon>
                            </IconButton>
                        )}
                    </Box>
                    <Typography className="description" color="GrayText" variant="body2">
                        Los caracteres especiales permitidos son:
                        <code>-</code>,<code>_</code>,<code>+</code>,<code>@</code>,<code>.</code>,<code>{"'"}</code>,
                        <code>espacio</code>
                    </Typography>
                </div>
                <div className="view-wrapper">
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Controller
                            control={control}
                            name="template"
                            render={({ field: { onChange, value, ...rest } }) => (
                                <AsyncAutocomplete
                                    accessor="name"
                                    inputLabel="Template"
                                    url="/templates"
                                    value={value}
                                    onChange={(ev: any, value: any) => {
                                        setValue("template", value);
                                    }}
                                />
                            )}
                        />
                        {template?.id_template && (
                            <IconButton className="view-button" onClick={handleClickTemplate(template.id_template)}>
                                <Icon style={{ fontSize: 20 }}>visibility</Icon>
                            </IconButton>
                        )}
                    </Box>
                </div>
                <div className="view-wrapper">
                    <Controller
                        control={control}
                        name="reference"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.reference}
                                helperText={errors?.reference?.message}
                                label="Detalle"
                                variant="outlined"
                            />
                        )}
                    />
                </div>
                <div className="view-wrapper">
                    <Controller
                        control={control}
                        name="data"
                        render={({ field }) => (
                            <AceEditor
                                {...field}
                                fontSize={14}
                                highlightActiveLine={true}
                                keyboardHandler="vscode"
                                mode="json"
                                setOptions={{
                                    enableLiveAutocompletion: true,
                                    showLineNumbers: true,
                                    spellcheck: true,
                                    enableSnippets: true,
                                    tabSize: 4,
                                    useSoftTabs: true,
                                    maxLines: 15,
                                    minLines: 4,
                                }}
                                theme="monokai"
                                value={
                                    typeof field.value === "string"
                                        ? field.value
                                        : JSON.stringify(field.value || {}, null, 4)
                                }
                                //onValidate={(annotations) => annotations.length ? setInvalidJson(true) : setInvalidJson(false)}
                                onChange={(newValue) => {
                                    field.onChange({ target: { value: newValue } });
                                    setValue(field.name, newValue, { shouldDirty: true, shouldValidate: true });
                                }}
                            />
                        )}
                    />
                </div>
                <Button disabled={_.isEmpty(dirtyFields) || loading} form="form1" type="submit" variant="outlined">
                    {loading ? <CircularProgress size={16} /> : "Crear"}
                </Button>
            </Box>
        </div>
    );
};

export default withRouter(RequerimientoForm);
