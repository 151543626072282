import React, { createContext, useReducer } from "react";

import { useSnackbar } from "notistack";

import api from "../../api";
import { EmailsResponse } from "../../interfaces/api/emails";
import { Email, EmailImportExcel } from "../../interfaces/emails";
import { EmailsReducer, EmailsState } from "./EmailsReducer";

type EmailsContextProps = {
    emails?: EmailsResponse[];
    errorMessage?: string;
    loading: boolean;
    getEmails: () => Promise<void>;
    getEmailById: (id: string) => Promise<Email | undefined>;
    getEmailsByContact: (contactId: string) => Promise<EmailsResponse[] | undefined>;
    getEmailsByRequirement: (requirementId: string) => Promise<EmailsResponse[] | undefined>;
    removeError: () => void;
    cleanEmails: () => void;
};

const emailsInitialState: EmailsState = {
    loading: false,
    errorMessage: undefined,
    emails: undefined,
};

export const EmailsContext = createContext({} as EmailsContextProps);

export const EmailsProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(EmailsReducer, emailsInitialState);
    const { enqueueSnackbar } = useSnackbar();

    const onSuccess = () => {
        dispatch({
            type: "removeError",
        });
        dispatch({
            type: "setLoading",
            payload: false,
        });
    };

    const onError = (errorMsg?: string) => {
        dispatch({
            type: "addError",
            payload: errorMsg || "Hubo un error",
        });
        dispatch({
            type: "setLoading",
            payload: false,
        });
        enqueueSnackbar(errorMsg || "Hubo un error", {
            variant: "error",
        });
    };

    const getEmails = async () => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<EmailsResponse[]>("/emails");
            const emails = resp.data;

            dispatch({
                type: "setEmails",
                payload: emails || undefined,
            });
            onSuccess();
        } catch (error: any) {
            onError(error.response?.data?.message);
            cleanEmails();
        }
    };

    const getEmailById = async (id: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<EmailsResponse>(`/emails/${id}`);
            const email = resp.data;
            const mappedEmail: Email = {
                id_email: email.id_email,
                to: email.to,
                status: email.status,
                created_at: email.created_at,
            };

            onSuccess();

            return mappedEmail;
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const getEmailsByContact = async (contactId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<EmailsResponse[]>(`/emails?contact->>id_contact=eq.${contactId}`);

            onSuccess();

            return data;
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const getEmailsByRequirement = async (requirementId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<EmailsResponse[]>(
                `/rpc/emails_by_requirement?id_requirement=${requirementId}`,
            );

            onSuccess();

            return data;
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const removeError = () => {
        dispatch({ type: "removeError" });
    };

    const cleanEmails = () => {
        dispatch({ type: "cleanEmails" });
    };

    return (
        <EmailsContext.Provider
            value={{
                ...state,
                cleanEmails,
                removeError,
                getEmails,
                getEmailById,
                getEmailsByContact,
                getEmailsByRequirement,
            }}
        >
            {children}
        </EmailsContext.Provider>
    );
};
