import React, { createContext, useReducer } from "react";

import { isArray } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";

import api from "../../api";
import { NovedadesResponse } from "../../interfaces/api/novedades";
import { Novedad, NovedadesFilterType, NovedadImportExcel } from "../../interfaces/novedades";
import { NovedadesReducer, NovedadesState } from "./NovedadesReducer";

type NovedadesContextProps = {
    novedades?: NovedadesResponse[];
    novedad?: Novedad;
    dayNovedades?: NovedadesResponse[];
    weekNovedades?: NovedadesResponse[];
    monthNovedades?: NovedadesResponse[];
    allNovedades?: NovedadesResponse[];
    errorMessage?: string;
    loading: boolean;
    loadingAction: {
        value: boolean;
        type?: string;
        reqId?: string;
    };
    getNovedades: () => Promise<void>;
    getNovedadesByFilter: (v: NovedadesFilterType) => Promise<void>;
    getNovedadById: (id: string) => Promise<Novedad | undefined>;
    getNovedadesByContact: (contactId: string) => Promise<NovedadesResponse[] | undefined>;
    getNovedadesByTemplate: (contactId: string) => Promise<NovedadesResponse[] | undefined>;
    removeError: () => void;
    cleanNovedades: () => void;
};

const novedadesInitialState: NovedadesState = {
    loading: false,
    loadingAction: {
        value: false,
        type: undefined,
    },
    errorMessage: undefined,
    novedades: undefined,
};

export const NovedadesContext = createContext({} as NovedadesContextProps);

export const NovedadesProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(NovedadesReducer, novedadesInitialState);
    const { enqueueSnackbar } = useSnackbar();

    const onSuccess = (action: boolean) => {
        dispatch({
            type: "removeError",
        });
        if (action) {
            dispatch({
                type: "setLoadingAction",
                payload: { value: false, type: undefined, reqId: undefined },
            });
        } else {
            dispatch({
                type: "setLoading",
                payload: false,
            });
        }
    };

    const onSuccessWithMessage = (action: boolean, successMessage: string) => {
        onSuccess(action);
        enqueueSnackbar(successMessage, {
            variant: "success",
        });
    };

    const onError = (action: boolean, errorMsg?: string) => {
        dispatch({
            type: "addError",
            payload: errorMsg || "Hubo un error",
        });
        if (action) {
            dispatch({
                type: "setLoadingAction",
                payload: { value: false, type: undefined, reqId: undefined },
            });
        } else {
            dispatch({
                type: "setLoading",
                payload: false,
            });
        }
        enqueueSnackbar(errorMsg || "Hubo un error", {
            variant: "error",
        });
    };

    const getNovedades = async () => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<NovedadesResponse[]>("/news");
            const novedades = resp.data;

            dispatch({
                type: "setNovedades",
                payload: novedades || undefined,
            });
            onSuccess(false);
        } catch (error: any) {
            onError(false, error.response?.data?.message);
            cleanNovedades();
        }
    };

    const getNovedadById = async (id: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<NovedadesResponse>(`/news/${id}`);
            const novedad = resp.data;
            const mappedNovedad: Novedad = {
                ...novedad,
            };

            onSuccess(false);

            dispatch({
                type: "setNovedad",
                payload: mappedNovedad || undefined,
            });

            return mappedNovedad;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const getNovedadesByContact = async (contactId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<NovedadesResponse[]>(`/news?contact->>id_contact=eq.${contactId}`);

            onSuccess(false);

            return data;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const getNovedadesByFilter = async (status: NovedadesFilterType) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });

            if (status === NovedadesFilterType.day) {
                const day = moment().subtract(1, "days").format("YYYY-MM-DD");
                const { data } = await api.get<NovedadesResponse[]>("/news?date=gte." + day);

                dispatch({
                    type: "setDayNovedades",
                    payload: data || undefined,
                });
            } else if (status === NovedadesFilterType.week) {
                const day = moment().subtract(7, "days").format("YYYY-MM-DD");
                const { data } = await api.get<NovedadesResponse[]>("/news?date=gte." + day);

                dispatch({
                    type: "setWeekNovedades",
                    payload: data || undefined,
                });
            } else if (status === NovedadesFilterType.month) {
                const day = moment().subtract(30, "days").format("YYYY-MM-DD");
                const { data } = await api.get<NovedadesResponse[]>("/news?date=gte." + day);

                dispatch({
                    type: "setMonthNovedades",
                    payload: data || undefined,
                });
            } else {
                const { data } = await api.get<NovedadesResponse[]>("/news");

                dispatch({
                    type: "setAllNovedades",
                    payload: data || undefined,
                });
            }
            onSuccess(false);
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const getNovedadesByTemplate = async (templateId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<NovedadesResponse[]>(`/news?template->>id_template=eq.${templateId}`);

            onSuccess(false);

            return data;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };
    const removeError = () => {
        dispatch({ type: "removeError" });
    };

    const cleanNovedades = () => {
        dispatch({ type: "cleanNovedades" });
    };

    return (
        <NovedadesContext.Provider
            value={{
                ...state,
                getNovedades,
                getNovedadesByFilter,
                cleanNovedades,
                removeError,
                getNovedadById,
                getNovedadesByContact,
                getNovedadesByTemplate,
            }}
        >
            {children}
        </NovedadesContext.Provider>
    );
};
