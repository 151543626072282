import { EmailsResponse } from "../../interfaces/api/emails";

export interface EmailsState {
    errorMessage?: string;
    loading: boolean;
    emails?: EmailsResponse[];
}

type EmailsActions =
    | { type: "setEmails"; payload: EmailsResponse[] | undefined }
    | { type: "addError"; payload: string }
    | { type: "setLoading"; payload: boolean }
    | { type: "cleanEmails" }
    | { type: "removeError" };

export const EmailsReducer = (state: EmailsState, action: EmailsActions): EmailsState => {
    switch (action.type) {
        case "setLoading":
            return {
                ...state,
                loading: action.payload,
            };
        case "addError":
            return {
                ...state,
                errorMessage: action.payload,
            };
        case "removeError":
            return {
                ...state,
                errorMessage: undefined,
            };
        case "setEmails":
            return {
                ...state,
                emails: action.payload,
            };
        case "cleanEmails":
            return {
                ...state,
                emails: undefined,
            };
        default:
            return state;
    }
};
