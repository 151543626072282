import React, { useContext, useEffect, useState } from "react";
import { CircularProgress, Icon, IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import DialogContentText from "@mui/material/DialogContentText";
import {
    GridColDef,
    GridRenderCellParams,
    GridSortModel,
    GridValueGetterFullParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");
import { RouteComponentProps, withRouter } from "react-router";

import { DeadlinesContext } from "../../../context/deadlines/DeadlinesContext";
import { DialogContext } from "../../../context/dialog/DialogContext";
import { DeadlinesResponse, DeadlineStatus, Regulator } from "../../../interfaces/api/deadlines";
import { Entity } from "../../../interfaces/entities";
import { DialogContent } from "../../../ui/dialog/content";
import { Table } from "../../../ui/table";
import { getContactFullName, getDeadlineStatusClassName, getDeadlineStatusLabel } from "../../../utils";
import { DaysCountdown } from "../../celeri/days-countdown";
import "./index.scss";

interface Action {
    type?: string;
    id?: string;
}

interface PropTypes extends RouteComponentProps<any> {
    deadlines: DeadlinesResponse[];
    isDetailSection?: boolean;
    loading?: boolean;
}

const DeadlinesTable = (props: PropTypes) => {
    const { deadlines, loading } = props;
    const { cancelDeadline, completeDeadline, pauseDeadline, resumeDeadline, restartDeadline } = useContext(
        DeadlinesContext,
    );

    const { openDialog: openCustomDialog, closeDialog: closeCustomDialog } = useContext(DialogContext);

    const [openDialog, setOpenDialog] = useState(false);
    const [action, setAction] = useState<Action | undefined>(undefined);

    useEffect(() => {
        if (action?.type) {
            openCustomDialog({
                children: <DialogComponent />,
            });
        }
    }, [action]);

    const handleClickContact = (contactID: string) => (event: any) => {
        event.stopPropagation();
        props.history.push(`/contacts/${contactID}`);
    };
    const handleClickTemplate = (templateID: string) => (event: any) => {
        event.stopPropagation();
        props.history.push(`/templates/${templateID}`);
    };

    const frequencyMapping = {
        "1 mon": "Mensual",
        "1 week": "Semanal",
        "1 year": "Anual",
    };

    const columns: GridColDef[] = [
        {
            field: "date",
            headerName: "Dias a vencer",
            width: 150,
            valueFormatter: (params: any) => {
                return params.value && moment(params.value?.toString()).format("DD/MM/YYYY");
            },
            renderCell: (params: GridRenderCellParams) => {
                const filingStatus = params.row.filings ? params.row.filings.status : "";

                const expired = moment(params.row.date).diff(moment(), "days") < 0;
                const status = filingStatus || (expired ? DeadlineStatus.Expired : "");

                if (status === DeadlineStatus.Submitted) {
                    return params.value && moment(params.value?.toString()).format("DD/MM/YYYY");
                } else {
                    return params.value && <DaysCountdown date={params.value} />;
                }
            },
        },
        {
            field: "regulation",
            headerName: "Reporte",
            width: 300,
            valueGetter: (params: any) => params.row.regulation.name,
            renderCell: (params: GridRenderCellParams) => (
                <Button sx={{ textTransform: "none", cursor: "pointer" }} variant="text">
                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {params.value}
                    </span>
                </Button>
            ),
        },
        {
            field: "interval",
            headerName: "Período",
            width: 250,
            valueGetter: (params: any) => ({
                interval_to: params.row.interval_to,
                interval_from: params.row.interval_from,
            }),
            renderCell: (params: GridRenderCellParams) => (
                <Typography>
                    del <b>{moment(params.value.interval_from).format("DD/MM/YY")}</b> al{" "}
                    <b>{moment(params.value.interval_to).format("DD/MM/YY")}</b>
                </Typography>
            ),
        },
        {
            field: "frequency",
            headerName: "Frecuencia",
            width: 100,
            valueGetter: (params: GridValueGetterFullParams) => {
                const freq: "1 mon" | "1 week" | "1 year" = params.row.regulation.frequency;

                return frequencyMapping[freq] || "";
            },
        },
        {
            field: "regulator",
            headerName: "Regulador",
            width: 150,
            renderCell: (params: GridRenderCellParams) => (
                <Button color="inherit" sx={{ textTransform: "none", cursor: "pointer" }} variant="text">
                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {params.value.code || ""}
                    </span>
                </Button>
            ),
        },
        {
            field: "status",
            headerName: "Estado",
            width: 145,
            renderCell: (params: GridRenderCellParams) => {
                const filingStatus = params.row.filings ? params.row.filings.status : "";

                const expired = moment(params.row.date).diff(moment(), "days") < 0;
                const status = filingStatus || (expired ? DeadlineStatus.Expired : "");

                return (
                    <Chip
                        className={getDeadlineStatusClassName(status)}
                        label={getDeadlineStatusLabel(status)}
                        variant={status === DeadlineStatus.Expired ? "filled" : "outlined"}
                    />
                );
            },
        },
        /* {
            field: "actions",
            headerName: "Acciones",
            sortable: false,
            filterable: false,
            width: props.isDetailSection ? 205 : 255,
        }, */
    ];

    const DialogComponent = () => (
        <DialogContent
            buttons={
                <>
                    <Button onClick={handleConfirmDialog}>Sí</Button>
                    <Button autoFocus onClick={() => closeCustomDialog()}>
                        No
                    </Button>
                </>
            }
        >
            <DialogContentText id="alert-dialog-description">{getDialogText()}</DialogContentText>
        </DialogContent>
    );

    const handleCancelDeadline = (deadline: DeadlinesResponse) => (event: any) => {
        event.stopPropagation();
        setAction({ type: "cancel", id: deadline.id_deadline });
        //setOpenDialog(true);
    };

    const handlePauseDeadline = (deadline: DeadlinesResponse) => (event: any) => {
        event.stopPropagation();
        setAction({ type: "pause", id: deadline.id_deadline });
    };

    const handleCompleteDeadline = (deadline: DeadlinesResponse) => (event: any) => {
        event.stopPropagation();
        setAction({ type: "complete", id: deadline.id_deadline });
    };

    const handleRestartDeadline = (deadline: DeadlinesResponse) => (event: any) => {
        event.stopPropagation();
        const isForbidden = deadline.status === DeadlineStatus.Review;

        if (!isForbidden) {
            setAction({ type: "restart", id: deadline.id_deadline });
        }
    };

    const getDialogText = () => {
        switch (action?.type) {
            case "cancel":
                return "¿Está seguro de que quiere cancelar este requerimiento?";
            case "pause":
                return "¿Está seguro de que quiere pausar este requerimiento?";
            case "resume":
                return "¿Está seguro de que quiere reanudar este requerimiento?";
            case "complete":
                return "¿Está seguro de que quiere completar este requerimiento?";
            case "restart":
                return "¿Está seguro de que quiere reiniciar este requerimiento?";
            default:
                return "";
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        if (!openDialog) {
            setAction(undefined);
        }
    };

    const handleConfirmDialog = () => {
        if (action && action.id) {
            switch (action.type) {
                case "cancel":
                    cancelDeadline(action.id);
                    break;
                case "pause":
                    pauseDeadline(action.id);
                    break;
                case "resume":
                    resumeDeadline(action.id);
                    break;
                case "complete":
                    completeDeadline(action.id);
                    break;
                case "restart":
                    restartDeadline(action.id);
                    break;
                default:
                    return;
            }
            handleCloseDialog();
            closeCustomDialog();
        }
    };

    const initialSortModel: GridSortModel = [
        {
            field: "date",
            sort: "asc",
        },
    ];

    return (
        <div className="deadlines-table">
            <Table
                columns={columns}
                getRowClassName={() => "cursor-pointer"}
                getRowId={(row: any) => row["id_deadline"]}
                initialSortModel={initialSortModel}
                loading={loading}
                rows={deadlines}
                onRowClick={(params: any, event: any) => {
                    event.stopPropagation();
                    props.history.push(`/cumplimiento/deadlines/${params.row.id_deadline}`);
                }}
            />
        </div>
    );
};

export default withRouter(DeadlinesTable);
