import React, { createContext, useReducer } from "react";

import { useSnackbar } from "notistack";

import api from "../../api";
import { ReportsResponse } from "../../interfaces/api/reports";
import { Reports } from "../../interfaces/reports";
import { ReportsReducer, ReportsState } from "./ReportsReducer";

type ReportsContextProps = {
    reports?: string[] | undefined;
    errorMessage?: string;
    loading: boolean;
    getReports: () => Promise<void>;
    cleanReports: () => void;
    removeError: () => void;
};

const reportsInitialState: ReportsState = {
    loading: false,
    errorMessage: undefined,
    reports: undefined,
};

export const ReportsContext = createContext({} as ReportsContextProps);

export const ReportsProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(ReportsReducer, reportsInitialState);
    const { enqueueSnackbar } = useSnackbar();

    const onSuccess = () => {
        dispatch({
            type: "removeError",
        });
        dispatch({
            type: "setLoading",
            payload: false,
        });
    };

    const onSuccessWithMessage = (successMessage: string) => {
        onSuccess();
        enqueueSnackbar(successMessage, {
            variant: "success",
        });
    };

    const onError = (errorMsg?: string) => {
        dispatch({
            type: "addError",
            payload: errorMsg || "Hubo un error",
        });
        dispatch({
            type: "setLoading",
            payload: false,
        });
        enqueueSnackbar(errorMsg || "Hubo un error", {
            variant: "error",
        });
    };

    const getReports = async () => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<ReportsResponse[]>("/organizations?select=id_organization,retool_config");
            const reports = resp.data[0];

            dispatch({
                type: "setReports",
                payload: reports.retool_config?.boards || [],
            });
            onSuccess();
        } catch (error: any) {
            onError(error.response?.data?.message);
            cleanReports();
        }
    };

    const removeError = () => {
        dispatch({ type: "removeError" });
    };

    const cleanReports = () => {
        dispatch({ type: "cleanReports" });
    };

    return (
        <ReportsContext.Provider
            value={{
                ...state,
                getReports,
                removeError,
                cleanReports,
            }}
        >
            {children}
        </ReportsContext.Provider>
    );
};
