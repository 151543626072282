import React from "react";

import { Tooltip } from "@mui/material";
import moment from "moment";

import "./index.scss";

function parseDate(date: string) {
    const myDate = moment(date);
    let color = null;
    const diff = myDate.diff(moment(), "days");

    if (diff < 0) color = "red";
    else if (diff < 3) color = "orange";
    else if (diff < 7) color = "yellow";
    else color = "green";

    return {
        date,
        display: myDate.fromNow(),
        detail: myDate.format("DD/MM/YYYY"),
        color,
    };
}
interface PropTypes {
    date: string;
}
export const DaysCountdown = (props: PropTypes) => {
    const value = parseDate(props.date);

    if (!props.date) {
        return null;
    }

    /* if(props.date && moment(props.date)) {
		return (
			<Typography>
				{moment(props.date).format("DD/MM/YYYY")}
			</Typography>
		)
	} */

    return (
        <Tooltip title={value.detail}>
            <span className={`days-countdown ${value.color}`}>{value.display}</span>
        </Tooltip>
    );
};
