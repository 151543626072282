import { Contact } from "../../contacts";
import { RequirementStatus } from "../requirements";
import { TemplateTypes } from "../templates";

export interface EmailsResponse {
    id_email: string;
    contact: Contact;
    type: EmailsResponseType;
    from: string;
    to: string;
    subject: string;
    cc: string | null;
    bcc: string | null;
    body: Body;
    id_sendgrid: string | null;
    requirements: Requirement[];
    created_at: Date;
    updated_at: Date;
    updates: Activity[];
    activity: Activity[];
    extra_data?: any;
    status: EmailStatus;
}

export interface Activity {
    data: ActivityData | null;
    event?: Event;
    id_email: string;
    created_at: Date;
    updated_at: Date;
    id_email_status: number;
    status?: ActivityStatus;
}

export interface ActivityData {
    ip?: string;
    email: string;
    event: Event;
    id_email: string;
    timestamp: number;
    useragent?: string;
    sg_event_id: string;
    sg_message_id: string;
    sg_template_id: string;
    sg_content_type?: string;
    sg_machine_open?: boolean;
    sg_template_name: string;
    tls?: number;
    "smtp-id"?: string;
    response?: string;
    send_at?: number;
    url?: string;
    url_offset?: URLOffset;
}

export enum Event {
    Click = "click",
    Delivered = "delivered",
    Open = "open",
    Processed = "processed",
}

export interface URLOffset {
    type: string;
    index: number;
}

export enum ActivityStatus {
    Created = "CREATED",
    Sent = "SENT",
}

export interface Requirement {
    data: RequirementData | null;
    status: RequirementStatus;
    contact: Contact;
    updates: Update[];
    comments: any[];
    template: Template;
    is_hidden: boolean;
    reference: string;
    created_at: Date;
    created_by: CreatedBy;
    expires_at: Date;
    is_expired: boolean;
    updated_at: Date;
    id_requirement: string;
    id_organization: string;
}

export interface CreatedBy {
    id: string;
    email: string;
}

export interface RequirementData {
    comitente: string;
}

export interface Template {
    name: string;
    type: TemplateTypes;
    config: Config;
    description: null;
    id_template: string;
    instructions: string;
}

export interface Config {
    legal_text?: string;
    schema?: any;
    ui_schema?: any;
}

export interface Schema {
    type: string;
    required: string[];
    properties: any;
}

export interface UISchema {
    type: string;
    elements: any;
}

export enum EmailStatus {
    Created = "CREATED",
    Sent = "SENT",
    Error = "ERROR",
}

export interface Update {
    status: EmailStatus;
    created_at: Date;
    created_by: null | string;
    updated_at: Date;
    id_requirement: string;
    id_requirement_update: number;
}

export enum EmailsResponseType {
    FollowUp = "FOLLOW_UP",
}
