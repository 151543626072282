import React, { useContext, useEffect, useState } from "react";

import { RouteComponentProps, withRouter } from "react-router";
import { useLocation } from "react-router-dom";

import EntitiesTable from "../../../components/entities/table";
import { EntitiesContext } from "../../../context/entities/EntitiesContext";
import CommonLayout from "../../../layout/common";

import "./index.scss";

type PropTypes = RouteComponentProps<any>;

const Entities = (props: PropTypes) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const { getEntities, getEntitiesByFilter, loading, errorMessage, entities } = useContext(EntitiesContext);

    useEffect(() => {
        getEntities();
    }, []);

    const isLoading = () => {
        if (loading || !entities) {
            return true;
        }

        return false;
    };

    const getRefreshFunction = () => {
        return getEntities();
    };

    return (
        <div className="pages-entities">
            <CommonLayout loading={loading} refreshFunction={getRefreshFunction} title="Entidades">
                <EntitiesTable entities={entities || []} loading={isLoading()} />
            </CommonLayout>
        </div>
    );
};

export default withRouter(Entities);
