import * as React from "react";

import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Divider, Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import "ace-builds";
import "ace-builds/src-noconflict/ext-error_marker";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/ext-spellcheck";
import "ace-builds/src-noconflict/keybinding-vscode";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/snippets/json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/worker-javascript";
import "ace-builds/src-noconflict/worker-json";
import "ace-builds/webpack-resolver";
import AceEditor from "react-ace";

import CommonLayout from "../../layout/common";

export const FormBuilder = () => {
    const [data, setData] = React.useState();
    const [errors, setErrors] = React.useState<any>();
    const [displayErrors, setDisplayErrors] = React.useState(false);
    const [disabledSubmit, setDisabledSubmit] = React.useState(true);
    const [validJson, setValidJson] = React.useState(true);

    const [jsonBuilder, setJsonBuilder] = React.useState<any>('{\n\t"schema": {},\n\t"ui_schema": {}\n}');
    const [objectBuilder, setObjectBuilder] = React.useState<any>({ schema: {}, ui_schema: {} });

    React.useEffect(() => {
        if (jsonBuilder && jsonBuilder.includes('"schema"') && jsonBuilder.includes('"ui_schema"')) {
            try {
                setObjectBuilder(JSON.parse(jsonBuilder));
                setValidJson(true);
            } catch (e) {
                setValidJson(false);
            }
        }
    }, [jsonBuilder]);

    const handleDataChange = ({ data, errors }: any) => {
        setData(data);
        setErrors(errors);
    };

    return (
        <div className="pages-form-builder">
            <CommonLayout loading={false} title="Form Builder">
                <Box>
                    <Typography color="primary" variant="h5">
                        Format
                    </Typography>
                    <Divider style={{ marginBottom: "16px" }} />
                    <AceEditor
                        fontSize={14}
                        highlightActiveLine={true}
                        keyboardHandler="vscode"
                        mode="json"
                        setOptions={{
                            enableLiveAutocompletion: true,
                            showLineNumbers: true,
                            spellcheck: true,
                            enableSnippets: true,
                            tabSize: 4,
                            useSoftTabs: true,
                            maxLines: 25,
                            minLines: 4,
                        }}
                        theme="monokai"
                        value={typeof jsonBuilder === "string" ? jsonBuilder : JSON.stringify(jsonBuilder, null, 4)}
                        width="100%"
                        onChange={(newValue) => setJsonBuilder(newValue)}
                        onValidate={(annotations) => (annotations.length ? setValidJson(false) : setValidJson(true))}
                    />
                    <Typography style={{ color: "red" }}>
                        {!validJson
                            ? 'Json inválido. Debe tener dos keys "schema" y "ui_schema" y ser un json bien formateado.'
                            : ""}
                    </Typography>
                </Box>
                <Box sx={{ height: "100%", marginTop: "32px", width: "100%" }}>
                    <Typography color="primary" variant="h5">
                        Preview
                    </Typography>
                    <Divider style={{ marginBottom: "16px" }} />
                    {validJson && (
                        <JsonForms
                            cells={materialCells}
                            data={data}
                            renderers={materialRenderers}
                            schema={objectBuilder.schema}
                            uischema={objectBuilder.ui_schema}
                            validationMode={displayErrors ? "ValidateAndShow" : "ValidateAndHide"}
                            onChange={handleDataChange}
                        />
                    )}
                    {!validJson && (
                        <Typography style={{ color: "grey", fontWeight: "bold" }} variant="subtitle1">
                            Previsualización no disponible, verifique que el json sea correcto
                        </Typography>
                    )}
                </Box>
            </CommonLayout>
        </div>
    );
};
