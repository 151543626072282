import axios, { AxiosRequestConfig } from "axios";
import { Cookies } from "react-cookie";

const baseURL = process.env.REACT_APP_API_URL;

const headers: AxiosRequestConfig["headers"] = {};

const api = axios.create({ baseURL, headers });

api.interceptors.request.use((config) => {
    const cookies = new Cookies();
    const access_token = cookies.get("access_token_api");

    if (access_token) {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${access_token}`,
        };
    }

    return config;
});

api.interceptors.response.use(
    (res) => res,
    (error) => {
        if (error.response?.status === 401) {
            const cookies = new Cookies();

            cookies.remove("access_token_api", { path: "/" });
            window.location.href = "/logout";
        }

        return Promise.reject(error);
    },
);

export default api;
