import React from "react";

import { createTheme, Icon, IconButton } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { SnackbarProvider } from "notistack";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

import { ContactsProvider } from "./context/contacts/ContactsContext";
import { DeadlinesProvider } from "./context/deadlines/DeadlinesContext";
import { DialogProvider } from "./context/dialog/DialogContext";
import { EmailsProvider } from "./context/emails/EmailsContext";
import { EntitiesProvider } from "./context/entities/EntitiesContext";
import { HomeProvider } from "./context/home/HomeContext";
import { NovedadesProvider } from "./context/novedades/NovedadesContext";
import { RegulationsProvider } from "./context/regulations/RegulationsContext";
import { ReportsProvider } from "./context/reports/ReportsContext";
import { RequirementsProvider } from "./context/requirements/RequirementsContext";
import { SubmissionsProvider } from "./context/submissions/SubmissionsContext";
import { TemplatesProvider } from "./context/templates/TemplatesContext";
import { AppRouter } from "./router";

// instantiate the library

// add Segment's own integration ( or any other device mode integration )

// define the integration settings object.
// Since we are using only Segment integration in this example, we only have
// "Segment.io" in the integrationSettings object
const integrationSettings = {
    "Segment.io": {
        apiKey: process.env.REACT_APP_SEGMENT_KEY,
        retryQueue: true,
        addBundledMetadata: true,
    },
};

import analytics from "@segment/analytics.js-core";
import SegmentIntegration from "@segment/analytics.js-integration-segmentio";
import { UserProvider } from "./context/user/UserContext";

// Initialize the library
analytics.use(SegmentIntegration);
window.analytics = analytics.init(integrationSettings);

const theme = createTheme({
    typography: {
        fontFamily: ["Archivo", "Roboto"].join(","),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
    },
    palette: {
        primary: {
            light: "#327bf5",
            main: "#327bf5",
            dark: "#14366f",
            contrastText: "#fff",
        },
        secondary: {
            light: "#63e5c5",
            main: "#40c6c6",
            dark: "#40c6c6",
            contrastText: "#FFF",
        },
    },
});

function ErrorFallback(props: FallbackProps) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: "red" }}>{props.error.message}</pre>
        </div>
    );
}

// add action to all snackbars
const notistackRef = React.createRef<SnackbarProvider>();
const onClickDismiss = (key: string | number) => () => {
    notistackRef && notistackRef.current && notistackRef.current.closeSnackbar(key);
};

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                ref={notistackRef}
                action={(key) => (
                    <IconButton sx={{ color: "white" }} onClick={onClickDismiss(key)}>
                        <Icon>close</Icon>
                    </IconButton>
                )}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                maxSnack={3}
            >
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <UserProvider>
                        <DialogProvider>
                            <ReportsProvider>
                                <RegulationsProvider>
                                    <EntitiesProvider>
                                        <DeadlinesProvider>
                                            <NovedadesProvider>
                                                <RequirementsProvider>
                                                    <EmailsProvider>
                                                        <SubmissionsProvider>
                                                            <TemplatesProvider>
                                                                <ContactsProvider>
                                                                    <HomeProvider>
                                                                        <AppRouter />
                                                                    </HomeProvider>
                                                                </ContactsProvider>
                                                            </TemplatesProvider>
                                                        </SubmissionsProvider>
                                                    </EmailsProvider>
                                                </RequirementsProvider>
                                            </NovedadesProvider>
                                        </DeadlinesProvider>
                                    </EntitiesProvider>
                                </RegulationsProvider>
                            </ReportsProvider>
                        </DialogProvider>
                    </UserProvider>
                </ErrorBoundary>
            </SnackbarProvider>
        </ThemeProvider>
    );
};

export default App;
